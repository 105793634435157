import React, { useContext, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { offerContext } from '../offerContext';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import AlchemyIntegration from '../components/Web3Integration/AlchemyIntegration';
import WalletConnect from '../components/Web3Integration/WalletConnect';
import AddToIpfs from '../pages/AddToIpfs';
import ProfilePage from '../pages/ProfilePage';
import FiltersPage from '../pages/FiltersPage';
import EditProfilePage from '../pages/EditProfile';
import OfferDetailPage from '../pages/OfferDetailPage';
import UserTransactionPage from '../pages/UserTransactionsPage';
import Saved from '../pages/Saved';
import BrandDetailPage from '../pages/BrandDetailsPage';
import CommunityDetailsPage from '../pages/CommunityDetailsPage';
import RedeemPage from '../pages/RedeemPage';
import BurnNFTPage from '../pages/BurnNFTPage';
import UserDetailsPage from '../pages/UserDetailsPage';

export default function MainRouter() {
    function PrivateRouter() {
        const { user, setUser, setOpen } = useContext(offerContext)
        return user !== null ? <>
            <Outlet />
        </> : <>
            {
                JSON.parse(localStorage.getItem("deOffersUser")) === null && setOpen(true)
            }
            <Navigate to="/" />
        </>
    }
    const { user, setUser,  allOffers, allCommunities,  allAds, createOfferFromApp, buyOfferFromApp, offerSummary } = useContext(offerContext)
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("deOffersUser")))
    }, [])

    return (
        <>
            <Routes>
                <Route exact path='/' element={<LandingPage allAds={allAds} allOffers={allOffers} allCommunities={allCommunities} />} />
                <Route exact path='/achemyintegration' element={<AlchemyIntegration />} />
                <Route exact path='/walletconnect' element={<WalletConnect />} />
                <Route path='/create' element={<PrivateRouter />} >
                    <Route exact path='/create' element={<AddToIpfs offersLength={allOffers.length} createOfferFromApp={createOfferFromApp} allCommunities={allCommunities} />} />
                </Route>
                <Route path='/userprofile' element={<PrivateRouter />} >
                    <Route exact path='/userprofile' element={<ProfilePage user={user} />} />
                </Route>
                <Route path='/createprofile' element={<PrivateRouter />} >
                    <Route exact path='/createprofile' element={<EditProfilePage />} />
                </Route>
                <Route path='/usertransactions' element={<PrivateRouter />} >
                    <Route exact path='/usertransactions' element={<UserTransactionPage />} />
                </Route>
                <Route path='/createredeempage' element={<PrivateRouter />} >
                    <Route exact path='/createredeempage' element={<RedeemPage />} />
                </Route>
                <Route path='/redeemoffer' element={<PrivateRouter />} >
                    <Route exact path='/redeemoffer' element={<BurnNFTPage />} />
                </Route>
                <Route exact path='/branddetails/:id' element={<BrandDetailPage />} />
                <Route exact path='/userdetails/:address' element={<UserDetailsPage />} />
                <Route exact path='/communitydetails/:id' element={<CommunityDetailsPage />} />
                <Route exact path='/offers' element={<FiltersPage allAds={allAds} allOffers={allOffers} allCommunities={allCommunities} items={false} />} />
                <Route exact path='/items' element={<FiltersPage allAds={allAds} allOffers={allOffers} allCommunities={allCommunities} items={true} />} />
                <Route exact path='/offerdetails/:id' element={<OfferDetailPage buyOfferFromApp={buyOfferFromApp} offerSummary={offerSummary} />} />
                <Route exact path='/offerdetails/listed/:id' element={<OfferDetailPage buyOfferFromApp={buyOfferFromApp} offerSummary={offerSummary} />} />
                <Route exact path='saved' element={<Saved allCommunities={allCommunities} />} />
            </Routes>
        </>
    )
}