import { Autocomplete, Avatar, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Divider, Grid, InputAdornment, SnackbarContent, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useContext } from 'react';
import Countdown from 'react-countdown';
import IpfsServices from '../../services/IpfsServices';
import { offerContext } from '../../offerContext';
import axiosHandler from '../../helpers/axiosHandler';
import Web3 from 'web3';
import moment from 'moment/moment';
import BrandModal from './createBrand/BrandModal';
import { createOffer, getCreateFee, getOfferCount } from '../../services/OfferServices';
import successHandler from '../../helpers/successHandler';
import UserServices from '../../services/UserServices';
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { Box } from '@mui/system';
import Select from "react-dropdown-select";
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FixedTags from './CHipTextField';
import InfoIcon from '@mui/icons-material/Info';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { getCommunityFromContractApi } from '../../services/BrandServices';
import CommunityServices from '../../services/CommunityServices';
import { useNavigate } from 'react-router';
import { loadAllOffers } from '../../LoadData';
import Search from '../../services/Search';
import { useAuth } from '@arcana/auth-react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const textField = { width: '100%' };
const style = {
    cardCss: { borderRadius: { md: '10px', xs: '4px' }, width: { xs: '100%' }, height: '100%', cursor: 'pointer' },
    cardCont: { padding: { sm: '9px', xs: '3px' } },
    img: { objectPosition: 'top', borderRadius: '5px', height: { md: '25vh', sm: '25vh', xs: '25vh' }, objectFit: 'cover', width: '100%' },
    heading: { margin: '0px', color: '#292D32', fontFamily: 'Poppins', fontWeight: 'bolder', padding: '0px', fontSize: { sm: '15px', xs: '8px' } },
    p: { margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' },
    communityImg: { width: { md: '40px', sm: '30px', xs: '15px' }, border: '2px solid black', height: { md: '40px', sm: '30px', xs: '15px' }, borderRadius: '50%' },
    countdown: { fontSize: { md: '12px', xs: '7px' }, margin: 0, padding: '0', fontWeight: 'bold' },
    btn: { backgroundColor: '#292D32', color: 'white !important', textTransform: 'none', '&:hover': { color: 'black !important' }, fontSize: { md: '15px', sm: '10px', xs: '6px' }, padding: '10%', minWidth: '50%' },
}


function CreateOffer({ offersLength, createOfferFromApp, communities }) {
    const navigate = useNavigate()
    const auth = useAuth()
    const { setUser, user, allCommunities, offerFactory, token,
        setUserBrand, setUserCreatedOffers, userCreatedOffers, selectedNetwork,
        setClaimed, claimed, fav, setFav, setFavItems, setBought, setSold,
        setAllOffers, isDev, isArcana } = useContext(offerContext)
    const userBrand = localStorage.getItem("deOffersBrand") !== 'undefined' ? JSON.parse(localStorage.getItem("deOffersBrand")) : null
    const [countFields, setCountFields] = useState(1);
    const [open, setOpen] = useState(false);
    const [upload, setUpload] = useState(false)
    const [textFiledss, setTextFieldss] = useState([])
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [imgload, setImgLoad] = useState(null)
    const [comm, setComm] = useState(allCommunities)
    const [allClicked, setAllClicked] = useState(false)
    const [market, setMarket] = useState(0)
    const [addClick, setAddClick] = useState([])
    const [addText, setAddText] = useState([])
    const [load, setLoad] = useState(false)
    const [options, setOptions] = useState([])
    const [options2, setOptions2] = useState([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(5)
    const [selected, setSelected] = useState([])
    const [disable, setDisable] = useState(false)

    let currentDate = new Date()
    if (currentDate.getMonth() + 1 < 10) {
        currentDate = currentDate.getFullYear() + '-0' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
    }
    else {
        currentDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
    }
    // console.log(userBrand)


    const [json, setJson] = useState({
        creatorName: userBrand?.name,
        offerName: '',
        desc: '',
        creatorAddress: user.walletAddress,
        targetCommunities: [],
        bannerUri: 0,
        startsAt: '',
        endsAt: '',
        startsAtTime: '00:00',
        endsAtTime: '00:00',
        price: undefined,
        count: undefined
    })

    const startsAtDate = new Date(json?.startsAt ? json.startsAt : currentDate);
    startsAtDate.setMonth(startsAtDate.getMonth() + 3);
    let maxDate = startsAtDate.toISOString().split('T')[0];

    useEffect(() => {
        const startsAtDate = new Date(json?.startsAt ? json.startsAt : currentDate);
        startsAtDate.setMonth(startsAtDate.getMonth() + 3);
        maxDate = startsAtDate.toISOString().split('T')[0];
    }, [json.startsAt])

    console.log(addClick, addText, json.targetCommunities)
    useEffect(() => {
        console.log(addText, 'rkess')
        // if (addText !== 'undefined') {
        console.log(true)
        const temp = [...new Set([...addText, ...selected])]
        setJson({ ...json, targetCommunities: temp })
        // }
    }, [addClick, addText])
    // useEffect(() => {
    //     setJson({ ...json, targetCommunities: [...json.targetCommunities.slice(0, addText.length), undefined, ...json.targetCommunities.slice(addText.length, json.targetCommunities.length)] })
    // },[addText])
    // console.log(addText, json.targetCommunities, 'you')
    // console.log(json)
    const handleCount = (e) => {
        if (e.target.value > 0) {
            if (e.target.value <= 5) {
                setCountFields(e.target.value);
                setTextFieldss([])
                // setJson({ ...json, targetCommunities: (Array(Number(e.target.value)).fill("")) })
            }
            else {
                axiosHandler("Maximum limit reached. Enter number between 1-5")
            }
        } else {
            axiosHandler("Please enter value greater than 0")
        }
    }

    useEffect(() => {
        if (selected.length === Number(countFields)) {
            setDisable(true)
        }
        else {
            setDisable(false)
        }
    }, [countFields])

    const handleTargetCommunities = (value, selected, setSelected) => {
        if (json.targetCommunities.length < countFields) {
            // let arr = json.targetCommunities
            // arr.pop()
            // setJson({...json, targetCommunities:arr})
            setSelected(value)
            setJson({ ...json, targetCommunities: [...value] })
            if (value.length === Number(countFields)) {
                console.log(value.length, countFields, 'kkkkkkkkk')
                setDisable(true)
            }
        } else {
            // console.log(selected)
            value = value.slice(0, -1)
            console.log(value)
            axiosHandler('added more communities then expected')
        }
    }

    const handleTargetCommunities2 = (value) => {
        console.log(value, addText, 'valllll')
        if (json.targetCommunities.length < countFields) {
            // let arr = json.targetCommunities
            // arr.pop()
            setSelected(value)
            const temp = [...new Set([...addText, ...value])]
            setJson({ ...json, targetCommunities: temp })
            // setJson({...json, targetCommunities:arr})
        } else {
            axiosHandler('added more communities then expected')
        }
    }

    console.log(imgload, 'comm222')

    const onSubmitOfferJson = async (event) => {
        setUpload(true)
        let form2Data = new FormData()
        form2Data.append('files', imgload);
        const resImg = await IpfsServices.uploadImg(form2Data, token)
        setJson({ ...json, bannerUri: resImg.data.urls[0] })
        const setWeb = async () => {
            console.log('hello')
            const web3 = new Web3(auth.provider);
            window.web3 = web3
            console.log(window.web3.current)
        }
        console.log(isArcana)
        isArcana && setWeb()
        event.preventDefault()
        let st = moment(json.startsAt + " " + json.startsAtTime).unix()
        let et = moment(json.endsAt + " " + json.endsAtTime).unix()
        if (resImg.data.urls[0] !== '' && json.offerName !== '' && json.startsAt !== '' && json.endsAt !== '' && json.price) {
            try {
                // console.log('checked fields');
                // console.log(offerFactory);
                const total = await getOfferCount(offerFactory);
                // console.log(json.targetCommunities.map(i => i.contractAddress));
                console.log(window.web3.currentProvider, 'done')
                let res = await createOffer(
                    offerFactory,
                    {
                        offerId: total + 1,
                        creatorName: userBrand.name,
                        offerName: json.offerName,
                        creatorAddress: userBrand.walletAddress,
                        bannerUri: resImg.data.urls[0],
                        name: json.offerName,
                        image: resImg.data.urls[0],
                        price: json.price,
                        count: json.count,
                        description: json.desc,
                        createdAt: moment().unix(),
                        startsAt: st,
                        endsAt: et,
                        targetCommunities: json.targetCommunities.map(i => i.contractAddress),
                        chain: selectedNetwork.chain
                    },
                    st,
                    et,
                    json.count,
                    Web3.utils.toWei(json.price),
                    json.targetCommunities.map(i => i.contractAddress),
                    json.offerName,
                    user.walletAddress,
                    Web3,
                    {
                        offerName: json.offerName,
                        bannerUri: resImg.data.urls[0],
                        price: json.price,
                        count: json.count,
                        description: json.desc,
                        createdAt: moment().unix(),
                        createdBy: user._id,
                        startsAt: st,
                        endsAt: et,
                        contractAddress: '',
                        brand: userBrand._id,
                        targetCommunities: json.targetCommunities,
                        offerUri: '',
                        chain: selectedNetwork.chain
                    },
                    token,
                    setUpload,
                    selectedNetwork.chain
                );
                if (res) {
                    // console.log(res);
                    await UserServices.updateMe(
                        {
                            created: [...user.created, res.data._id],
                        }, token
                    ).then(async (res) => {
                        // console.log(res, 'updateuser')
                        await loadAllOffers(setAllOffers, selectedNetwork);
                        localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                        // setUser( res.data)
                        successHandler('Offer created successfully 🎉');
                        navigate('/')
                        setUpload(false)
                        await UserServices.updateStates(res, setUser, setUserBrand, setUserCreatedOffers, setClaimed, setFav, token, setFavItems, setBought, setSold)
                        // console.log(user, userBrand, claimed, fav, userCreatedOffers)
                    })
                }
            } catch (error) {
                // console.log(error.message, 'errorcreate')
                axiosHandler('Offer could not be created');
                setUpload(false)
            }
        } else {
            axiosHandler('Please fill all the fields')
            setUpload(false)
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setJson({ ...json, [name]: value });
    }

    const handleChangeNegative = (e) => {
        if (e.target.name === 'price') {
            if (e.target.value >= 0) {
                const name = e.target.name;
                const value = e.target.value;
                setJson({ ...json, [name]: value });
            } else {
                axiosHandler("Enter a valid number")
            }
        }
        else {
            if (e.target.value > 0 || e.target.value === "") {
                const name = e.target.name;
                const value = e.target.value;
                setJson({ ...json, [name]: value });
            } else {
                axiosHandler("Enter a valid number")
            }
        }
    }

    async function getImageFileObject(img) {
        setImgLoad(img.file)
        console.log(img, 'comm222')
    }
    function runAfterImageDelete(file) {
        // console.log({ file })
        console.log(file, 'comm222')

    }
    console.log(json)
    console.log(userBrand, 'brand')

    const forALLClicked = () => {
        setJson({ ...json, targetCommunities: [] })

        setAllClicked(!allClicked)
        // console.log('clicked')

    }
    const addComCLicked = () => {
        const arr = addClick
        arr.push('')
        console.log(arr)
        setAddClick([...arr])
    }
    useEffect(() => { console.log(addClick, 'addd') }, [addClick])

    useEffect(() => {
        localStorage.getItem("deOffersBrand") !== 'undefined' && JSON.parse(localStorage.getItem("deOffersBrand")) ? setOpen(false) : setOpen(true)

        const func = async () => {
            await getCreateFee(offerFactory)
                .then((res) => {
                    // console.log(res)
                    setMarket(res / 10)
                })
        }
        func()
    }, [userBrand !== null, open])

    useEffect(() => {
        const func = async () => {
            await getCreateFee(offerFactory)
                .then((res) => {
                    // console.log(res)
                    setMarket(res / 10)
                })
        }
        func()
    }, [])

    const deleteAddText = (i) => {
        const arr2 = addClick.filter((text, id) => id !== i)
        setAddClick(arr2)
        const arr = addText.filter((text, id) => id !== i)
        setAddText(arr)
        const temp = [...new Set([...arr, ...selected])]
        setJson({ ...json, targetCommunities: temp })
    }

    const loadMore = async () => {
        setPage(page + 1)
        await CommunityServices.getPaginatedCommunities(page, size, selectedNetwork.chain)
            .then((res) => {
                setOptions([...options, ...res.data])
                setOptions2([...options, ...res.data])
                // console.log(res)
            })
    }

    useEffect(() => {
        loadMore()
    }, [selectedNetwork])

    const customNoDataRenderer = ({ props, state }) => {
        return <>
            <Grid onClick={addComCLicked} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                <CardMedia component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image='https://cdn-icons-png.flaticon.com/512/262/262038.png' />
                Add Community
            </Grid>
            <Grid onClick={forALLClicked} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                <CardMedia component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image='https://www.svgrepo.com/download/52765/open.svg' />
                All Communities
            </Grid>
        </>
    }

    const customItemRenderer = ({ item, itemIndex, methods }) => {
        return <>
            {
                itemIndex === 0 ? <>
                    <Grid onClick={addComCLicked} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                        <CardMedia key={itemIndex} component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image='https://cdn-icons-png.flaticon.com/512/262/262038.png' />
                        Add Community
                    </Grid>
                    <Grid onClick={forALLClicked} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                        <CardMedia key={itemIndex} component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image='https://www.svgrepo.com/download/52765/open.svg' />
                        All Communities
                    </Grid>
                    <Grid onClick={() => methods.addItem(item)} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                        <CardMedia key={itemIndex} component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image={item.img} />
                        {item.name}
                    </Grid>
                </>
                    : options2.length - 1 === itemIndex ? <>

                        <Grid onClick={() => methods.addItem(item)} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                            {
                                item.img === "https://learnhrm.shrm.org/wp-content/uploads/2020/04/logo-placeholder-generic.200x200.png" || item.img === "" ?
                                    <Avatar key={itemIndex} sx={{ ...style.communityImg, fontSize: '20px', color: '#BC09C7', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: '700' }}>{item.name[0].toUpperCase()}</Avatar>
                                    : <CardMedia key={itemIndex} component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image={item.img} />
                            }

                            {item.name}
                        </Grid>
                        <Button onClick={loadMore}>Load More</Button>
                    </> : <Grid onClick={() => methods.addItem(item)} sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '2%' }}>
                        {
                            item.img === "https://learnhrm.shrm.org/wp-content/uploads/2020/04/logo-placeholder-generic.200x200.png" || item.img === "" ?
                                <Avatar key={itemIndex} sx={{ ...style.communityImg, fontSize: '20px', color: '#BC09C7', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: '700' }}>{item.name[0].toUpperCase()}</Avatar>
                                : <CardMedia key={itemIndex} component='img' sx={{ width: { md: '30px', sm: '30px', xs: '30px' }, border: '2px solid black', height: { md: '30px', sm: '30px', xs: '30px' }, borderRadius: '50%' }} image={item.img} />
                        }
                        {item.name}
                    </Grid>
            }
        </>

    }
    // console.log(moment(json.endsAt).unix() * 1000);
    // console.log(json);

    const searchcom = async (e, index) => {
        setLoad(true)
        const arr = addText
        arr[index] = e.target.value
        setAddText(arr)
        // console.log(arr)
        await getCommunityFromContractApi(e.target.value, json, setJson, token, index, isDev, selectedNetwork)
            .then((res) => {
                console.log(res, typeof (res), 'rkess')
                // if (res) {
                const arr1 = addText
                arr1[index] = json.targetCommunities[index]
                setAddText(arr1)
                setLoad(false)
                // }
            }).catch((err) => {
                console.log(err, 'rkess')
                axiosHandler('khushi')
            })
    }

    console.log(json, addText)


    // console.log(json)
    const custonOptionRenderer = (value) => {
        console.log(value)
        if (json.targetCommunities.length <= countFields) {
            return <>
                <Chip size='small' label={value.item.name} onDelete={(event) => {
                    value.methods.removeItem(event, value.item, true)
                    handleTargetCommunities(value.state.values)
                }} />
            </>
        } else {
            value.methods.removeItem(value.item, true)
            return <div></div>
        }
    }

    console.log(disable, 'kkkkkkkkk')

    return (
        <div style={{
            backgroundRepeat: 'no-repeat',
            backgroundImage: `linear-gradient(0deg, rgba(245,246,248,0) 0%, rgba(245,246,248,0) 35%, rgba(188,9,199,0.1) 100%), linear-gradient(0deg, rgba(245,246,248,2) 0%, rgba(245,246,248,2) 35%, rgba(245,246,248,0.6) 100%)`,
            backgroundBlendMode: 'screen',
            borderRadius: '10px',
            paddingTop: '3%',
            paddingRight: '3%',
            backgroundSize: '100% 100%'
        }}>
            {userBrand && <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item md={4} container sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', height: { md: '80vh', sm: '70vh' }, overflow: 'scroll', "&::-webkit-scrollbar": { display: 'none' } }}>
                    <Card sx={{ width: { md: '22.3vw' }, height: { md: '75vh', sm: '70vh', xs: '55vh' } }}>
                        <CardContent>
                            {
                                <><Box sx={{ display: { md: 'flex', sm: 'flex', xs: 'none' } }}> <ImageUploader
                                    style={{ height: '300px', width: '300px', marginBottom: '8%' }}
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)} /></Box>
                                    <Box sx={{ display: { md: 'none', sm: 'none', xs: 'flex' } }}> <ImageUploader
                                        style={{ height: '250px', width: '250px', marginBottom: '8%' }}
                                        onFileAdded={(img) => getImageFileObject(img)}
                                        onFileRemoved={(img) => runAfterImageDelete(img)} /></Box>
                                </>
                            }
                            <Grid container sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant='p' sx={style.heading}>{json.offerName}</Typography>
                                    <Typography variant='p' sx={style.p}> {`From ${userBrand?.name}`}</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ padding: '2%' }}>
                                    <Divider orientation='vertical' />
                                </Grid>
                                <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography variant='p' sx={style.p}>Price</Typography>
                                    <Typography variant='p' sx={style.heading}>{(String(json.price ? json.price : 0))} WETH</Typography>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12} sx={{ margin: '2% 0%' }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>

                                    {allClicked ? <Chip label={"All communities"} size="small" sx={{ backgroundColor: '#3ED1CF', color: 'white', marginLeft: '1px' }} />
                                        : json.targetCommunities.map((community, index) => (
                                            community !== undefined && community?.img ? <Avatar onClick={() => navigate(`/communitydetails/${community?._id}`, { state: { id: community?._id } })} key={index} sx={{ border: '2px solid black' }} src={community?.img} /> :
                                                <Avatar onClick={() => navigate(`/communitydetails/${community?._id}`, { state: { id: community?._id } })} key={index} sx={{ border: '3px solid #BC09C7', color: '#BC09C7', backgroundColor: 'white' }}>{community?.name.slice(0, 1)}</Avatar>
                                        ))}


                                </Grid>
                                <Grid item md={12} sm={12} xs={12} sx={{ margin: '2% 0%' }}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography variant='p' sx={style.p}>
                                        Ends in
                                    </Typography>
                                    {json.endsAt !== '' && <Countdown date={moment(json.endsAt).unix() * 1000} renderer={({ days, hours, minutes, seconds, completed }) => completed ? "" : <Typography variant='p' sx={style.countdown}>{days}d : {hours}h : {minutes}m : {seconds}s</Typography>} />}

                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button sx={{ backgroundColor: '#292D32', color: 'white', textTransform: 'none', '&:hover': { color: 'black' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }} style={{ color: 'white' }}>Redeem</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container md={8} sx={{ height: '80vh', overflow: 'scroll', "&::-webkit-scrollbar": { display: 'none' } }} >
                    <Grid item md={12} >
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}
                        >
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Card sx={{ borderLeft: '4px solid #BC09C7', borderRadius: '10px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                        <CardContent>
                                            <Grid container columnSpacing={3} >
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Creator Name</p>
                                                    <TextField required value={JSON.parse(localStorage.getItem("deOffersBrand")).name} disabled sx={textField} name='creatorName' placeholder='Enter Creator Name' id='creatorName' onChange={handleChange} />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Creator's Address</p>
                                                    <TextField required value={json.creatorAddress} disabled sx={textField} name='creatorAddress' placeholder="Enter Creator's Address" id='creatorAddress' onChange={handleChange} />
                                                </Grid>
                                            </Grid>
                                            <p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '3%' }}>Offer Name</p>
                                            <TextField required value={json.offerName} sx={textField} name='offerName' placeholder='Enter Offer Name' id='offerName' onChange={handleChange} />
                                            <p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '3%' }}>Description</p>

                                            <TextField multiline rows={4} required value={json.desc} sx={textField} name='desc' placeholder="Enter description" id='desc' onChange={handleChange} />

                                        </CardContent>
                                    </Card>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Card sx={{ borderLeft: '4px solid #BC09C7', borderRadius: '10px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', marginTop: '3%' }}>
                                        <CardContent>
                                            {(addClick.length) ? <Grid container columnSpacing={3} sx={{ marginTop: '1%', padding: '0' }}>
                                                <Grid item md={3} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Target communities</p>
                                                    <TextField required value={countFields} sx={textField} name='countFields' type='number' id='countFields' onChange={handleCount} />
                                                </Grid>
                                                <Grid item md={9} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Address of target communities</p>
                                                    {addClick.map((e, i) => <TextField sx={textField} InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment sx={{ cursor: 'pointer' }} onClick={() => deleteAddText(i)} position="start">
                                                                <DeleteIcon />
                                                            </InputAdornment>
                                                        )
                                                    }} key={i} placeholder='Enter Contract Address' value={addText[i]?.name} onChange={(e) => searchcom(e, i)} />)}

                                                    <Select
                                                        multi
                                                        options={options2}
                                                        value={[...selected]}
                                                        valueField="contractAddress"
                                                        itemRenderer={customItemRenderer}
                                                        noDataRenderer={customNoDataRenderer}
                                                        noDataLabel={customNoDataRenderer}
                                                        labelField="name"
                                                        searchBy="name"
                                                        color='#BC09C7'
                                                        searchFn={(prop) => {
                                                            console.log(prop)
                                                            const func = async () => {
                                                                let res = await Search.searchCommunities(prop.state.search)
                                                                console.log(res.data, 'resbrands');
                                                                setOptions2(() => [...res.data])
                                                                return options2
                                                            }
                                                            if (prop.state.search !== '') {
                                                                func()
                                                            } else {
                                                                return options
                                                            }
                                                        }}
                                                        style={{ height: '3.4375em' }}
                                                        sortBy="name"
                                                        onChange={(value) => {
                                                            if (selected.length < value.length) {
                                                                handleTargetCommunities2(value)
                                                            } else {
                                                                const temp = [...new Set([...addText, ...value])]
                                                                setJson({ ...json, targetCommunities: temp })
                                                            }
                                                        }}
                                                    />

                                                </Grid>
                                            </Grid> : allClicked ? <>
                                                <Grid container columnSpacing={3} sx={{ padding: '0' }}>
                                                    <Grid item md={11}>
                                                        <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Target communities</p>
                                                        {/* <TextField sx={textField} placeholder='All communities' disabled /> */}

                                                        <FixedTags />
                                                    </Grid>
                                                    <Grid item md={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem' }}>
                                                        <DeleteIcon onClick={forALLClicked} sx={{ cursor: 'pointer' }} />
                                                    </Grid>
                                                </Grid>
                                            </> : <Grid container columnSpacing={3} sx={{ padding: '0' }}>
                                                <Grid item md={3} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Target communities</p>
                                                    <TextField required value={countFields} sx={textField} name='countFields' type='number' id='countFields' onChange={handleCount} />
                                                </Grid>
                                                <Grid item md={9} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Address of target communities</p>
                                                    <Select
                                                        multi
                                                        options={options2}
                                                        value={[...selected]}
                                                        valueField="contractAddress"
                                                        itemRenderer={customItemRenderer}
                                                        noDataRenderer={customNoDataRenderer}
                                                        labelField="name"
                                                        searchBy="name"
                                                        disabled={disable}
                                                        searchFn={(prop) => {
                                                            console.log(prop)
                                                            // prop.state.values = selected
                                                            const func = async () => {
                                                                let res = await Search.searchCommunities(prop.state.search)
                                                                console.log(res.data, 'resbrands');
                                                                setOptions2(() => [...res.data])
                                                                return options2
                                                            }
                                                            if (prop.state.search !== '') {
                                                                func()
                                                            } else {
                                                                return options
                                                            }
                                                        }}
                                                        className='dropdown-scroll-container'
                                                        color='#BC09C7'
                                                        style={{ height: '3.4375em' }}
                                                        sortBy="name"
                                                        onChange={(value) => {
                                                            if (selected.length < value.length) {
                                                                // console.log(value)
                                                                handleTargetCommunities(value, selected, setSelected)
                                                                return value
                                                            } else {
                                                                setSelected(value)
                                                                const temp = [...new Set([...addText, ...value])]
                                                                setJson({ ...json, targetCommunities: temp })
                                                                return value
                                                            }
                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>}
                                            {/* <p style={{ fontSize: '12px', fontWeight:'bold', marginTop: '3%' }}>Banner image</p> */}
                                            {/* <input sx={textField} multiple name='file' value={json.bannerUri} type='file' id='bannerUri' onChange={img} /> */}



                                            <Grid container columnSpacing={2} sx={{ marginTop: '3%' }}>
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '3%' }}>Offer starting at</p>
                                                    <TextField required value={json.startsAt} sx={textField} InputProps={{ inputProps: { min: moment().format('YYYY-MM-DD') } }} type="date" name='startsAt' id='startsAt' onChange={handleChange} />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '3%' }}>Offer ending at</p>
                                                    <TextField required value={json.endsAt} disabled={json.startsAt === ''} InputProps={{ inputProps: { min: json.startsAt, max: maxDate } }} sx={textField} type="date" name='endsAt' id='endsAt' onChange={handleChange} />
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ marginTop: '3%' }}>
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Price (WETH)</p>
                                                    <TextField required value={json.price} sx={textField} type="text" name='price' id='price' onChange={handleChangeNegative} />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Count</p>
                                                    <TextField required value={json.count} sx={textField} type="text" name='count' id='count' onChange={handleChangeNegative} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box sx={{ width: '100%', marginTop: '3%', bgcolor: 'white', color: '#3782B7', padding: '2%', borderRadius: '5px', boxShadow: 'none', border: '2px dashed #E2E2E2' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                                                <InfoIcon sx={{ marginRight: '2%' }} />
                                                Fees ({market}%)
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <strong>{json.price ? `-${(json.price * market / 100).toFixed(8)}WETH` : `0 WETH`}</strong>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex', marginTop: '3%', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                                                <CallReceivedIcon sx={{ marginRight: '2%' }} />
                                                Receive (per offer)
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <strong>{json.price ? `${(json.price - (json.price * market / 100)).toFixed(8)} WETH` : `0 WETH`}</strong>
                                            </div>
                                        </div>
                                    </Box>
                                    {
                                        upload ? <Box sx={{ marginTop: '5%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} />
                                        </Box> : <Button onClick={onSubmitOfferJson} sx={{ '&:hover': { backgroundColor: 'white', color: '#BC09C7' }, border: '2px solid #BC09C7', backgroundColor: '#BC09C7', color: 'white', width: '100%', marginTop: '5%', textTransform: 'none' }} >Create</Button>
                                    }
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Grid>





                </Grid>
            </Grid>}
            {
                <BrandModal open={open} handleOpen={handleOpen} handleClose={handleClose} setOpen={setOpen} user={user} />
            }
        </div>
    )
}

export default CreateOffer