import React, { useContext } from 'react'
import { useLocation } from 'react-router';
import BrandDetails from '../components/details/Brand/BrandDetails';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

function BrandDetailPage() {
    const { user } = useContext(offerContext)
    return (
        <>
            <SideDrawer currentUser={user}>
                <BrandDetails />
            </SideDrawer>
        </>
    )
}

export default BrandDetailPage;