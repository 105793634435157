import { Button, Card, CardContent, CardMedia, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useContext } from 'react'
import axiosHandler from '../../helpers/axiosHandler'
import successHandler from '../../helpers/successHandler'
import { offerContext } from '../../offerContext'
import { ImageBannerContext } from '../../pages/EditProfile'
import IpfsServices from '../../services/IpfsServices'
import UserServices from '../../services/UserServices'
import { useNavigate } from 'react-router'

const btn = { backgroundColor: '#f2f2f2', color: 'black', border: '2px solid #0000001c', "&:hover": { backgroundColor: '#f2f2f2', color: 'black' }, cursor: 'context-menu' }

function EditFields() {
    const navigate = useNavigate()
    const { image, setImage, banner, setBanner, username, setUsername, email, setEmail, bio, setBio, socials, setSocials, profileImage, setProfileImage, profileBanner, setProfileBanner } = useContext(ImageBannerContext)
    // const user2 = JSON.parse(localStorage.getItem("deOffersUser"))
    const { user, setUser, token } = useContext(offerContext)
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSocials({ ...socials, [name]: value });
    }

    const socialMedia = Object.keys(socials).map((social) => (
        {
            name: social,
            link: socials[social],
        }
    ))

    const handleSubmit = async () => {
        if (email) {
            let form2Data = new FormData()
            form2Data.append('files', profileBanner);
            // console.log(img.file, token)
            const ban = profileBanner ? typeof (profileBanner) !== 'string' ? await IpfsServices.uploadImg(form2Data, token) : { data: { urls: [user.profileBanner] } } : { data: { urls: [""] } }

            let form3Data = new FormData()
            form3Data.append('files', profileImage);
            // console.log(img.file, token)
            const imgres = profileImage ? typeof (profileImage) !== 'string' ? await IpfsServices.uploadImg(form3Data, token) : { data: { urls: [user.profileImage] } } : { data: { urls: [""] } }
            console.log(ban, imgres, form2Data, form3Data)

            await UserServices.updateMe({
                profileImage: imgres.data.urls[0],
                profileBanner: ban.data.urls[0],
                username: username,
                bio: bio,
                email: email,
                socialHandles: socialMedia,
            }, token)
                .then((res) => {
                    localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                    setUser(res.data);
                    successHandler('Changes has been saved')
                    navigate('/userprofile')
                })
                .catch((err) => {
                    axiosHandler(err.message)
                })
        } else {
            axiosHandler('Email is required')
        }
    }

    return (
        <>
            <Grid container spacing={5} sx={{ marginTop: '5%' }}>
                <Grid item md={6} sx={{ width: '100%' }}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item>
                                    <Typography sx={{ fontFamily: 'Poppins', color: '#BC09C7', fontWeight: 'bold' }}>User Details</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField sx={{ width: '100%' }} name='username' id='username' placeholder='Enter Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <TextField sx={{ width: '100%' }} name='email' id='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <TextField sx={{ width: '100%' }} multiline rows={5} name='bio' id='bio' placeholder='Tell about yourself!' value={bio} onChange={(e) => setBio(e.target.value)} />
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} sx={{ width: '100%' }}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item>
                                    <Typography sx={{ fontFamily: 'Poppins', color: '#BC09C7', fontWeight: 'bold' }}>Socials</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={0}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CardMedia sx={{ width: '25px' }} component='img' image='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png' />
                                                    </InputAdornment>
                                                ),
                                            }} type="text" value={socials.instagram} onChange={handleChange} name='instagram' id='instagram' sx={{ width: '103%' }} placeholder='Username' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={0}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CardMedia sx={{ width: '25px' }} component='img' image='https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png' />
                                                    </InputAdornment>
                                                ),
                                            }} type="text" value={socials.twitter} name='twitter' onChange={handleChange} id='twitter' sx={{ width: '103%' }} placeholder='Username' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={0}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CardMedia sx={{ width: '25px' }} component='img' image='https://cdn-icons-png.flaticon.com/512/174/174857.png' />
                                                    </InputAdornment>
                                                ),
                                            }} type="text" value={socials.linkedin} name='linkedin' onChange={handleChange} id='linkedin' sx={{ width: '103%' }} placeholder='Username' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={4} />
                <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%', width: '100%' }}>
                    <Button sx={{ backgroundColor: '#BC09C7', width: '90%', color: 'white', textTransform: 'none' }} onClick={handleSubmit}>Save Changes</Button>
                </Grid>
                <Grid item md={4} />
            </Grid>
        </>
    )
}

export default EditFields