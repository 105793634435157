import { Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import NoLiveOffers from '../nothingToSee/NoLiveOffers'
import { offerContext } from '../../offerContext'
import { useNavigate } from 'react-router'
import moment from 'moment'
import OfferCard from './card/OfferCard'

function ListedOffers({ communities }) {
    const { eligible, listings } = useContext(offerContext)
    const navigate = useNavigate()
    const checkStatus = (i) => {
        const now = moment().unix();
        let stats

        if (now > i.endsAt) {
            stats = "Expired"
        }
        else if (i.startsAt > now) {
            stats = "Upcoming";
        }

        else if (i.startsAt <= now <= i.endsAt) {
            stats = "Active";
        }
        return stats
    }
    console.log(listings)
    return (
        <>
            <Grid sx={{ marginTop: '5%' }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item>
                        <Typography variant='h4' sx={{ fontWeight: 'bolder', fontSize: { sm: '20px', xs: '15px' }, fontFamily: 'Poppins', margin: '0', padding: '0' }}>Trending Items</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => navigate('/items')}>Show More</Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '1%' }} spacing={2}>
                    {listings && listings.filter(i => checkStatus(i) !== "Expired" && !i.isSold).length !== 0 ? listings.filter(i => checkStatus(i) !== "Expired" &&  !i.isSold).map((offer, index) => {
                        if (index < 4) {
                            return <React.Fragment key={index}>
                                <Grid item md={3} xs={12}>
                                    <OfferCard offerDets={offer} communities={communities} />
                                </Grid>
                            </React.Fragment>
                        }
                    }) : <NoLiveOffers text={'No Listed offers to show'} />}
                </Grid>
            </Grid>

        </>
    )
}

export default ListedOffers