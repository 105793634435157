import { CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import check from '../../images/check.png'
function Two({ offer, activeStep, setActiveStep, json, setJson }) {
    return (
        <>
            <Grid container >
                <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh', width: '100%' }}>
                    <CardMedia component='img' image={check} sx={{ height: '15vh', width: 'auto' }} />
                    <Typography variant='h5' sx={{ fontWeight: '800', fontFamily: 'Poppins', color: '#292D32' }} > Confirm to continue</Typography>
                    <Typography variant='p' sx={{ margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' }}>Please make sure that the details filled are correct</Typography>
                    <Typography variant='p' sx={{ margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' }}>the creator will reach you through provided email</Typography>
                    <Typography variant='p' sx={{ margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' }}>on pressing the confirmation button the NFT </Typography>
                    <Typography variant='p' sx={{ margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' }}>will be burnt with the transaction and you will have successfully redeemed the offer.</Typography>

                </Grid>
            </Grid>
        </>
    )
}

export default Two
