import { Avatar, Box, CardMedia, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import axiosHandler from '../../../helpers/axiosHandler';
import BrandServices from '../../../services/BrandServices';
import VerifiedIcon from '@mui/icons-material/Verified';
import SortIcon from '@mui/icons-material/Sort';

const style = {
    pTag: { color: '#424242', fontSize: '12px' },
    margin: { marginTop: '3%' },
    icon: { color: '#424242', fontSize: '20px' },
    desc: { color: '#636363', fontSize: '11px' },
    btn: { textTransform: 'none', width: '100%', backgroundColor: '#BC09C7', color: 'white' },
    disabled: { backgroundColor: 'gray', color: 'white !important' },
    center: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' },
    withdrawBalance: { backgroundColor: 'white', border: '2px solid #BC09C7', color: '#BC09C7', height: '100%', "& :hover": { backgroundColor: 'white', border: '2px solid #BC09C7', color: '#BC09C7' } },
    pTagError: { color: 'red', fontSize: '8px' },
    btnCheck: {
        color: '#BC09C7',
        width: '100%',
        height: '100%'
    }
}

function BrandAbout({ brand }) {


    return (
        <>
            {Object.keys(brand).length !== 0 && <Box >
                <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item md={1.4} xs={3} sm={2}>
                        {brand?.img ? <CardMedia component='img' image={brand.img} sx={{ borderRadius: '50%', width:{md:'150px', sm:'100px', xs:'80px'}, height:{md:'150px', sm:'100px', xs:'80px'} }} /> 
                        : <Avatar sx={{fontSize: '10rem', border:'2px solid #BC09C7', color: '#BC09C7', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: '800', height:'100%', width:'100%'}}>{brand.name.slice(0,1)}</Avatar>}
                    </Grid>
                    <Grid item md={0.4} />
                    <Grid item md={5}>
                        <Typography variant='h5' sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', margin: '0', padding: '0' }}>
                            {brand.name}
                            {true && <VerifiedIcon sx={{ color: '#1A83FF', fontSize: '15px' }} />}
                        </Typography>
                        <Typography variant='p' sx={{ fontFamily: 'Poppins', fontSize: '12px', margin: '0', padding: '0', color: '#636363' }}>
                            {brand.walletAddress}
                        </Typography>
                        {
                            brand.description && <>
                                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                    <SortIcon style={{ ...style.margin, ...style.icon }} />
                                    <p style={{ ...style.pTag, ...style.margin }} >Description</p>
                                </Grid>
                                <p style={style.desc}>{brand.description}</p>

                            </>
                        }
                    </Grid>

                </Grid>
            </Box>}
        </>
    )
}

export default BrandAbout