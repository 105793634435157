import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu'
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, CardMedia, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, Popper } from '@mui/material';
import Navbar from '../Navbar';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import Search, { searchBrands } from '../../services/Search';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../images/logo.png'
import SearchIcon from '@mui/icons-material/Search';
import Web3 from 'web3';

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#BC09C7"
        },
        "& .MuiOutlinedInput-input": {
            // backgroundColor: "rgba(217, 217, 217, 0.1)",
            // backdropFilter: "blur(12.852px)",
            // boxShadow: 'inset 47.6px -47.6px 47.6px rgba(165, 165, 165, 0.1), inset -47.6px 47.6px 47.6px rgba(255, 255, 255, 0.1)',
            color: 'black',
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "black"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#BC09C7"
        },
        "& .MuiInputLabel-outlined": {
            color: "black"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "black"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#BC09C7"
        },
        "& .MuiOutlinedInput-root ": {
            width: '100%'
        }
    }
});

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ElevateAppBar(props) {
    const { openDrawer, narrowDrawerWidth, drawerWidth, handleDrawerToggle } = props
    const [search, setSearch] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [brandSearch, setBrandSearch] = useState([])
    const [communitySearch, setCommunitySearch] = useState([])
    const [userSearch, setUserSearch] = useState([])
    const [offerSearch, setOfferSearch] = useState([])
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? event.currentTarget : event.currentTarget);
    };
    const id = search !== 0 ? 'simple-popper' : undefined;
    const navigate = useNavigate()

    useEffect(() => {
        const func = async () => {
            await Search.searchBrands(search)
                .then((res) => {
                    // console.log(res.data, 'resbrands');
                    setBrandSearch(res.data)
                }).catch((e) => console.log(e))
        }

        search !== '' && func()
    }, [search])

    useEffect(() => {
        const func = async () => {
            await Search.searchOffers(search)
                .then((res) => {
                    // console.log(res.data, 'resbrands');
                    setOfferSearch(res.data)
                }).catch((e) => console.log(e))
        }

        search !== '' && func()
    }, [search])

    useEffect(() => {
        const func = async () => {
            await Search.searchOffersByAddress(search)
                .then((res) => {
                    // console.log(res.data, 'resbrands');
                    setOfferSearch([res.data])
                }).catch((e) => console.log(e))
        }

        search !== '' && func()
    }, [Web3.utils.isAddress(search)])

    useEffect(() => {
        const func = async () => {
            await Search.searchCommunities(search)
                .then((res) => {
                    // console.log(res.data, 'resbrands');
                    setCommunitySearch(res.data)
                }).catch((e) => console.log(e))
        }

        search !== '' && func()
    }, [search])

    useEffect(() => {
        const func = async () => {
            await Search.searchUsers(search)
                .then((res) => {
                    // console.log(res.data, 'resbrands');
                    setUserSearch(res.data)
                }).catch((e) => console.log(e))
        }

        search !== '' && func()
    }, [search])

    useEffect(() => {
        if (search === '') {
            setBrandSearch([])
            setCommunitySearch([])
            setOfferSearch([])
            setUserSearch([])
        }
    }, [search])

    const classes = useStyles();
    return (
        <Box >
            <AppBar
                position="fixed"
                sx={openDrawer ? { flexGrow: 1, width: { sm: `calc(100% - ${narrowDrawerWidth}px)` }, boxShadow: 'none', backgroundColor: '#F5F6F8', color: 'black' } : { flexGrow: 1, boxShadow: 'none', width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: '#F5F6F8', color: 'black' }}

            >
                <Toolbar>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid lg={2} md={2} sm={2} xs={2} item sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <CardMedia component='img' onClick={() => navigate('/')} image={logo} sx={{ width: '90px', cursor: 'pointer' }} />
                            {
                                openDrawer && <Typography className='nfthodlr' variant='h5' sx={{ color: '#BC09C7', fontFamily: 'Poppins', fontWeight: 'bolder', display: { md: 'flex', sm: 'none', xs: 'none' } }}>NFTHodlr</Typography>
                            }
                        </Grid>
                        <Grid item lg={5} md={4} sm={openDrawer ? 3 : 2} xs={7} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {/* <div style={{ width: '100%' }} > */}
                            <TextField className={classes.root} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }} sx={{ marginLeft: '2%', position: 'relative', width: '100%' }} onClick={handleClick} onChange={(e) => setSearch(e.target.value)} value={search} fullWidth placeholder='Search Brands and Communities' size='small' variant="outlined" />
                            <Popper id={id} onClose={() => setSearch('')} open={search !== ''} anchorEl={anchorEl} sx={{ zIndex: 2000, position: 'absolute', '& .css-cevtoy': { border: '2px solid #747474', borderRadius: '10px' }, height: '40vh', width: { md: '35%', sm: '40%', xs: '60%' } }}>
                                <Box sx={{ border: 1, p: 1, height: '100%', bgcolor: 'background.paper', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none' }, backgroundColor: 'white' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <CloseIcon onClick={() => setSearch('')} sx={{ fontSize: '12px', zIndex: '10000000', color: 'black', display: 'flex', justifyContent: 'end', cursor: 'pointer', textAlign: 'right' }} />
                                    </Box>
                                    <div>
                                        {
                                            userSearch.length !== 0 && <Accordion expanded>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Users</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        {
                                                            userSearch.length !== 0 && userSearch.map((brand) => {
                                                                return <>
                                                                    <Grid item container md={12} onClick={() => {
                                                                        navigate(`/userdetails/${brand.walletAddress.toLocaleLowerCase()}`, { state: { id: brand._id } })
                                                                        setSearch('')
                                                                    }} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Grid item xs={1.5}>
                                                                            {
                                                                                !brand?.profileImage ? <Avatar sx={{ width: '40px', height: '40px', border: '2px solid black', borderRadius: '50%', display: 'flex', justifyContent: 'center', color: '#BC09C7', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: '800' }}>{brand.username[0].toUpperCase()}</Avatar>
                                                                                    : <CardMedia sx={{ borderRadius: '50%', width: '40px', height: '40px' }} component='img' image={brand.profileImage} />
                                                                            }

                                                                        </Grid>
                                                                        <Grid item xs={0.5} />
                                                                        <Grid item xs={10}>
                                                                            <Typography variant='p' sx={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{brand.username}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            })
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {
                                            brandSearch.length !== 0 && <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Brands</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        {
                                                            brandSearch.length !== 0 && brandSearch.map((brand) => {
                                                                return <>
                                                                    <Grid item container md={12} onClick={() => {
                                                                        navigate(`/branddetails/${brand._id}`, { state: { id: brand._id } })
                                                                        setSearch('')
                                                                    }} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Grid item xs={1.5}>
                                                                            <CardMedia sx={{ borderRadius: '50%', width: '40px', height: '40px' }} component='img' image={brand.img} />
                                                                        </Grid>
                                                                        <Grid item xs={0.5} />
                                                                        <Grid item xs={10}>
                                                                            <Typography variant='p' sx={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{brand.name}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            })
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {
                                            communitySearch.length !== 0 && <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Communities</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} >
                                                        {
                                                            communitySearch.length !== 0 && communitySearch.map((community) => {
                                                                return <>
                                                                    <Grid item container md={12} onClick={() => navigate(`/communitydetails/${community._id}`, { state: { id: community._id } })} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                        <Grid item xs={1.5}>
                                                                            <CardMedia sx={{ borderRadius: '50%', width: '40px', height: '40px' }} component='img' image={community.img} />
                                                                        </Grid>
                                                                        <Grid item xs={0.5} />
                                                                        <Grid item xs={10}>
                                                                            <Typography variant='p' sx={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{community.name}</Typography>
                                                                        </Grid>

                                                                    </Grid>
                                                                </>
                                                            })
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {
                                            offerSearch.length !== 0 && <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Offers</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} >
                                                        {
                                                            offerSearch.length !== 0 && offerSearch.map((offer) => {
                                                                return <>
                                                                    <Grid item container md={12} onClick={() => navigate(`/offerdetails/${offer._id}`, { state: { id: offer._id } })} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                        <Grid item xs={1.5}>
                                                                            <CardMedia sx={{ borderRadius: '50%', width: '40px', height: '40px' }} component='img' image={offer.bannerUri} />
                                                                        </Grid>
                                                                        <Grid item xs={0.5} />
                                                                        <Grid item xs={10}>
                                                                            <Typography variant='p' sx={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{offer.offerName}</Typography>
                                                                        </Grid>

                                                                    </Grid>
                                                                </>
                                                            })
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                    </div>



                                </Box>
                            </Popper>
                            {/* </div> */}
                        </Grid>
                        <Grid item lg={4} md={5} sm={7}>
                            <Navbar currentUser={props.currentUser} loadWeb3={props.loadWeb3} openDrawer={openDrawer} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box >
    );
}
