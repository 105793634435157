import React, { useContext } from 'react'
import OfferDetails from '../components/offerDetails/OfferDetails';
import SavedComponent from '../components/saved/SavedComponent';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

function Saved({ allCommunities }) {
    const { user } = useContext(offerContext)

    return (
        <>
            <SideDrawer currentUser={user}>
                <SavedComponent allCommunities={allCommunities} />
            </SideDrawer>
        </>
    )
}

export default Saved;