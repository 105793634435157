import { CircularProgress, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import { offerContext } from '../../offerContext';


function One({ offer, activeStep, setActiveStep, json, setJson }) {
    const textField = { width: '100%' };
    const { user } = useContext(offerContext)


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setJson({ ...json, [name]: value });
    }

    return (
        <>
            <Grid container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Grid item sm={12} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Email</p>
                    <TextField value={json.redeemEmail} required sx={textField} name='redeemEmail' placeholder='Enter your Email' id='redeemEmail' onChange={handleChange} />
                </Grid>
                <Grid item sm={12} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Name</p>
                    <TextField value={json.redeemName} required sx={textField} name='redeemName' placeholder='Enter your name' id='redeemName' onChange={handleChange} />
                </Grid>
                <Grid item sm={12} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Phone No.</p>
                    <TextField required sx={textField} value={json.redeemPhoneNo} name='redeemPhoneNo' placeholder='Enter Phone No.' id='redeemPhoneNo' onChange={handleChange} />
                </Grid>
                <Grid item sm={12} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Address</p>
                    <TextField required sx={textField} value={json.redeemAddress} name='redeemAddress' placeholder='Enter Address' id='redeemAddress' onChange={handleChange} multiline rows={4} />
                </Grid>
            </Grid>
        </>
    )
}

export default One