import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useNavigate } from "react-router";
import AddIcon from '@mui/icons-material/Add';
import '../../assets/SideDrawer.css'
import PersonIcon from '@mui/icons-material/Person';
import { Badge, ListItemText } from '@mui/material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useContext } from 'react'
import { offerContext } from '../../offerContext'
import ClassIcon from '@mui/icons-material/Class';

function BroadDrawer() {
    const navigate = useNavigate()
    const { user } = useContext(offerContext)
    const url = window.location.href.split('/')[3]

    return (
        <>
            {
                user ? <div>
                    <Box className="logoBox" >
                    </Box>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/userprofile')}>
                            <ListItemIcon>
                                <PersonIcon sx={url =='userprofile' ? { backgroundColor: '#BC09C7', padding: '5px', color: 'white', borderRadius: '50%' } : { backgroundColor: '#7382989c', padding: '5px', color: 'white', borderRadius: '50%' }} />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ color: '#000', textDecoration: 'none!important' }}
                                primary="User Profile"
                            />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/')} >
                            <ListItemIcon>
                                <DashboardIcon sx={url === '' ? { color: '#BC09C7' } : { color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/create')} >
                            <ListItemIcon>
                                <AddIcon sx={url==='create' ? { color: '#BC09C7' } : { color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="Create Offer" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/offers')} >
                            <ListItemIcon>
                                <CollectionsBookmarkIcon sx={url==='offers' ? { color: '#BC09C7' } :{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="All Offers" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/items')} >
                            <ListItemIcon>
                                <ClassIcon sx={url==='items' ? { color: '#BC09C7' } :{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="All Items" />
                        </ListItem>
                    </List>
                    <Divider />

                    <List>
                        <ListItem button onClick={() => navigate('/createprofile')} >
                            <ListItemIcon>
                                <ManageAccountsIcon sx={url==='createprofile' ? { color: '#BC09C7' } :{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItem>
                    </List>
                    
                    {/* <Divider />
                    <List>
                        <ListItem button sx={{ paddingLeft: '0px' }} onClick={() => navigate('/usertransactions')} >
                            <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                                <ReceiptLongIcon sx={{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="User Transactions" />
                        </ListItem>
                    </List> */}
                </div> : <div>
                    <Box className="logoBox" >
                    </Box>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/userprofile')}>
                            <ListItemIcon>
                                <PersonIcon sx={url =='userprofile' ? { backgroundColor: '#BC09C7', padding: '5px', color: 'white', borderRadius: '50%' } : { backgroundColor: '#7382989c', padding: '5px', color: 'white', borderRadius: '50%' }} />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ color: '#000', textDecoration: 'none!important' }}
                                primary="User Profile"
                            />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/')} >
                            <ListItemIcon>
                                <DashboardIcon sx={url==='' ? { color: '#BC09C7' } :{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/create')} >
                            <ListItemIcon>
                                <AddIcon sx={{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="Create Offer" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => navigate('/offers')} >
                            <ListItemIcon>
                                <CollectionsBookmarkIcon sx={url==='offers' ? { color: '#BC09C7' } :{ color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="All Offers" />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem button onClick={() => navigate('/items')} >
                            <ListItemIcon>
                                <ClassIcon sx={url==='items' ? { color: '#BC09C7' } : { color: '#7382989c' }} />
                            </ListItemIcon>
                            <ListItemText primary="All Items" />
                        </ListItem>
                    </List>
                </div>
            }
        </>
    )
}

export default BroadDrawer