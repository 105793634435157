import React, { useContext, useEffect } from 'react'
import { FilterContext } from './FilterSidebar'
import { getCommNameFromContract } from '../../utils/helper';
import { Button, Grid, Typography } from '@mui/material';
import OfferCard from '../landingPageComponents/card/OfferCard';
import moment from 'moment/moment';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import NoLiveOffers from '../nothingToSee/NoLiveOffers';
import { offerContext } from '../../offerContext';

function FilterOffer({ allAds, allOffers, allCommunities, offers, items }) {
    const { brands, statuses, platforms, HL, NO, toggle, setToggle,sold } = useContext(FilterContext)
    const { listings } = useContext(offerContext)
    let brandkeys = Object.keys(brands)
    let statuskeys = Object.keys(statuses)
    let platformkeys = Object.keys(platforms)
    let listitems = listings
    // console.log(offers)
    let selectedBrand = brandkeys.filter((key) => {
        return brands[key]
    });

    let selectedStatus = statuskeys.filter((key) => {
        return statuses[key]
    });

    let selectedPlatform = platformkeys.filter((key) => {
        return platforms[key]
    });


    let filteredBrand, filteredPlatform, filteredSort, filteredStatus, list;

    if (offers && !items) {
        filteredBrand = offers.filter((brand) => {
            return selectedBrand.length !== 0 ? selectedBrand.includes(brand.brand && brand.brand.name) : brand
        })

        filteredPlatform = filteredBrand.filter((brand) => {
            let tempPla = [];
            let temp;
            brand.targetCommunities.length !== 0 ? brand.targetCommunities.forEach(comm => {
                temp = getCommNameFromContract(allCommunities, comm.contractAddress)
                tempPla.push(temp)
            }) : tempPla.push(brand)
            return tempPla.some(v => selectedPlatform.length !== 0 ? selectedPlatform.includes(v) : brand)
        })

        const checkStatus = (i) => {
            const now = moment().unix();
            // console.log(now)
            let stats

            if (now > i.endsAt) {
                stats = "Expired"
            }
            else if (i.startsAt > now) {
                stats = "Upcoming";
            }

            else if (i.startsAt <= now <= i.endsAt) {
                stats = "Active";
            }
            return stats
        }
        if (selectedStatus.includes('Expired')) {
            // console.log("yessss expiredddd");
            filteredStatus = filteredPlatform.filter(i => selectedStatus.length !== 0 ? selectedStatus.includes(checkStatus(i)) : i);
        } else {
            // console.log("nooooo expiredddd");
            filteredStatus = filteredPlatform.filter(i => checkStatus(i) !== "Expired").filter(i => selectedStatus.length !== 0 ? selectedStatus.includes(checkStatus(i)) : i);
            // console.log(filteredStatus)
        }

        if (HL !== "") {
            if (HL === "HighToLow") {
                filteredSort = filteredStatus.sort((a, b) => b.price - a.price);
            }
            else if (HL === "LowToHigh") {
                filteredSort = filteredStatus.sort((a, b) => a.price - b.price);
            }
            else if (HL === "NewToOld") {
                filteredSort = filteredStatus.sort((a, b) => b.createdAt - a.createdAt);
            }
            else if (HL === "OldToNew") {
                filteredSort = filteredStatus.sort((a, b) => a.createdAt - b.createdAt);
            }
        }
        else {
            filteredSort = filteredStatus
        }
    } else {
        let filterSold
        if (sold){
            filterSold = listings
        }else{
            filterSold = listings.filter((it) => it.isSold === sold)
        }console.log(filterSold)
        filteredBrand = filterSold.filter((brand) => {
            return selectedBrand.length !== 0 ? selectedBrand.includes(brand.offer.brand && brand.offer.brand.name) : brand
        })
        // console.log(filteredBrand)

        if (HL === "HighToLow") {
            filteredBrand.sort((a, b) => b.price - a.price);
        }
        else if (HL === "LowToHigh") {
            filteredBrand.sort((a, b) => a.price - b.price);
        }
        else if (HL === "NewToOld") {
            filteredBrand.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
        }
        else if (HL === "OldToNew") {
            filteredBrand.sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix());
        }

        // console.log(filteredBrand)
    }

    return (
        <>
            <Button onClick={() => setToggle(!toggle)} sx={{ border: '2px solid #BC09C7', color: '#BC09C7', borderRadius: '50px', textTransform: 'none', display: { md: 'none', sm: 'flex', xs: 'flex' } }}>
                <FilterAltIcon sx={{ color: '#BC09C7' }} />
                Filter
            </Button>
            <Typography variant='p' sx={{ margin: '0px', color: '#292D32', fontFamily: 'Poppins', fontWeight: 'bolder', padding: '0px', fontSize: { sm: '15px', xs: '8px' } }}>All {items ? 'Items' : 'Offers'}</Typography>
            {items ? <Grid item container spacing={3}>

                {filteredBrand && filteredBrand.length !== 0 ? filteredBrand.map((offer, index) => {
                    return <React.Fragment key={index}>
                        <Grid item md={3} xs={12}>
                            <OfferCard offerDets={offer} communities={allCommunities} />
                        </Grid>
                    </React.Fragment>
                }) : <NoLiveOffers text={'No Listed offers to show'} />}
            </Grid>
                : filteredStatus && filteredStatus.length !== 0 ? <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} spacing={5}>
                    <Grid item container columnSpacing={5}>
                        {
                            filteredStatus.map((offer, index) => {
                                return <Grid item md={4} sm={toggle ? 12 : 6} xs={12} key={index} sx={{ marginBottom: '5%' }}>
                                    <OfferCard offer={offer} communities={allCommunities} />
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid> : <NoLiveOffers text={"No Live offers to show"} />}
        </>
    )
}

export default FilterOffer