import WalletConnectProvider from '@walletconnect/web3-provider';
import React from 'react'
import Web3 from 'web3';
import { Button } from '@mui/material';

function WalletConnect() {
    let account;

    let provider = new WalletConnectProvider({
        rpc: {
            5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
        },
    });

    let connectWC = async () => {
        await provider.enable();

        const web3 = new Web3(provider);
        window.w3 = web3

        let accounts = await web3.eth.getAccounts();
        account = accounts[0];
    }


    let sign = async (msg) => {
        if (window.w3) {
            return await window.w3.eth.personal.sign(msg, account)
        } else {
            return false
        }
    }

    let contract = async (abi, address) => {
        if (window.w3) {
            return new window.w3.eth.Contract(abi, address)
        } else {
            return false
        }
    }

    let disconnect = async () => {
        await provider.disconnect()
    }


    let address = "0x020E30A80d2569DEC7196B1569Bff49838a3c261"
    return (
        <div>
            <Button onClick={connectWC} sx={{ backgroundColor: '#292D32', color: 'white', textTransform: 'none', '&:hover': { color: 'black' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>Wallet Connect</Button>
        </div>
    )
}

export default WalletConnect