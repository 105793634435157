import { CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import popper from '../../images/popper.png'
function Three() {
    return (
        <>
            <Grid container >
                <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh', width: '100%' }}>
                    <CardMedia component='img' image={popper} sx={{ height: '15vh', width: 'auto' }} />
                    <Typography variant='h5' sx={{ fontWeight: '800', fontFamily: 'Poppins', color: '#292D32' }} > Congratulations </Typography>
                    <Typography variant='p' sx={{ margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '12px', sm: '10px', xs: '6px' }, padding: '0px' }}>Creator will contact you soon</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default Three