import moment from "moment/moment";
import Web3 from "web3";

export function compare(s1, s2) {
  return s1 === s2;
}

export const getArrayOfResponses = async arr => {
  let requests = arr.map(url => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(r => r.json())
        .then(r => resolve(r))
        .catch(e => reject(e));
    });
  });
  return await Promise.all(requests);
};

export const getCommFromContract = (comms, addr) => {
  for (let i in comms) {
    if (comms[i].contractAddress === addr) return comms[i];
  }
};
export const getCommNameFromContract = (comms, addr) => {
  for (let i in comms) {
    if (comms[i].contractAddress === addr) return comms[i].name;
  }
};

export const getCommId = (comms, name) => {
  for (let i in comms) {
    if (comms[i].name === name) return comms[i].contractAddress;
  }
};

const convertToCSV = (objArray) => {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Arr => comma separated string
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ','
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}

const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    // Inserts at beginnning
    items.unshift(headers);
  }
  // Convert Object to JSON
  let jsonObject = JSON.stringify(items);

  let csv = convertToCSV(jsonObject);

  let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    // Saves file/blob to disk
    navigator.msSaveBlob(blob, exportedFilenmae);
  }
  else {
    let link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

const fileTitle = 'Redeemers list';

const headers = {
  tx_walletAddress: 'WalletAddress',
  tx_Name: 'Name',
  tx_Email: 'Email',
  tx_PhoneNo: 'Phone Number',
  tx_Address: 'Address',
};

export const downloadCSV = (arr) => {
  let itemsFormatted = [];

  // format the data
  arr.forEach((item) => {
    let address = item.redeemAddress.replaceAll(',', ';').replaceAll('\n', ';')
    itemsFormatted.push({
      tx_walletAddress: item.initiatedBy.walletAddress,
      tx_Name: item.redeemName,
      tx_Email: item.redeemEmail,
      tx_PhoneNo: item.redeemPhoneNo,
      tx_Address: address,

    });
  });

  exportCSVFile(headers, itemsFormatted, fileTitle);
};

const convertToCSV2 = (objArray) => {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Arr => comma separated string
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ','
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}

const exportCSVFile2 = (headers2, items, fileTitle2) => {
  if (headers2) {
    // Inserts at beginnning
    items.unshift(headers2);
  }
  // Convert Object to JSON
  let jsonObject = JSON.stringify(items);

  let csv = convertToCSV2(jsonObject);

  let exportedFilenmae = fileTitle2 + '.csv' || 'export.csv';

  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    // Saves file/blob to disk
    navigator.msSaveBlob(blob, exportedFilenmae);
  }
  else {
    let link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

const fileTitle2 = 'Transactions';

const headers2 = {
  tx_hash: 'Txn Hash',
  tx_method: 'Method',
  tx_age: 'Date Time (UTC)',
  tx_value: 'Value',
  tx_fee: '[Txn Fee]',
  from: 'From',
  to: 'To'
};

export const downloadCSV2 = (arr, address) => {
  let itemsFormatted = [];

  // format the data
  arr.forEach((item) => {
    itemsFormatted.push({
      tx_hash: item.hash.replace(/,/g, ''),
      tx_method: item.from === address ? 'Withdraw' : 'Deposit',
      tx_date: moment.unix(Number(item.timeStamp)).format("MM/DD/YYYY"),
      tx_value: Web3.utils.fromWei(item.value),
      tx_fee: Web3.utils.fromWei(item.gasUsed),
      from: item.from,
      to: item.to,

    });
  });

  exportCSVFile2(headers2, itemsFormatted, fileTitle2);
}