import { Button, CardMedia, Grid, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { offerContext } from '../../../offerContext'
import { connectArcana, connectMetaMask } from '../../../services/connectors'
import { InjectedConnector } from '@web3-react/injected-connector'
import DisclaimerModal from './DisclaimerModal'
import gicon from '../../../images/gicon.png'
import Micon from '../../../images/Micon.png'
import { useAuth } from '@arcana/auth-react'
import Web3 from 'web3'

const style = {
    btn: { display: 'flex', padding: { md: '5px', xs: '0px' }, width: '100%', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#f2f2f2', height: '100%', flexDirection: 'column', alignItems: 'center', textTransform: 'none' },
    img: { width: '50px' },
    heading: { fontFamily: 'Poppins', margin: 0, padding: 0, fontWeight: 'bold', color: 'black' },
    ptag: { margin: 0, padding: 0, fontSize: '10px', color: '#4e4e4e' },
    gridItem: { padding: { md: '10px', xs: '5px' }, height: { md: '100%', xs: 'auto' } },
    gridContainer: { margin: '5% 0' },
}

function ModalOneContent({ activeStep, setActiveStep }) {
    const auth = useAuth()
    const { activate } = useWeb3React();
    const { user, setUser, account, setAccount, isArcana, setISArcana, token, setToken, setUserBrand } = useContext(offerContext)
    const [op, setOp] = useState(false)
    const Injected = new InjectedConnector({
        supportedChainIds: [1, 3, 4, 5, 42]
    });
    useEffect(() => {
        if (user !== null && user.walletAddress) {
            if (!(user.email) || user.email === '' || user.email === null || user.email === undefined) {
                setOp(true)
                // setActiveStep(activeStep + 1);
            } else {
                setActiveStep(activeStep + 2);
            }
        }
    }, [user])
    console.log(user, account, auth, "modalonek")



    return (
        <>
            <Grid container sx={style.gridContainer}>
                <Grid item container md={12}>
                    <Grid item md={6} sm={6} xs={12} sx={style.gridItem}>
                        <Button onClick={() => {
                            setISArcana(false)
                            localStorage.setItem("isArcana", false)
                            activate(Injected)
                            window.web3 = new Web3(window.ethereum)
                            connectMetaMask(user, setUser, account, setAccount, token, setToken, setUserBrand)
                        }} sx={style.btn}>
                            <CardMedia sx={style.img} component='img' image={Micon} />
                            <Typography variant='h6' sx={style.heading}>MetaMask</Typography>
                            <p style={style.ptag}>Connect to your MetaMask Wallet</p>
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} sx={style.gridItem}>
                        <Button onClick={async () => {
                            await auth.loginWithSocial('google').then(async () => {
                                const web3 = new Web3(auth.provider);
                                window.web3 = web3
                                console.log(auth)
                                setISArcana(true)
                                connectArcana(user, setUser, account, setAccount, token, setToken, setUserBrand, auth)
                                localStorage.setItem("isArcana", true)
                                // if (auth.isLoggedIn) {
                                // }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }} sx={style.btn}>
                            <CardMedia sx={style.img} component='img' image={gicon} />
                            <Typography variant='h6' sx={style.heading}>Google</Typography>
                            <p style={style.ptag}>Continue using your google account</p>
                        </Button>
                    </Grid>
                    {/* <Grid item md={4} sm={4} xs={12} sx={style.gridItem}>
                        <Button onClick={() => {
                            try {
                                activate(walletlink)
                                fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand)
                            } catch (err) {
                                axiosHandler("Coin Base Extension not found")
                            }
                        }} sx={style.btn}>
                            <CardMedia sx={style.img} component='img' image="https://login.xyz/img/coinbase.svg" />
                            <Typography variant='h6' sx={style.heading}>Coin Base</Typography>
                            <p style={style.ptag}>Connect your Coin Base Wallet</p>
                        </Button>
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} sx={style.gridItem} >
                        {isMobile ?
                            <Button onClick={() => { window.open('https://link.trustwallet.com/open_url?url=http://192.168.0.102:3000') }} sx={style.btn}>
                                <CardMedia sx={style.img} component='img' image="https://trustwallet.com/assets/images/media/assets/trust_platform.svg" />
                                <Typography variant='h6' sx={style.heading}>Trust Wallet</Typography>
                                <p style={style.ptag}>Connect with your Trust wallet account</p>
                            </Button>
                            :
                            <Tooltip title="Only available on mobile">
                                <Button sx={style.btn}>
                                    <CardMedia sx={style.img} component='img' image="https://trustwallet.com/assets/images/media/assets/trust_platform.svg" />
                                    <Typography variant='h6' sx={style.heading}>Trust Wallet</Typography>
                                    <p style={style.ptag}>Connect with your Trust wallet account</p>
                                </Button>
                            </Tooltip>
                        }
                    </Grid> */}
                </Grid>
                {/* <Grid item container md={12}>
                    <Grid item md={12} sm={12} xs={12} sx={{ padding: '10px' }}>
                        <Button onClick={() => connectWalletConnect(user, setUser, account, setAccount)} sx={{ display: 'flex', padding: '5px', width: '100%', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#f2f2f2', flexDirection: 'column', alignItems: 'center', textTransform: 'none' }}>
                            <CardMedia sx={style.img} component='img' image="https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png" />
                            <Typography variant='h6' sx={style.heading}>Wallet Connect</Typography>
                            <p style={style.ptag}>Scan or Connect through Wallet Connect</p>
                        </Button>
                    </Grid>
                </Grid> */}
                {/* <Button onClick={async() => {
                    await auth.logout()
                }} >Logout</Button> */}
            </Grid>
            <DisclaimerModal open={op} setOpen={setOp} activeStep={activeStep} setActiveStep={setActiveStep} />
        </>
    )
}

export default ModalOneContent