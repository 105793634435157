import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useContext, useState } from 'react';
import Transactions from '../../services/Transactions';
import { offerContext } from '../../offerContext';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import axiosHandler from '../../helpers/axiosHandler';
import moment from 'moment';
import Web3 from 'web3';
import { Card, CardContent, CardMedia, Grid, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadCSV, downloadCSV2 } from '../../utils/helper';
import Loading from '../loader/Loading';
import { Box } from '@mui/system';
import NoLiveOffers from '../nothingToSee/NoLiveOffers';

function UserTransactions() {
    const [expanded, setExpanded] = useState(false);
    const { user } = useContext(offerContext)
    const [arrTransactions, setArrTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setLoading(true)
        const func = async () => {
            await Transactions.getTransactions(user.walletAddress)
                .then((res) => {

                    // console.log(res.data, 'tempp');
                    setArrTransactions(res.data && res.data.result)
                    setLoading(false)
                })
                .catch((err) => {
                    axiosHandler(err.message)
                })
        }
        func()
    }, [])
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {loading ? <Loading /> : <Box sx={{ width: { md: '70%' } }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Transactions</h3>
                    <Tooltip title="Expost CSV">
                        <DownloadIcon sx={{ cursor: 'pointer' }} onClick={() => downloadCSV2(arrTransactions, user.walletAddress)} />
                    </Tooltip>
                </div>
                {
                    arrTransactions.length !== 0 ? arrTransactions.map((transaction, index) => {
                        return <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ width: '100%' }} className={transaction.from === user.walletAddress ? 'withdraw' : 'deposit'}>
                            <AccordionSummary
                                sx={{
                                    display: 'flex', justifyContent: 'space-between', width: '100%', '& .MuiAccordionSummary-content': { justifyContent: 'space-between' },
                                    '& .MuiAccordion-root': { backgroundColor: '#FFC48B4c !important', borderBottomLeftradius: '5px', borderLeft: '4px solid #FFC48B !important' }
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {expanded === `panel${index}` ? <CloseIcon /> : <AddIcon />}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h4 style={{ width: '100%', flexShrink: 0 }}>
                                            {transaction.from === user.walletAddress ? 'Withdraw' : 'Deposit'}
                                        </h4>
                                        <p style={{ fontSize: '12px' }}>{moment.unix(Number(transaction.timeStamp)).format("MM/DD/YYYY")}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h3>{Web3.utils.fromWei(transaction.value)}</h3>
                                    <CardMedia component="img" sx={{ width: '20px' }} image="https://upload.wikimedia.org/wikipedia/commons/0/01/Ethereum_logo_translucent.svg" />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingLeft: '40px' }}>
                                <div style={{ display: 'flex' }}>
                                    <h5>Transaction Hash -  </h5>
                                    <p style={{ fontSize: '12px' }}> &ensp;{transaction.hash}</p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Value -  </h5>
                                    <p style={{ fontSize: '12px' }}> &ensp;{Web3.utils.fromWei(transaction.value)}</p>
                                    <CardMedia component="img" sx={{ width: '12px' }} image="https://upload.wikimedia.org/wikipedia/commons/0/01/Ethereum_logo_translucent.svg" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Transaction Fee -  </h5>
                                    <p style={{ fontSize: '12px' }}> &ensp;{Web3.utils.fromWei(transaction.gas)}</p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>From -  </h5>
                                    <p style={{ fontSize: '12px' }}> &ensp;{transaction.from}</p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>To -  </h5>
                                    <p style={{ fontSize: '12px' }}> &ensp;{transaction.to}</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    }) : <NoLiveOffers text={"No transaction yet"} />
                }
            </Box>}
        </Box>
    )
}

export default UserTransactions