import { Avatar, Badge, Box, Button, Card, CardContent, CardMedia, Chip, Divider, Grid, Skeleton, TableHead, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import clipboardCopy from 'clipboard-copy';
import { offerContext } from '../../offerContext';
import ShareIcon from '@mui/icons-material/Share';
import Countdown from 'react-countdown';
import { compare, downloadCSV, getCommFromContract } from '../../utils/helper';
import SortIcon from '@mui/icons-material/Sort';
import Web3 from 'web3';
import moment from 'moment';
import axiosHandler from '../../helpers/axiosHandler';
import OfferServices, { getNFTOwnedCount, getUserNFTsOfContracts, withdrawETH } from '../../services/OfferServices';
import Loading from '../loader/Loading';
import './OfferDetails.css'
import LaunchIcon from '@mui/icons-material/Launch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Offer from '../../artifacts/Offer.json';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { loadAllBrands, loadAllCommunities, loadAllOffers, loadBlockchainData } from '../../LoadData';
import CircularProgress from '@mui/material/CircularProgress';
import CommunityServices from '../../services/CommunityServices';
import SimilarOfferCard from './SimilarOfferCard';
import { isObject } from 'url';
import HorizontalScroll from 'react-horizontal-scrolling';
import { Table, Paper, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DownloadIcon from '@mui/icons-material/Download';
import DiscordModal from './DiscordModal';
import ResellModal from './ResellPopup';
import { buyListing, getItemFromDb, getMyListings, loadAllListings } from '../../services/MarketPlace';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import successHandler from '../../helpers/successHandler';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import weth from '../../images/weth.png'
import { Helmet } from 'react-helmet-async';
import { useAuth } from '@arcana/auth-react';


const style = {
    pTag: { color: '#424242', fontSize: '12px' },
    div: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    margin: { marginTop: '3%' },
    timer: { backgroundColor: '#D9D9D9', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50px', margin: 0 },
    marginLeft: { marginLeft: '50px' },
    icon: { color: '#424242', fontSize: '20px' },
    desc: { color: '#636363', fontSize: '11px' },
    btn: { textTransform: 'none', width: '100%', backgroundColor: '#BC09C7', border: '2px solid #BC09C7', color: 'white', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' } },
    disabled: { backgroundColor: 'gray', color: 'white !important', border: '2px solid gray' },
    center: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' },
    withdrawBalance: { backgroundColor: 'white', border: '2px solid #BC09C7', color: '#BC09C7', height: '100%', "& :hover": { backgroundColor: 'white', border: '2px solid #BC09C7', color: '#BC09C7' } },
    pTagError: { color: 'red', fontSize: '8px' },
    btnCheck: {
        color: '#BC09C7',
        width: '100%',
        height: '100%'
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#BC09C7',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function OfferDetails({ buyOfferFromApp, offerSummary }) {
    const params = useParams();
    const { inAppRed, setInAppRed, bought, setBought,
        listings, setListings, offerFactory, account, allOffers,
        allCommunities, summary, NFTs, user, setUser, setOfferFactory,
        setAllBrands, setAllAds, setAllCommunities, setAllOffers,
        token, setUserBrand, setUserCreatedOffers, setFav, setForwarderC,
        payingTokenAddress, setPayingTokenAddress, pContract, isEth,
        setPcontract, isDev, setIsDev, selectedNetwork } = useContext(offerContext)
    const [isCross, setIsCross] = useState(false);
    const [True, setTrue] = useState(true);
    const [offers, setOffers] = useState(allOffers)
    const id = params.id
    const [offer, setOffer] = useState(null)
    const [eligible2, setEligible2] = useState(null)
    const [available, setAvailable] = useState();
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false)
    const [withdrawableAmount, setWithdrawableAmount] = useState(undefined);
    const [enteredAmount, setEnteredAmount] = useState('0')
    const [owner, setOwner] = useState(user && offer && user.created.includes(offer._id))
    const [check, setCheck] = useState(false)
    const [save, setSave] = useState(user && offer && user.favorites.filter(c => c === offer._id).length !== 0)
    const [load, setLoad] = useState(false)
    const [similarOffers, setSimilarOffers] = useState([]);
    const [loadImg, setLoadImg] = useState(false)
    const [tick, setTick] = useState(false)
    const navigate = useNavigate()
    const [redeemed, setRedeemed] = useState(null)
    const [redemption, setRedemption] = useState(null)
    const [tableData, setTableData] = useState([])
    const [now, setNow] = useState(moment().unix());
    const [display, setDisplay] = useState(0);
    const [claimed, setClaimed] = useState(summary.length !== 0 && summary[2]);
    const [open, setOpen] = useState(false)
    const [dc, setDc] = useState(false)
    const [open2, setOpen2] = React.useState(false);
    const handleOpen = () => setOpen2(true);
    const handleClose = () => setOpen2(false);
    const [nftId, setNftId] = useState(null)
    const offerDets = window.location.href.includes('listed')
    const [listedOffer, setListedOffer] = useState(null)
    const [show, setShow] = useState('')
    const [buyed, setBuyed] = useState(false)
    const [resold, setResold] = useState(false)
    const [numOwn, setNumOwn] = useState(0)
    const [tokenBalance, setTokenBalance] = useState(undefined)
    const [showMore, setShowMore] = useState(false)
    const [showTimer, setShowTimer] = useState(true)
    const auth = useAuth()

    console.log(eligible2, fetching, 'yayk')
    useEffect(() => {
        setIsDev(window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/') ? false : true)
        setPayingTokenAddress(window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/')
            ? '0xD087ff96281dcf722AEa82aCA57E8545EA9e6C96'
            : '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619')
    }, [])


    useEffect(() => {

        const func = async () => {

            setFetching(true);
            !offerDets ? await OfferServices.getOfferById(id)
                .then(async (res) => {
                    setTrue(false)
                    setOffer(() => res.data)
                    setShowTimer(user !== null && user.claimed.find(c => compare(c, res.data._id)))
                    // setIsClaim()
                    if (user !== null && user.claimed.find(c => compare(c, res.data._id))) {
                        setEligible2(false);
                    }
                    else {
                        let targetNfts = await getUserNFTsOfContracts(
                            user?.walletAddress,
                            res.data.targetCommunities.map(i => i.contractAddress),
                            isDev,
                            selectedNetwork
                        );
                        console.log(targetNfts, 'yayk')
                        let echeck = res.data.targetCommunities.length !== 0 ? targetNfts?.find(i =>
                            res.data?.targetCommunities
                                .map(j => j.contractAddress.toLowerCase())
                                .includes(i.contract.address.toLowerCase())
                        ) : true
                        // console.log(echeck, 'yay')
                        setEligible2(echeck);
                    }

                    setOwner(() => user && (user.walletAddress.toLowerCase() === res.data.createdBy.walletAddress.toLowerCase() ? true : false))
                    setDisplay(() => (now < res.data.startsAt ? res.data.startsAt : res.data.endsAt))

                    offerSummary(res.data.contractAddress)


                    const requests = async () => {
                        await OfferServices.requests(res.data._id, token)
                            .then((res) => {
                                setTableData(res.data)
                                // console.log(res.data, 'req')
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                    const getWithdrawableBalance = async () => {
                        const web3 = window.web3
                        const payingTokenAddress = isDev
                            ? '0xD087ff96281dcf722AEa82aCA57E8545EA9e6C96'
                            : '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619';
                        let abi = [
                            {
                                inputs: [
                                    {
                                        internalType: 'address',
                                        name: '_spender',
                                        type: 'address',
                                    },
                                    {
                                        internalType: 'uint256',
                                        name: '_value',
                                        type: 'uint256',
                                    },
                                ],
                                name: 'approve',
                                outputs: [
                                    {
                                        internalType: 'bool',
                                        name: 'success',
                                        type: 'bool',
                                    },
                                ],
                                stateMutability: 'nonpayable',
                                type: 'function',
                            },
                            {
                                constant: true,

                                inputs: [{ name: '_owner', type: 'address' }],

                                name: 'balanceOf',

                                outputs: [{ name: 'balance', type: 'uint256' }],

                                type: 'function',
                            }
                        ];
                        const contractP = new web3.eth.Contract(abi, payingTokenAddress);

                        // setWithdrawableAmount(web3.utils.fromWei(await web3.eth.getBalance(res.data.contractAddress)));
                        setWithdrawableAmount(
                            Web3.utils.fromWei(await web3.eth.getBalance(res.data?.contractAddress)),
                        );
                        let tokenb = await contractP?.methods.balanceOf(res.data?.contractAddress).call()
                        console.log(tokenb, 'kkyaakk')
                        tokenb && setTokenBalance(
                            Web3.utils.fromWei(tokenb),
                        );
                        requests()
                    };
                    user && (user.walletAddress.toLowerCase() === res.data.createdBy.walletAddress.toLowerCase()) && getWithdrawableBalance()
                    // user && (user.walletAddress.toLowerCase() === res.data.createdBy.walletAddress.toLowerCase()) && 
                    const getMylist = async () => {
                        await getMyListings(token)
                            .then((resList) => {
                                console.log(resList, 'yaya')
                                const resellStatus = resList.data.find((off) => off.offer._id === res.data._id)
                                setResold(resellStatus)
                                console.log(resellStatus)

                                // setFetching(false);
                            })
                        setFetching(false)
                    }
                    const updateCount = async () => {
                        let summary = await OfferServices.getLiveData(res.data.contractAddress);
                        try {
                            // console.log(summary, 'summ')
                            setAvailable(Number(summary[2].hex));
                            setNftId(Number(summary[1].hex));
                            if (user) {
                                console.log('hey')
                                const web3 = window.web3;
                                const contractAddress = res.data?.contractAddress;
                                const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
                                user && await getNFTOwnedCount(offercontract, user?.walletAddress, Number(summary[1].hex), setNumOwn)
                            }
                            user ? getMylist() : setFetching(false)
                        } catch (error) {
                            console.log(error)
                        }
                        // setFetching(false);
                    };
                    setIsCross(res.data.targetCommunities.length > 1);
                    setSave(user !== null && user.favorites.filter(c => c === res.data._id).length !== 0)
                    now < res.data.startsAt ? setDisplay(res.data.startsAt) : setDisplay(res.data.endsAt)
                    // console.log(sim, 'sim')
                    setTrue(true)
                    setRedemption(res.data.inAppRedemption)
                    setRedeemed(user && user.redeemed.includes(res.data._id) ? true : false)
                    setInAppRed(res.data.inAppRedemption)
                    setDc(res.data.discordServer && res.data.discordServer !== '' ? true : false)
                    updateCount()
                    // let temp = [];
                    // let sim = []
                    // allCommunities.length !== 0 && res.data.targetCommunities.length !== 0 && res.data.targetCommunities.forEach(async j => {

                    //     await CommunityServices.getOfferByAdd(j._id)
                    //         .then(async (res2) => {
                    //             // console.log(res2)
                    //             res2.data.map(element => {
                    //                 if (element.endsAt > moment().unix()) {
                    //                     if (res.data._id !== element._id) {
                    //                         sim.push(element)
                    //                     }
                    //                 }
                    //             });

                    //         })
                    // }
                    // )

                    // // console.log(temp, sim, 'oddddddddd')
                    // setSimilarOffers(sim)


                })
                :
                await getItemFromDb(id)
                    .then(async (res) => {
                        setFetching(true)
                        let summary = await OfferServices.getLiveData(res.data.offer.contractAddress);
                        // console.log(Number(summary))
                        setAvailable(Number(summary[2].hex));
                        setNftId(Number(summary[1].hex));
                        const web3 = window.web3;
                        const contractAddress = res.data?.offer.contractAddress;
                        const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
                        await getNFTOwnedCount(offercontract, user?.walletAddress, Number(summary[1].hex), setNumOwn)
                        // console.log(res.data)
                        setEligible2(true)
                        setOffer(res.data.offer)
                        setRedemption(res.data.offer.inAppRedemption)
                        setDc(res.data.offer.discordServer && res.data.offer.discordServer !== '' ? true : false)
                        setListedOffer(res.data)
                        setBuyed(res.data.owner.walletAddress === user?.walletAddress ? true : false)
                        setOwner(res.data.seller.walletAddress === user?.walletAddress ? true : false)
                        setSave(user !== null && user.favoriteItems.filter(c => c === res.data._id).length !== 0)
                        setRedeemed(user && user.redeemed.includes(res.data.offer._id) ? true : false)
                        setFetching(false);
                    })
        }
        func()
    }, [id, user])


    useEffect(() => {

        setClaimed(offer !== null && offer !== undefined && offer.claimedBy ? offer.claimedBy.length : 0)

        const func = async () => {
            await loadBlockchainData(setOfferFactory, setForwarderC, setPcontract, isDev)
            await loadAllOffers(setAllOffers, selectedNetwork);
            await loadAllListings(setListings)
            if (localStorage.getItem('isArcana')) {
                window.web3 = new Web3(auth.provider)
            }
        }

        func();
        setOffers(allOffers)

        const func2 = async () => {
            !offerDets ? await OfferServices.getOfferById(id)
                .then(async (res) => {
                    setOffer(res.data)
                }) : await getItemFromDb(id)
                    .then(async (res) => { setOffer(res.data.offer) })
        }
        func2()
    }, [])


    const buy = async (id, price, address, load, setLoad) => {
        if (Object.keys(user).length !== 0) {
            setLoading(true)
            try {
                const res = offer.targetCommunities.length !== 0 ?
                    await buyOfferFromApp(id, price, address, eligible2.id.tokenId, eligible2.contract.address, load, setLoading, user, offer, setUser, offer.contractAddress) :
                    await buyOfferFromApp(id, price, address, '0', '0x0000000000000000000000000000000000000000', load, setLoading, user, offer, setUser, offer.contractAddress)
                // updateCount()
                // console.log(offer.contractAddress, 'resss2')
                let summary = await OfferServices.getLiveData(offer.contractAddress);
                // console.log(Number(summary))
                setAvailable(Number(summary[2].hex));
                const web3 = window.web3;
                const contractAddress = listedOffer?.offer.contractAddress;
                const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
                // await getNFTOwnedCount(offercontract, user?.walletAddress, nftId, setNumOwn)
                setNumOwn(numOwn + 1)
                setEligible2(() => false);

                // setEligible2(false);
                setUser(JSON.parse(localStorage.getItem("deOffersUser")))
                // setOwner(user && (user.walletAddress.toLowerCase() === offer.createdBy.walletAddress.toLowerCase() ? true : false))
                setDisplay(() => (now < offer.startsAt ? offer.startsAt : offer.endsAt))
                // console.log(display, 'resss')

                offerSummary(offer.contractAddress)
                // if (user !== null && user.claimed.find(c => compare(c, offer._id))) {
                // }
                // console.log(res.data, 'ress')
            } catch (err) {
                console.log(err, 'errrrrr')

                axiosHandler(err.message)
            }
            setLoading(false)
        }
        else {
            axiosHandler("Login to claim the offer")
        }
    }
    const withdrawAmount = async () => {
        setCheck(false)
        setTick(true)
        const web3 = window.web3
        const offercontract = new web3.eth.Contract(Offer.abi, offer.contractAddress);

        const getWithdrawableBalance = async () => {
            const web3 = window.web3

            // setWithdrawableAmount(web3.utils.fromWei(await web3.eth.getBalance(res.data.contractAddress)));
            setWithdrawableAmount(
                Web3.utils.fromWei(await web3.eth.getBalance(offer?.contractAddress)),
            );
            setTokenBalance(
                Web3.utils.fromWei(
                    await pContract.methods.balanceOf(offer?.contractAddress).call(),
                ),
            );
        };
        await withdrawETH(
            offercontract,
            `${withdrawableAmount}WETH + ${tokenBalance}MATIC`,
            user.walletAddress,
            setCheck
        )
            .then(async (res) => {
                // console.log(res)
                if (res) {
                    setWithdrawableAmount(null);
                    await getWithdrawableBalance();
                    setTick(false)
                    setEnteredAmount('0')

                    setCheck(true)
                } else {
                    setCheck(false)
                    setTick(false)
                    axiosHandler('Transfer Rejected')
                }
            }).catch((err) => {
                console.error(err)
                setTick(false)
            })
    };


    const buylisted = async () => {
        // console.log('hey')
        setLoading(true)
        // let provider = window.ethereum;
        const web3 = window.web3;
        let res = await buyListing(offerFactory,
            account,
            listedOffer._id,
            listedOffer.tokenId,
            web3.utils.toWei(listedOffer.price.toString()),
            token,
            setBought,
            payingTokenAddress,
            pContract,
            listedOffer.isEth
        )
        if (res) {
            console.log(res)
            successHandler('Item bought successfully 🎉')
            res.data?.offer && setListedOffer(res.data)
            res.data?.owner && setBuyed(res.data.owner.walletAddress === user?.walletAddress ? true : false)
            res.data?.offer && setListedOffer(res.data)
            // const web3 = window.web3;
            const contractAddress = listedOffer?.offer.contractAddress;
            const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
            // await getNFTOwnedCount(offercontract, user?.walletAddress, nftId, setNumOwn)
            setNumOwn(numOwn + 1)
        }

        setLoading(false)
    }

    function renderLinks(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text.split(linkRegex);
        return parts.map((part, index) =>
            linkRegex.test(part) ? (
                <Link key={index} to={part} style={{ color: 'blue' }}>
                    {part}
                </Link>
            ) : (
                part
            )
        );
    }

    console.log(tableData, 'tab')

    return (
        <>
            {
                offer && !fetching ? <>
                    <Grid container sx={{
                        display: 'flex', justifyContent: 'center', width: '99%', overflow: 'scroll', "&::-webkit-scrollbar": { display: 'none' },
                        marginLeft: '0.2%',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `linear-gradient(0deg, rgba(245,246,248,0) 0%, rgba(245,246,248,0) 35%, rgba(188,9,199,0.1) 100%), linear-gradient(0deg, rgba(245,246,248,2) 0%, rgba(245,246,248,2) 35%, rgba(245,246,248,0.6) 100%)`,
                        backgroundBlendMode: 'screen',
                        borderRadius: '10px',
                        marginTop: '0.1%',
                        backgroundSize: '100% 100%'

                    }} spacing={3}>
                        <Grid item md={10}>

                            <Grid container spacing={6} >

                                <Grid item md={5} xs={12}>
                                    <Card sx={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', borderRadius: '10px' }}>
                                        <CardContent>
                                            <div style={{ ...style.div, marginBottom: '4%' }}>
                                                <div>
                                                    <h3>{offer.offerName}</h3>
                                                    <p style={{ ...style.pTag }} >From {offer.brand.name}</p>
                                                </div>
                                                <div style={{ display: 'flex', columnGap: 20 }}>
                                                    <Tooltip title="View on Polygon Scan">
                                                        <LaunchIcon sx={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '1.2em' }} onClick={() => window.open(`https://polygonscan.com/address/${offer.contractAddress}`)} />
                                                    </Tooltip>
                                                    {
                                                        save ? <Tooltip title="Remove From Favourites" onClick={() => {
                                                            OfferServices.removeFromFav(user, offerDets ? listedOffer._id : offer._id, setUser, setSave, token, setUserBrand, setUserCreatedOffers, setClaimed, setFav, offerDets)
                                                        }}>
                                                            <FavoriteIcon sx={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '1.2em' }} />
                                                        </Tooltip> : <Tooltip title="Add to favourites" onClick={() => {
                                                            OfferServices.addToFav(user, offerDets ? listedOffer._id : offer._id, setUser, setSave, token, setUserBrand, setUserCreatedOffers, setClaimed, setFav, offerDets)
                                                        }}>
                                                            <FavoriteBorderIcon sx={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '1.2em' }} />
                                                        </Tooltip>
                                                    }
                                                    <Tooltip title="Share">
                                                        <ShareIcon onClick={() => {
                                                            let linkToCopy = window.location.href
                                                            clipboardCopy(linkToCopy)
                                                                .then(() => {
                                                                    successHandler('Link copied to clipboard')
                                                                })
                                                                .catch(err => {
                                                                    axiosHandler('Failed to copy the link')
                                                                });
                                                        }} sx={{ fontWeight: 'bold', cursor: 'pointer', width: '20px', fontSize: '1.2em' }} />
                                                    </Tooltip>
                                                </div>

                                            </div>


                                            <CardMedia component='img' image={offer.bannerUri} style={loadImg ? { borderRadius: '5px' } : { display: 'none' }} onLoad={() => setLoadImg(true)} />
                                            {
                                                !loadImg && <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
                                            }

                                            {!offerDets && offer?.targetCommunities.length !== 0 &&
                                                <>
                                                    <div>
                                                        <p style={{ ...style.pTag, ...style.margin }} >Target Communities</p>
                                                        <Grid md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            {offer?.targetCommunities.map((community, index) => (
                                                                community !== undefined && community.img ? <Avatar onClick={() => navigate(`/communitydetails/${community._id}`, { state: { id: community._id } })} key={index} sx={{ border: '2px solid black' }} src={community.img} /> :
                                                                    <Avatar onClick={() => navigate(`/communitydetails/${community._id}`, { state: { id: community._id } })} key={index} sx={{ border: '3px solid #BC09C7', color: '#BC09C7', backgroundColor: 'white' }}>{community.name.slice(0, 1)}</Avatar>
                                                            ))}
                                                        </Grid>
                                                    </div>
                                                </>
                                            }
                                        </CardContent>
                                    </Card>

                                    <Grid container style={{ display: 'flex', columnGap: 20, ...style.margin }}>
                                        {
                                            !offerDets && owner && <Grid container columnSpacing={3} sx={{ display: 'flex' }}>
                                                <Grid md={6} item sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <p style={{ ...style.pTag, ...style.margin }}>Collectible balance</p>
                                                    {/* <Button sx={{ ...style.withdrawBalance, color: 'black' }} disabled >{withdrawableAmount}</Button> */}
                                                    <div style={{ marginTop: '3%', display: 'flex', justifyContent: 'space-between' }}>
                                                        <h3 style={{ display: 'flex', alignItems: 'center' }}>
                                                            {/* {withdrawableAmount}  */}
                                                            <>
                                                                <CardMedia sx={{ width: '20px', height: '20px', objectFit: 'contain' }} component='img' image={weth} />
                                                                {tokenBalance ? tokenBalance : '0'}
                                                            </>
                                                            {
                                                                withdrawableAmount > 0 ? <> +
                                                                    <CardMedia sx={{ width: '20px', height: '20px', objectFit: 'contain' }} component='img' image='https://cryptologos.cc/logos/polygon-matic-logo.png' />
                                                                    {withdrawableAmount}
                                                                </> : ''
                                                            }
                                                        </h3>
                                                        {/* <Button sx={{ '& :hover': { bgcolor: 'none' }, color: '#BC09C7' }} onClick={() => setEnteredAmount(withdrawableAmount)} >
                                                                Max
                                                                <ArrowForwardIcon sx={{ width: '15px' }} />
                                                            </Button> */}
                                                    </div>
                                                </Grid>
                                                <Grid item md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    {/* <p style={{ ...style.pTag, ...style.margin }}>Enter Amount</p>
                                                        <TextField onChange={(e) => e.target.value >= 0 ? setEnteredAmount(e.target.value) : axiosHandler("Enter valid amount")} sx={{ height: '100% !important' }} value={enteredAmount} type="number" /> */}
                                                </Grid>
                                                <Grid item md={1} sm={1} xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Button sx={{ ...style.btnCheck, marginTop: '1.5rem' }} onClick={() => withdrawAmount()}>
                                                        {
                                                            tick ? <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} /> : <AccountBalanceIcon />
                                                        }
                                                    </Button>
                                                </Grid>
                                                {withdrawableAmount && withdrawableAmount < enteredAmount && <p style={{ ...style.pTagError, marginLeft: '5%' }}>Enter valid amount to withdraw</p>}
                                            </Grid>
                                        }
                                    </Grid>

                                    {user ? offerDets ? <div style={{ ...style.margin }}>
                                        {
                                            listedOffer?.owner?.walletAddress === user.walletAddress ?
                                                listedOffer?.seller?.walletAddress === user.walletAddress ?
                                                    listedOffer.isSold ?
                                                        <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Sold out</Button>
                                                        : <Button onClick={() => {
                                                            setShow('delete')
                                                            setOpen2(true)
                                                        }} sx={{ ...style.btn, ...style.margin }}>Delist</Button>
                                                    : redeemed ?
                                                        numOwn ?
                                                            <Grid container columnSpacing={3}>
                                                                <Grid item md={6}>
                                                                    <Button onClick={() => {
                                                                        setShow('create')
                                                                        setOpen2(true)
                                                                    }} sx={{ backgroundColor: 'white', color: '#292D32', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>List</Button>
                                                                </Grid>
                                                                <Grid item md={6}>
                                                                    <Badge badgeContent={<Tooltip title="To enable multiple redeems please transfer this NFT offer to another wallet to redeem"><InfoIcon /></Tooltip>} sx={{ width: '100%' }}>
                                                                        <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Redeemed</Button>
                                                                    </Badge>
                                                                </Grid>
                                                            </Grid>
                                                            : <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Redeemed</Button>
                                                        : <Grid container columnSpacing={3}>
                                                            <Grid item md={6}>
                                                                <Button onClick={() => {
                                                                    setShow('create')
                                                                    setOpen2(true)
                                                                }} sx={{ backgroundColor: 'white', color: '#292D32', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>List</Button>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                {
                                                                    redemption ? <Button onClick={() => navigate('/redeemoffer', { state: { offer: offer } })} sx={{ backgroundColor: '#292D32', color: 'white', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>Redeem</Button>
                                                                        : dc ? <Button sx={{ ...style.margin, backgroundColor: '#7289DA', width: '100%', textTransform: 'none', color: 'white', '&:hover': { backgroundColor: '#7289DAdc', border: '2px solid #7289DA' }, border: '2px solid #7289DA' }} onClick={() => window.open(offer.discordServer, "_blank")} >Join Discord Server</Button>
                                                                            : <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', border: '2px solid #E2E2E2', width: '100%', textTransform: 'none' }} disabled>Not redeemable</Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                : listedOffer.isSold ? <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Sold out</Button>
                                                    : !loading ? <Button sx={{ ...style.btn, ...style.margin }} onClick={buylisted}  >Buy listed offer</Button>
                                                        : <div style={{ display: 'flex', justifyContent: 'center' }}> <CircularProgress sx={{ ...style.btn, borderRadius: '50%', padding: '5px' }} /> </div>
                                        }
                                    </div>
                                        : <div style={{ ...style.margin }}>
                                            {
                                                owner ? !inAppRed && !dc && <>
                                                    <Grid container columnSpacing={2}>
                                                        <Grid item md={6}>
                                                            <Button sx={{ ...style.btn, ...style.margin }} onClick={() => navigate('/createredeempage', { state: { id: offer._id } })} >Create Redeem Page</Button>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <Button onClick={() => setOpen(true)} sx={{ ...style.margin, backgroundColor: '#7289DA', width: '100%', textTransform: 'none', color: 'white', '&:hover': { backgroundColor: '#7289DAdc', border: '2px solid #7289DA' }, border: '2px solid #7289DA' }}>Discord
                                                                <CardMedia component='img' image='https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png' sx={{ width: 'auto', height: '10px', marginLeft: '2px', '&:hover': { color: '#7289DA' } }} />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </> :
                                                    showTimer ? resold ? <Button disabled sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }}>Offer already listed</Button>
                                                        : redeemed ? numOwn ? <Grid container columnSpacing={3}>
                                                            <Grid item md={6}>
                                                                <Button onClick={() => {
                                                                    setShow('create')
                                                                    setOpen2(true)
                                                                }} sx={{ backgroundColor: 'white', color: '#292D32', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>List</Button>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Badge badgeContent={<Tooltip title="To enable multiple redeems please transfer this NFT offer to another wallet to redeem"><InfoIcon /></Tooltip>} sx={{ width: '100%' }}>
                                                                    <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Redeemed</Button>
                                                                </Badge>
                                                            </Grid>
                                                        </Grid>
                                                            : <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Redeemed</Button>
                                                            : <Grid container columnSpacing={3} sx={{ marginTop: '2%' }}>
                                                                <Grid item md={6}>
                                                                    <Button onClick={() => {
                                                                        setShow('create')
                                                                        setOpen2(true)
                                                                    }} sx={{ backgroundColor: 'white', color: '#292D32', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>List</Button>
                                                                </Grid> <Grid item md={6}>
                                                                    {redemption ? <Button onClick={() => navigate('/redeemoffer', { state: { offer: offer } })} sx={{ backgroundColor: '#292D32', color: 'white', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: 'transparent', color: '#292D32' } }}>Redeem</Button>
                                                                        : dc ? <Button sx={{ backgroundColor: '#7289DA', width: '100%', textTransform: 'none', color: 'white', border: '2px solid #7289DA', '&:hover': { backgroundColor: '#7289DAdc' } }} onClick={() => window.open(offer.discordServer, "_blank")} >Join Discord Server</Button>
                                                                            : <Button sx={{ color: 'white !important', textTransform: 'none', fontWeight: 'bold', backgroundColor: 'gray !important', width: '100%', border: '2px solid gray' }} disabled>Not redeemable</Button>}
                                                                </Grid>
                                                            </Grid>
                                                        : eligible2 ? !loading ? (now > offer.endsAt ? <Button disabled sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important' }} >Expired</Button>
                                                            : (offer.startsAt > now ? <Button disabled sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important' }}>Upcoming</Button>
                                                                : (offer.count - available) === (offer.count) ? <Button disabled sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important' }} >No offer left</Button>
                                                                    : <Button sx={{ ...style.btn, ...style.margin }} onClick={() => buy(offer.offerId, offer.price, offer.contractAddress, load, setLoad)}  >Claim</Button>))
                                                            : <div style={{ display: 'flex', justifyContent: 'center' }}> <CircularProgress sx={{ ...style.btn, borderRadius: '50%', padding: '5px' }} /> </div>
                                                            : <Button sx={{ ...style.btn, ...style.margin, ...style.disabled }} disabled={true} >You are not eligible to claim this offer</Button>
                                            }
                                        </div>
                                        : offerDets ? <Button sx={{ ...style.btn, ...style.margin, ...style.disabled }} disabled={true} >Login to buy the item</Button> : <Button sx={{ ...style.btn, ...style.margin, ...style.disabled }} disabled={true} >Login to redeem the offer</Button>
                                    }
                                </Grid>
                                <Grid item md={7} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div>

                                        {
                                            offer.description && <>
                                                <Card sx={{ borderLeft: '4px solid #BC09C7', borderRadius: '10px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                    <CardContent>
                                                        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Grid item sx={{ display: 'flex' }}>
                                                                <SortIcon style={{ ...style.icon, marginRight: '5%' }} />
                                                                <p style={{ ...style.pTag, fontWeight: 'bold' }} >Description</p>
                                                            </Grid>
                                                            <Grid item>
                                                                {
                                                                    owner && <>
                                                                        <Chip label={offerDets ? "Seller" : "Owner"} size="small" color='secondary' />
                                                                    </>
                                                                }
                                                                {
                                                                    offerDets ? <>
                                                                        <Chip label={"Listed Offer - open for all"} size="small" sx={{ backgroundColor: '#3ED1CF', color: 'white', marginLeft: '1px' }} />
                                                                    </> : offer?.targetCommunities.length === 0 && <>
                                                                        <Chip label={"All communities"} size="small" sx={{ backgroundColor: '#3ED1CF', color: 'white', marginLeft: '1px' }} />
                                                                    </>
                                                                }
                                                                {
                                                                    (claimed || owner) && offer.discordServer && offer.discordServer !== '' ? <>
                                                                        <Chip label="Discord Server" onClick={() => window.open(offer.discordServer, "_blank")} size="small" sx={{ backgroundColor: '#7289DA', color: 'white', marginLeft: '1px', cursor: 'pointer' }} />
                                                                    </> : ''
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <p style={style.desc}>
                                                            {/* {offer.description.length > 500 ? showMore ? offer.description.split('\n').map(str => <p style={style.desc}>{str}</p>) : offer.description.slice(0, 500).split('\n').map(str => <p style={style.desc}>{str}</p>) : offer.description.split('\n').map(str => <p style={style.desc}>{str}</p>)} {offer.description.length > 500 && <strong style={{ cursor: 'pointer', color: '#BC09C7', textDecoration: 'underline' }} onClick={() => setShowMore(!showMore)}>{showMore ? 'show less' : 'show more'}</strong>} */}
                                                            {
                                                                offer.description.length > 500 ? (
                                                                    showMore ? (
                                                                        offer.description
                                                                            .split('\n')
                                                                            .map((str) => <p style={{ ...style.desc, ...style.margin }}>{renderLinks(str)}</p>)
                                                                    ) : (
                                                                        offer.description
                                                                            .slice(0, 500)
                                                                            .split('\n')
                                                                            .map((str) => <p style={{ ...style.desc, ...style.margin }}>{renderLinks(str)}</p>)
                                                                    )
                                                                ) : (
                                                                    offer.description.split('\n').map((str) => <p style={{ ...style.desc, ...style.margin }}>{renderLinks(str)}</p>)
                                                                )
                                                            }{' '}
                                                            {offer.description.length > 500 && (
                                                                <strong
                                                                    style={{ cursor: 'pointer', color: '#BC09C7', textDecoration: 'underline' }}
                                                                    onClick={() => setShowMore(!showMore)}
                                                                >
                                                                    {showMore ? 'show less' : 'show more'}
                                                                </strong>
                                                            )}
                                                        </p>
                                                    </CardContent>
                                                </Card>

                                            </>
                                        }

                                        {!offerDets && !showTimer && <>
                                            <Card sx={{ borderLeft: '4px solid #BC09C7', borderRadius: '10px', marginTop: '3%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                <CardContent>
                                                    <p style={{ ...style.pTag }} >{(now > offer.endsAt ? "Expired!" : (offer.startsAt > now ? "Starts In" : "Ends In"))}</p>
                                                    {
                                                        !(now > offer.endsAt) && <div style={{ display: 'flex' }}>
                                                            {now < offer.startsAt ? display === offer.startsAt
                                                                && <Countdown date={Number(`${display.toString()}000`)} renderer={({ days, completed }) => completed ? "" : <h5 style={style.timer}>{days}d</h5>} /> : display === offer.endsAt && <Countdown date={Number(`${display.toString()}000`)} renderer={({ days, completed }) => completed ? "" : <h5 style={style.timer}>{days}d</h5>} />}
                                                            <h5 style={{ margin: 0, padding: '15px 12px' }}>:</h5>
                                                            {now < offer.startsAt ? display === offer.startsAt
                                                                && <Countdown date={Number(`${display.toString()}000`)} renderer={({ hours, completed }) => completed ? "" : <h5 style={style.timer}>{hours}h</h5>} /> : display === offer.endsAt && <Countdown date={Number(`${display.toString()}000`)} renderer={({ hours, completed }) => completed ? "" : <h5 style={style.timer}>{hours}h</h5>} />}
                                                            <h5 style={{ margin: 0, padding: '15px 12px' }}>:</h5>
                                                            {now < offer.startsAt ? display === offer.startsAt
                                                                && <Countdown date={Number(`${display.toString()}000`)} renderer={({ minutes, completed }) => completed ? "" : <h5 style={style.timer}>{minutes}m</h5>} /> : display === offer.endsAt && <Countdown date={Number(`${display.toString()}000`)} renderer={({ minutes, completed }) => completed ? "" : <h5 style={style.timer}>{minutes}m</h5>} />}
                                                            <h5 style={{ margin: 0, padding: '15px 12px' }}>:</h5>
                                                            {now < offer.startsAt ? display === offer.startsAt
                                                                && <Countdown date={Number(`${display.toString()}000`)} renderer={({ seconds, completed }) => completed ? "" : <h5 style={style.timer}>{seconds}s</h5>} /> : display === offer.endsAt && <Countdown date={Number(`${display.toString()}000`)} renderer={({ seconds, completed }) => completed ? "" : <h5 style={style.timer}>{seconds}s</h5>} />}
                                                        </div>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </>
                                        }

                                        <Card sx={{ borderLeft: '4px solid #BC09C7', borderRadius: '10px', marginTop: '3%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                            <CardContent>
                                                <div style={{ display: 'flex' }}>
                                                    {!offerDets ? <div>
                                                        <p style={{ ...style.pTag, ...style.margin, }} >Claimed</p>
                                                        <h3>{offerDets ? listedOffer.isSold ? 1 : 0 : offer.count - available}/{offerDets ? 1 : offer.count}</h3>
                                                    </div> : <div>
                                                        <p style={{ ...style.pTag, ...style.margin, }} >Seller</p>
                                                        <h3>{listedOffer.seller.username}</h3>
                                                    </div>
                                                    }
                                                    <div>
                                                        <p style={{ ...style.pTag, ...style.margin, ...style.marginLeft }} >Price</p>
                                                        <h3 style={style.marginLeft}>{offerDets ? listedOffer?.price : (offer.price)} {offerDets && listedOffer.isEth ? 'MATIC' : 'WETH'}
                                                            {offerDets && owner && !listedOffer.isSold && <Tooltip title="Update Price">
                                                                <ModeEditOutlineIcon onClick={() => {
                                                                    setShow('edit')
                                                                    setOpen2(true)
                                                                }} sx={{ color: '#999999', height: '15px', cursor: 'pointer' }} />
                                                            </Tooltip>}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    {/* <Button onClick={() => {
                                                    setShow('delete')
                                                    setOpen2(true)
                                                }} sx={{ ...style.btn, ...style.margin }}>Delist</Button>
                                                    : buyed ?
                                                        <Grid container columnSpacing={3}>
                                                            <Grid item md={6}>
                                                                <Button onClick={() => {
                                                                    setShow('create')
                                                                    setOpen2(true)
                                                                }} sx={{ backgroundColor: 'white', color: '#292D32', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>List</Button>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                {
                                                                    redeemed ? <Badge badgeContent={<Tooltip title="To enable multiple redeems please transfer this NFT offer to another wallet to redeem"><InfoIcon /></Tooltip>} sx={{ width: '100%' }}>
                                                                        <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Redeemed</Button>
                                                                    </Badge> : redemption ? <Button onClick={() => navigate('/redeemoffer', { state: { offer: offer } })} sx={{ backgroundColor: '#292D32', color: 'white', textTransform: 'none', width: '100%', border: '2px solid #292D32', '&:hover': { backgroundColor: '#292D32', color: 'white' } }}>Redeem</Button>
                                                                        : dc ? <Button sx={{ ...style.margin, backgroundColor: '#7289DA', width: '100%', textTransform: 'none', color: 'white' }} onClick={() => window.open(offer.discordServer, "_blank")} >Join Discord Server</Button>
                                                                            : <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Not redeemable yet</Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        : !loading ? <Button sx={{ ...style.btn, ...style.margin }} onClick={buylisted}  >Buy listed offer</Button>
                                                            : <div style={{ display: 'flex', justifyContent: 'center' }}> <CircularProgress sx={{ ...style.btn, borderRadius: '50%', padding: '5px' }} /> </div>
                                                        : <Button sx={{ color: '#342E57 !important', fontWeight: 'bold', backgroundColor: '#E2E2E2 !important', width: '100%' }} disabled>Sold out</Button> */}

                                </Grid>
                            </Grid>
                            {
                                !offerDets && owner && offer && inAppRed && <>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '3%' }}>
                                        <h4>Redeemers List</h4>
                                        <Tooltip title="Export CSV">
                                            <DownloadIcon sx={{ cursor: 'pointer' }} onClick={() => downloadCSV(tableData)} />
                                        </Tooltip>
                                    </Grid>
                                    <TableContainer sx={{ marginTop: '' }} component={Paper}>
                                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Name</StyledTableCell>
                                                    <StyledTableCell align="center">Email</StyledTableCell>
                                                    <StyledTableCell align="center">Phone No.</StyledTableCell>
                                                    <StyledTableCell align="center">Address</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData.map((row, index) => (
                                                    <StyledTableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <StyledTableCell component="th" scope="row">{row.redeemName}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.redeemEmail}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.redeemPhoneNo}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.redeemAddress}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            }
                        </Grid>
                        {/* {similarOffers.length !== 0 && <Grid item md={10} sx={{ overflow: 'scroll', "&::-webkit-scrollbar": { display: 'none' }, display: { md: 'flex', xs: 'none' }, flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container columnSpacing={3} sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <h3>Similar Offers</h3>
                            <HorizontalScroll>
                                {
                                    similarOffers.map((offerMet, index) => {
                                        return <Grid item columnSpacing={3}><SimilarOfferCard key={index} offerMet={offerMet} /></Grid>
                                    })
                                }
                            </HorizontalScroll>
                        </Grid>
                    </Grid>} */}

                        <DiscordModal open={open} setOpen={setOpen} id={offer._id} setInAppRed={setInAppRed} setDc={setDc} />
                    </Grid >
                </>
                    : <Loading />
            }

            <ResellModal open={open2} setOpen={setOpen2} handleClose={handleClose} handleOpen={handleOpen} nftId={nftId} offer={offer} listedOffer={listedOffer} show={show} setListedOffer={setListedOffer} />
        </>
    )
}

export default OfferDetails