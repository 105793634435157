import { Box, CardMedia, Grid, Typography, Tab, Tabs, Switch, Paper } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { offerContext } from '../../offerContext'
import Identicon from 'react-identicons';
import randomColor from 'randomcolor';
import sha256 from 'js-sha256';
import Details from './Details';
import { searchUserBought, searchUserByAddress, searchUserClaimed, searchUserCreated, searchUserFav, searchUserFavItems, searchUserListed, searchUserRedeemed, searchUserSold } from '../../services/Search';
import Loading from '../loader/Loading';
import NoLiveOffers from '../nothingToSee/NoLiveOffers';
import OfferCard from '../landingPageComponents/card/OfferCard';
import FollowFollowingModal from '../user/FollowFollowingModal';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UserPFP() {
  const { user } = useContext(offerContext)
  const seed = parseInt(sha256(user?.walletAddress).substring(0, 16), 16)
  const seed2 = parseInt(sha256(user?.email).substring(0, 16), 16)

  const color1 = randomColor({ seed });
  const color2 = randomColor({ seed: seed2 });

  // Create a gradient background CSS style
  const gradientBackground = {
    background: `linear-gradient(45deg, ${color1}, ${color2})`,
    height: '40vh',
    borderRadius: '10px',
  };
  console.log(user.walletAddress)


  const { allCommunities, eligible } = useContext(offerContext)
  // const [created, setCreated] = useState(userCreatedOffers)
  let res = [[...new Map(eligible.map(item =>
    [item._id, item])).values()]][0]

  // const [myList, setMyList] = useState([])
  // const [myListings, setMyListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [toggleCreate, setToggleCreate] = useState(false)
  const [toggleSave, settoggleSave] = useState(false)
  const [toggleTrade, settoggleTrade] = useState(false)
  const [toggleClaimed, settoggleClaimed] = useState(false)
  const [redeemable, setRedeemable] = useState([])
  const [nonRedeemable, setNonRedeemable] = useState([])
  // const [inClaimed, setInClaimed] = useState([])
  const [value, setValue] = React.useState(0);
  // const [claimed, setClaimed] = useState([])
  const [created, setCreated] = useState([])
  const [listed, setListed] = useState([])
  const [bought, setBought] = useState([])
  const [sold, setSold] = useState([])
  const [fav, setFavs] = useState([])
  const [favItems, setFavItems] = useState([])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function removeElements(arr1, arr2) {
    return arr1.filter(item => !arr2.includes(item));
  }

  useEffect(() => {
    const func = async () => {
      setLoading(true)
      // let res = await searchUserByAddress((JSON.parse(localStorage.getItem("deOffersUser"))).walletAddress )
      let id = (JSON.parse(localStorage.getItem("deOffersUser")))._id
      let claimres = await searchUserClaimed(id)
      let rederes = await searchUserRedeemed(id)
      let temp = removeElements(claimres.data, rederes.data);
      setNonRedeemable(rederes.data)
      setRedeemable(temp)
      setLoading(false)
      let createres = await searchUserCreated(id)
      let listres = await searchUserListed(id)
      let boures = await searchUserBought(id)
      let solres = await searchUserSold(id)
      let favres = await searchUserFav(id)
      let favitemres = await searchUserFavItems(id)
      // setThisUser(res.data)
      setCreated(createres.data)
      setListed(listres.data)
      setBought(boures.data)
      setSold(solres.data)
      setFavs(favres.data)
      setFavItems(favitemres.data)
      console.log(res.data)
    }
    func()
  }, [])

  return (
    <>
      {loading ? <Loading /> : <Grid sx={{ position: 'relative', maxHeight: '320px' }}>
        {
          user.profileBanner ? <CardMedia component="img" image={user.profileBanner} sx={{ height: '40vh' }} />
            : <Box sx={gradientBackground}></Box>
        }


        {
          user.profileImage ? <CardMedia component="img" image={user.profileImage} sx={{ height: '10vh', width: '10vh', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '4px solid black', position: 'absolute', top: '35vh', left: { md: '5%', sd: '5%', xs: '35vw' } }} /> :
            <Box sx={{ height: '10vh', width: '10vh', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '4px solid black', position: 'absolute', top: '35vh', left: { md: '5%', sd: '5%', xs: '35vw' } }}>
              <Identicon string={user.walletAddress} size={40} bg='white' />
            </Box>
        }
        <Box sx={{ position: 'absolute', top: '41vh', width: '100%' }}>
          <Details />

          <>
            <Grid sx={{ marginTop: '5%' }}>
              <Paper sx={{ width: '100%', backgroundColor: 'white', borderRadius: '50px', padding: '5px' }}>
                <Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab className='mediaQuery' {...a11yProps(0)} label='Claimed' />
                  <Tab className='mediaQuery' {...a11yProps(1)} label='Owned' />
                  <Tab className='mediaQuery' {...a11yProps(2)} label='Trade' />
                  <Tab className='mediaQuery' {...a11yProps(3)} label='Favourite' />
                </Tabs>
              </Paper>
              <TabPanel value={value} index={0}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                  <p>Non-Redeemed</p>
                  <Switch checked={toggleClaimed} onChange={() => settoggleClaimed(!toggleClaimed)} color="secondary" />
                  <p>Redeemed</p>
                </div>
                <Grid item container spacing={2}>

                  {
                    toggleClaimed ? nonRedeemable && nonRedeemable.length !== 0 ? nonRedeemable.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offer={offer} communities={allCommunities} disable={true} showIcon={true} />
                      </Grid>
                    }) : <NoLiveOffers text={"Nothing to show"} /> : redeemable && redeemable.length !== 0 ? redeemable.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offer={offer} communities={allCommunities} disable={true} showIcon={true} />
                      </Grid>
                    }) : <NoLiveOffers text={"Nothing to show"} />

                  }

                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                  <p>Created</p>
                  <Switch checked={toggleCreate} onChange={() => setToggleCreate(!toggleCreate)} color="secondary" />
                  <p>Listed</p>
                </div>
                <Grid item container spacing={2}>
                  {
                    toggleCreate ? listed && listed.length === 0 ? <NoLiveOffers text={"Nothing to show"} /> : listed.filter((offer) => !offer.isSold).map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offerDets={offer} communities={allCommunities} disable={true} create={true} />
                      </Grid>
                    }) : created && created.length === 0 ? <NoLiveOffers text={"Nothing to show"} /> : created.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offer={offer} communities={allCommunities} disable={true} create={true} />
                      </Grid>
                    })
                  }
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                  <p>Bought</p>
                  <Switch checked={toggleTrade} onChange={() => settoggleTrade(!toggleTrade)} color="secondary" />
                  <p>Sold</p>
                </div>
                <Grid item container spacing={2}>
                  {toggleTrade ? sold && sold.length !== 0 && sold !== "" ? sold.map((offer, index) => {
                    return <Grid item md={3} xs={4} key={index}>
                      <OfferCard offerDets={offer} communities={allCommunities} disable={true} showIcon={true} />
                    </Grid>
                  }) : <NoLiveOffers text={"Nothing to show"} />
                    : bought && bought.length !== 0 && bought !== "" ? bought.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offerDets={offer} communities={allCommunities} disable={true} showIcon={true} />
                      </Grid>
                    }) : <NoLiveOffers text={"Nothing to show"} />
                  }
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                  <p>Offers</p>
                  <Switch checked={toggleSave} onChange={() => settoggleSave(!toggleSave)} color="secondary" />
                  <p>Items</p>
                </div>
                <Grid item container spacing={2}>
                  {
                    toggleSave ? favItems && favItems.length !== 0 && favItems !== "" ? favItems.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offerDets={offer} communities={allCommunities} disable={true} showIcon={true} />
                      </Grid>
                    }) : <NoLiveOffers text={"Nothing to show"} /> : fav && fav.length !== 0 && fav !== "" ? fav.map((offer, index) => {
                      return <Grid item md={3} xs={4} key={index}>
                        <OfferCard offer={offer} communities={allCommunities} disable={true} showIcon={true} />
                      </Grid>
                    }) : <NoLiveOffers text={"Nothing to show"} />

                  }
                </Grid>
              </TabPanel>

            </Grid>
          </>
        </Box>
      </Grid>}
    </>
  )
}

export default UserPFP