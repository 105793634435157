import { Divider, FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { offerContext } from '../../../offerContext';
import OfferCard from '../../landingPageComponents/card/OfferCard';
export const BrandContext = createContext([])

function BrandFilter({ brandOffers }) {
    const params = useParams()
    const [status, setStatus] = useState("Active")
    const [sort, setSort] = useState("NewToOld")
    const id = params.id
    const { allCommunities } = useContext(offerContext)
    // console.log(id, 'idddd');
    const [filterData, setFilterData] = useState(brandOffers)
    const checkStatus = (i) => {
        const now = moment().unix();
        let stats

        if (now > i.endsAt) {
            stats = "Expired"
        }
        else if (i.startsAt > now) {
            stats = "Upcoming";
        }

        else if (i.startsAt <= now <= i.endsAt) {
            stats = "Active";
        }
        return stats
    }

    let data = brandOffers;
    useEffect(() => {
        if ((sort !== "None")) {
            if (sort === "HighToLow") {
                data = data.filter((offer) => checkStatus(offer) === status)
                setFilterData(() => data.sort((a, b) => b.price - a.price));
            }
            else if (sort === "LowToHigh") {
                data = data.filter((offer) => checkStatus(offer) === status)
                setFilterData(data = data.sort((a, b) => a.price - b.price));
            }
            else if (sort === "NewToOld") {
                data = data.filter((offer) => checkStatus(offer) === status)
                setFilterData(() => data.sort((a, b) => b.createdAt - a.createdAt));
            }
            else if (sort === "OldToNew") {
                data = data.filter((offer) => checkStatus(offer) === status)
                setFilterData(() => data.sort((a, b) => a.createdAt - b.createdAt));
            }
        }
        setFilterData(data)
        // console.log(status, sort, data, 'setstatus')
    }, [status, sort])
    // console.log(allCommunities, 'allcocom')


    return (<BrandContext.Provider value={{ offers: filterData }}>
        <FormControl sx={{ m: 1, minWidth: 120, width: '20%' }}>
            <FormHelperText>Status</FormHelperText>
            <Select
                value={status}
                onChange={(e) => setStatus(() => e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value={"Active"}>Active</MenuItem>
                <MenuItem value={"Upcoming"}>Upcoming</MenuItem>
                <MenuItem value={"Expired"}>Expired</MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120, width: '20%' }}>
            <FormHelperText>Sort</FormHelperText>
            <Select
                value={sort}
                onChange={(e) => setSort(() => e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value={"None"}>
                    <em>None</em>
                </MenuItem>
                <MenuItem value={"NewToOld"}>New To Old</MenuItem>
                <MenuItem value={"OldToNew"}>Old To New</MenuItem>
                <MenuItem value={"HighToLow"}>High To Low</MenuItem>
                <MenuItem value={"LowToHigh"}>Low To High</MenuItem>
            </Select>
        </FormControl>
        <Grid item xs={12}>
            <Divider sx={{ marginTop: '2%', marginBottom: '2%', borderColor: 'rgb(0 0 0 / 8%)' }} />
        </Grid>
        <Grid container >
            <Grid item md={12}>
                <Grid item container columnSpacing={5} rowSpacing={5}>
                    {
                        filterData.map((offerdata, index) => {
                            return <Grid item md={3} xs={12} key={index}>
                                <OfferCard offer={offerdata} communities={allCommunities} />
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    </BrandContext.Provider>
    )
}

export default BrandFilter