import { Avatar, Badge, Card, CardContent, CardMedia, Divider, Grid, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import CommunityServices from '../../../services/CommunityServices';
import { offerContext } from '../../../offerContext';

const style = {
    heading: { margin: '0px', color: '#292D32', fontFamily: 'Poppins', fontWeight: 'bolder', padding: '0px', fontSize: { sm: '15px', xs: '8px' }, display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' },
    p: { margin: '0px', color: '#636363', fontFamily: 'Poppins', fontSize: { md: '10px', sm: '8px', xs: '6px' }, padding: '0px' },
    communityImg: { width: { md: '80px', sm: '50px', xs: '30px' }, border: '2px solid black', height: { md: '80px', sm: '50px', xs: '30px' }, borderRadius: '50%', display: 'flex', justifyContent: 'center' },
    nickname: { fontSize: { md: '12px', xs: '7px' }, margin: 0, padding: '0', fontWeight: 'bold' },
}

function TrustedCommunities({ allCommunities }) {
    // console.log(allCommunities, 'resdata');
    const {selectedNetwork} = useContext(offerContext)
    const navigate = useNavigate()
    const [comForthis, setComForthis] = useState([])

    const loadCommForThis = async () => {
        await CommunityServices.getPaginatedCommunities(1, 8, selectedNetwork.chain)
            .then((res) => {
                setComForthis(res.data)
            }).catch((e) => console.log(e))
    }

    useEffect(() => {
        loadCommForThis()
    }, [selectedNetwork])

    return (
        <>
            <Grid sx={{ marginTop: '4%' }}>
                <Typography variant='h4' sx={{ fontWeight: 'bolder', fontSize: { sm: '20px', xs: '15px' }, fontFamily: 'Poppins', margin: '0', padding: '0' }}>Top Communities</Typography>
                {comForthis && comForthis.length !== 0 ? <Grid container spacing={1} sx={{ height: '100%', marginTop: '1%' }}>
                    {comForthis && comForthis.map((community, index) => {
                        if (index < 8) {
                            return (
                                <Grid item key={index} onClick={() => navigate(`/communitydetails/${community._id}`, { state: { id: community._id } })} md={1.5} xs={2} sx={{ width: '100%', height: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                    <Tooltip title={community.name}>
                                        {
                                            community.img === "https://learnhrm.shrm.org/wp-content/uploads/2020/04/logo-placeholder-generic.200x200.png" || community.img === "" ?
                                                <Avatar sx={{ ...style.communityImg, fontSize: '50px', color: '#BC09C7', backgroundColor: 'white', fontFamily: 'Poppins', fontWeight: '800' }}>{community.name[0].toUpperCase()}</Avatar>
                                                : <CardMedia component='img' sx={style.communityImg} image={community.img} style={{ objectFit: "contain" }} />
                                        }
                                    </Tooltip>
                                </Grid>)
                        }
                    })}
                </Grid> : <p style={{ fontSize: '14px', color: 'grey' }}>No communities to show </p>}
            </Grid>
        </>
    )
}

export default TrustedCommunities