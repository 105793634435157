import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel } from '@mui/material';
import { offerContext } from '../../../offerContext';
import { useWeb3React } from '@web3-react/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 700, sm: 700, xs: 400 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '75vh',
    borderRadius: '10px',
    paddingBottom: '0'
    // "&::-webkit-scrollbar": { display: 'none' }
};

const btn = {
    color: '#BC09C7',
    textTransform: 'none'
}

export default function DisclaimerModal({ open, setOpen, activeStep, setActiveStep }) {
    const [checked, setChecked] = React.useState(false);
    const { setUser, setAccount } = React.useContext(offerContext)
    const { deactivate } = useWeb3React()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    console.log(open)
    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ overflowY: 'scroll', height: '57vh' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Disclaimer
                        </Typography>

                        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'justify' }}>
                            Nothing in this Website shall be deemed to constitute a prospectus of any sort or a solicitation for investment, nor does it in any way pertain to an offering or a solicitation of an offer to buy any securities in any jurisdiction. This document is not composed in accordance with, and is not subject to, laws or regulations of any jurisdiction which prohibits or in any manner restricts transactions in respect of, or with use of, NFTs and digital cryptocurrencies.
                            The information set forth in this Website may not be exhaustive and does not imply any elements of a contractual relationship. The content of this Website is not binding on the Company and the Company reserves the right to change, modify, add, or remove portions of this Website for any reason at any time before, during and after the sale of its services by updating an amended Website.
                            NFTs are not a digital currency, security, commodity, or any other kind of financial instrument and have not been registered under the Securities and Exchange Board of India Act,1992 and Reserve bank of India Act, 1934 as amended, the Securities laws of India or the Securities laws of any other country, including the Securities laws of any jurisdiction in which a potential token holder is a resident. The NFTs are not being offered or distributed to holders, citizens of, natural and legal persons, having their habitual residence, location or their seat of incorporation in the country or territory where transactions with NFTs are prohibited or in any manner restricted by applicable laws or regulations. If such a restricted person purchases the NFTs, such a restricted person has done so on an unlawful, unauthorized and fraudulent basis and in this regard, shall bear negative consequences.
                            Each purchaser of the NFTs is reminded that this Website has been presented to him/her on the basis that he/she is a person into whose attention the document may be lawfully presented in accordance with the laws of the purchaser’s jurisdiction. It is the responsibility of each potential purchaser of the NFTs to determine if the purchaser can legally purchase the NFTs in the purchaser’s jurisdiction and whether the purchaser can then resell the NFTs to another purchaser in any given jurisdiction.
                            Certain statements, estimates and financial information contained in this Website are for informational purposes only, and may constitute forward-looking statements or information. Such forward-looking statements or information involve known and unknown risks and uncertainties which may cause actual events or results to differ materially from the estimates or the results implied or expressed in such forward-looking statements or information. The English language Website is the primary official source of information. The information contained in the English language Website may from time to time be translated into other languages. In the course of such translation some of the information contained in the English language Website may be lost, corrupted or misrepresented. The accuracy of such alternative communications cannot be guaranteed. In the event of any conflicts or inconsistencies between such translations and the official English language Website, the provisions of the English language original document shall prevail.
                        </Typography>
                    </Box>
                    <FormControlLabel control={<Checkbox checked={checked}
                        onChange={() => setChecked(!checked)} />} sx={{ color: '#BC09C7' }} label="By ticking this box I agree that I have read the disclaimer" />
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button onClick={() => {
                            deactivate();
                            setUser(null);
                            setAccount('')
                            setActiveStep(0);
                            localStorage.clear()
                            setOpen(false)
                        }} sx={btn}>Back</Button>
                        <Button onClick={() => setActiveStep(activeStep + 1)} disabled={!checked} sx={checked ? { textTransform: 'none', backgroundColor: '#BC09C7', border: '2px solid #BC09C7', color: 'white', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' } } : { backgroundColor: 'gray', color: 'white !important', border: '2px solid gray', textTransform: 'none' }}>Continue</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}