import React, { useContext } from 'react'
import OfferDetails from '../components/offerDetails/OfferDetails';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

function OfferDetailPage({ offerId, buyOfferFromApp, offerSummary }) {
    const { user } = useContext(offerContext)

    return (
        <>
            <SideDrawer currentUser={user}>
                <OfferDetails buyOfferFromApp={buyOfferFromApp} offerSummary={offerSummary} />
            </SideDrawer>
        </>
    )
}

export default OfferDetailPage;