import * as React from 'react';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { offerContext } from '../../../offerContext';
import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import './modal.css'
import { useState } from 'react';
import IpfsServices from '../../../services/IpfsServices';
import BrandServices from '../../../services/BrandServices';
import { useNavigate } from 'react-router';
import successHandler from '../../../helpers/successHandler';

function ModalTwoContent({ setActiveStep, activeStep, open, setOpen }) {
    const { user, setUserBrand, token } = useContext(offerContext)
    const [loading, setLoading] = useState(false)
    const [brand, setBrand] = useState({ name: '', nickName: '', walletAddress: user.walletAddress, description: '', secondaryImg: '', img: '' })
    const navigate = useNavigate()
    // console.log(brand);
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setBrand({ ...brand, [name]: value });
    }

    const createBrand = async () => {
        setLoading(true)
        await BrandServices.newBrand({ name: brand.name, nickname: brand.nickName, walletAddress: brand.walletAddress.toLowerCase(), description: brand.description, img: brand.img, secondaryImg: brand.secondaryImg }, token)
            .then((res) => {
                // console.log(res.data);
                setUserBrand(res.data ? res.data : null)
                // console.log(res.data)
                localStorage.setItem("deOffersBrand", JSON.stringify(res.data ? res.data : null))
                // setActiveStep(activeStep + 1)
                setLoading(false)
                navigate('/create')
                setOpen(false)
                successHandler('Brand created successfully')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // console.log(brand)

    return (
        <Grid container spacing={2} sx={{ padding: '2% 0' }}>
            <Grid item md={12} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Wallet Address</p>
                <TextField sx={{ width: '100%' }} name='walletAddress' value={brand.walletAddress} placeholder={user.walletAddress} disabled />
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Brand Name</p>
                <TextField sx={{ width: '100%' }} name='name' value={brand.name} placeholder='Name' onChange={handleChange} />
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Brand Abbrevation</p>
                <TextField sx={{ width: '100%' }} name='nickName' value={brand.nickName} placeholder='Nickname' onChange={handleChange} />
            </Grid>
            <Grid item md={12} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Description</p>
                <TextField sx={{ width: '100%' }} name='description' value={brand.description} onChange={handleChange} placeholder='Description' multiline rows={3} />
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Profile Image</p>
                <TextField sx={{ width: '100%' }}
                    type="file"
                    name='img'
                    inputProps={{ accept: 'image/*' }}
                    onChange={async (e) => {
                        e.preventDefault()
                        let form2Data = new FormData()
                        form2Data.append('files', e.target.files[0]);
                        // const headers = { 'content-type': 'multipart/form-data' }
                        const res = await IpfsServices.uploadImg(form2Data, token)
                        setBrand({ ...brand, img: res.data.urls[0] })
                    }}
                />
            </Grid>
            <Grid item md={6} sx={{ width: '100%' }}>
                <p style={{ fontSize: '12px' }}>Banner image</p>
                <TextField sx={{ width: '100%' }}
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    onChange={async (e) => {
                        e.preventDefault()
                        let form2Data = new FormData()
                        form2Data.append('files', e.target.files[0]);
                        const res = await IpfsServices.uploadImg(form2Data, token)
                        setBrand({ ...brand, secondaryImg: res.data.urls[0] })
                    }}
                />
            </Grid>
            <Grid item md={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => createBrand()} sx={{ color: '#BC09C7', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>
                        Back
                    </Button>
                    {
                        loading ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} />
                        </Box> : <Button onClick={() => createBrand()} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>
                            Create Brand
                        </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default ModalTwoContent