import { Box, Button, CircularProgress, Fade, Link, Modal, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import axiosHandler from '../../helpers/axiosHandler';
import OfferServices from '../../services/OfferServices';
import { offerContext } from '../../offerContext';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const textField = { width: '100%' };


function DiscordModal({ open, setOpen, id, setInAppRed, setDc }) {

    const [json, setJson] = useState({
        discordServer: ''
    })
    const [load, setLoad] = useState(false)
    const { token } = useContext(offerContext)
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setJson({ ...json, [name]: value });
    }

    const handleSubmit = async () => {
        if (json.discordServer.match(/(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g)) {
            setLoad(true)
            await OfferServices.updateOffer(id, {
                discordServer: json.discordServer
            }, token)
                .then((res) => {
                    // console.log('yay :- ', res)
                    setLoad(false)
                    setOpen(false)
                    setInAppRed(false)
                    setDc(true)
                })
                .catch((e) => {
                    // console.log('redeem fields error yay :-', e);
                    axiosHandler('Something went wrong')
                    setLoad(false)
                })
        }
        else {
            axiosHandler('Enter a valid invite link')
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            sx={{ "& .css-1wnsr1i": { border: 'none', borderRadius: '10px', width: '50%' } }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ fontFamily: 'Poppins', margin: '0', padding: '0', fontWeight: 'bold' }}>
                        Connect Discord
                    </Typography>
                    <p style={{ fontFamily: 'Poppins', padding: '0', color: '#636363', fontSize: '12px', marginBottom: '3%' }} >The redeemers will join the discord server for further procedure
                        <Link href="https://support.discord.com/hc/en-us/articles/208866998-Invites-101" target="_blank">  Ckeck out how to send invite link</Link>

                    </p>
                    <TextField required value={json.discordServer} sx={textField} name='discordServer' placeholder='Enter the link to your discord server' id='discordServer' onChange={handleChange} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '3%' }}>
                        <Button onClick={() => setOpen(false)} sx={{ textTransform: 'none', color: '#BC09C7' }}>Back</Button>
                        {
                            load ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} />
                            </Box> :
                                <Button onClick={handleSubmit} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>Submit</Button>
                        }
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default DiscordModal