import axiosHandler from "../helpers/axiosHandler";
import httpCommon from "../http-common";
import http from "../http-common";
import httpcookie from "../http-cookie"
import httpTransactions from "../http-transactions";

const getBrands = () => {
  return http.get(`/api/v1/brand/`);
};

const getOffersByBrandName = (id) => {
  return http.get(`/api/v1/offer/brand/db/${id}`)
}

const newBrand = (data, token) => {
  return httpTransactions.post(`/api/v1/brand/`, data, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  });
};

const deleteBrands = () => {
  return httpcookie.delete(`/api/v1/brand/`);
};

const getBrandById = (id) => {
  return http.get(`/api/v1/brand/${id}`);
};

const updateBrand = (id, data) => {
  return httpcookie.put(`/api/v1/brand/${id}`, data);
};

const deleteBrand = (id) => {
  return httpcookie.delete(`/api/v1/brand/${id}`);
};

const verifyBrand = (id) => {
  return httpcookie.put(`/api/v1/brand/verify/${id}`);
};

const getUserBrand = async (add) => {
  try {
    return await http.get(`/api/v1/brand/user/${add}`)
  }
  catch (e) {
    console.log(e);
    return false
  }
}

export const createCommunity = async (body, token) => {
  try {
    let res = await httpCommon.post(`/api/v1/community`, body, {
      headers: {
        Authorization: localStorage.getItem('deOffersToken')
      }
    });
    // console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export const searchCommunities = async q => {
  try {
    let res = await httpCommon.get(`/api/v1/community/communities/search?q=${q}`);
    // console.log(res, 'ya');
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCommunityFromContractApi = async (
  address,
  json,
  setJson,
  token,
  index,
  isDev,
  selectedNetwork
  // communities,
) => {
  let res = await fetch(`${isDev ? selectedNetwork.alchemyAPIUrl : selectedNetwork.alchemyMainUrl}9_2htus9LWuPu3sZXn_XT0PICbJV2-wa/getContractMetadata?contractAddress=${address}`,
  ).then(r => r.json());
  console.log(res, 'kkrh');
  //console.log(res.contractMetadata.symbol);

  if (res.contractAddress?.tokenType === 'NOT_A_CONTRACT') {
    console.log('NOT_A_CONTRACT')
    return null;
  }

  let temp = {
    name: res.contractMetadata.name, //res.name,
    nickname: res.contractMetadata.symbol, //res.symbol,
    description: res?.contractMetadata?.description || '',
    contractAddress: res.address.toLowerCase(), //res.id.split(':')[1],
    img: res.contractMetadata?.imageUrl ? res.contractMetadata?.imageUrl :
      '',
  };

  await createCommunity(temp, token).then((res) => {
    console.log(res, 'kkrh')
    const arr = json.targetCommunities
    arr[index] = res.data
    setJson({ ...json, targetCommunities: arr })
    return res.data

  }).catch(async (error) => {
    console.log(error);
    let k = (await searchCommunities(res.contractMetadata.name))[0]
    if (!k) {
      console.log(`Error fetching data for address: ${address}`);
      axiosHandler("Community doesn't exist on this network kindly change the network")
      return null; // Early return if response is undefined
    }
    console.log(k, 'kkrh')
    const arr = json.targetCommunities
    arr[index] = k
    setJson({ ...json, targetCommunities: arr })

    // setData((await searchCommunities(res.contractMetadata.name))[0]);
    console.log('No Such community found :(');
    return k
  })

  // setData(comm);
}

export default {
  getBrands,
  newBrand,
  deleteBrands,
  getBrandById,
  updateBrand,
  deleteBrand,
  verifyBrand,
  getOffersByBrandName,
  getUserBrand
};