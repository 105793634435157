import React, { createContext, useContext, useEffect, useState } from 'react'
import CreateProfile from '../components/createProfile/CreateProfile';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

export const ImageBannerContext = createContext({})

function EditProfilePage({ currentUser }) {
    const { user, setUser } = useContext(offerContext)
    console.log(user)
    //     const storedData = localStorage.getItem('deOffersUser');
    // const parsedData = storedData ? JSON.parse(storedData) : null;
    const [image, setImage] = useState(user?.profileImage || null)
    const [banner, setBanner] = useState(user?.profileBanner || null)
    const [username, setUsername] = useState(user.username ? user.username : '')
    const [email, setEmail] = useState(user.email ? user.email : '')
    const [bio, setBio] = useState(user.bio ? user.bio : '')
    const [socials, setSocials] = useState({ instagram: user.socialHandles ? user.socialHandles[0] && user.socialHandles[0].link : '', facebook: user.socialHandles ? user.socialHandles[1] && user.socialHandles[1].link : '', twitter: user.socialHandles ? user.socialHandles[2] && user.socialHandles[2].link : '', pinterest: user.socialHandles ? user.socialHandles[3] && user.socialHandles[3].link : '', linkedin: user.socialHandles ? user.socialHandles[4] && user.socialHandles[4].link : '', dribbble: user.socialHandles ? user.socialHandles[5] && user.socialHandles[5].link : '' })
    const [profileImage, setProfileImage] = useState(user?.profileImage || null);
    const [profileBanner, setProfileBanner] = useState(user?.profileBanner || null);
    return (
        <>
            <ImageBannerContext.Provider value={{ image, setImage, banner, setBanner, username, setUsername, email, setEmail, bio, setBio, socials, setSocials, setProfileBanner, setProfileImage, profileBanner, profileImage }}>
                <SideDrawer currentUser={user}>
                    <CreateProfile />
                </SideDrawer>
            </ImageBannerContext.Provider>
        </>
    )
}

export default EditProfilePage