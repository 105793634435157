import axios from "axios";
let token = localStorage.getItem("deOffersToken");
const isDev = window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/') ? false : true

export default axios.create({
  baseURL: isDev ? "https://dev.nfthodlr.xyz" : "https://app.nfthodlr.xyz",
  // baseURL: "https://app.nfthodlr.xyz/",


  headers: {
    // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    "Content-type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  mode: "cors"
});