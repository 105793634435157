import Tabs from './Tabs'
import React from 'react'
import Details from './Details'
import UserPFP from './UserPFP'

function Profile() {

    return (
        <>
            <UserPFP />
            {/* <Details /> */}
            {/* <Tabs /> */}
        </>
    )
}

export default Profile