import React, { useContext } from 'react'
import BurnNFTForm from '../components/burnNFT/BurnNFTForm';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

function BurnNFTPage() {
    const { user } = useContext(offerContext)
    return (
        <SideDrawer currentUser={user}>
            <BurnNFTForm />
        </SideDrawer>
    )
}

export default BurnNFTPage