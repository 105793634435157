import React, { useContext } from 'react'
import { useLocation } from 'react-router';
import CommunityDetails from '../components/details/Community/CommunityDetails';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';

function CommunityDetailsPage() {
    const { user } = useContext(offerContext)

    return (
        <>
            <SideDrawer currentUser={user}>
                <CommunityDetails />
            </SideDrawer>
        </>
    )
}

export default CommunityDetailsPage;