import { Web3ReactProvider } from '@web3-react/core';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import { Web3Provider } from "@ethersproject/providers";
import { AuthProvider } from '@arcana/auth';
import { ProvideAuth } from '@arcana/auth-react';

function getLibrary(provider) {
    return new Web3Provider(provider);
}

if (process.env.NODE_ENV !== "development") {
    console.log = () => { };
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
}

const isDev = window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/') ? false : true
const key = isDev ? 'xar_test_9eba439f6f7c4de6e765d96d7b76a77b623368ee' : 'xar_live_85eea83d198f44dc97a9da28e4a4be97b76d1a8b'
const provider2 = new AuthProvider(key);

window.process = {};
ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <ProvideAuth provider={provider2}>
            <App />
        </ProvideAuth>
    </Web3ReactProvider>
    ,
    document.getElementById('root')
);