import http from "../http-common";

export const searchBrands = (search) => {
    return http.get(`/api/v1/brand/brands/search?q=${search}`);
};

export const searchCommunities = (search) => {
    return http.get(`/api/v1/community/communities/search?q=${search}`);
}

export const searchOffers = (search) => {
    return http.get(`/api/v1/offer/offers/search?q=${search}`);
}

export const searchOffersByAddress = (search) => {
    return http.get(`/api/v1/offer/db/address/${search}`);
}

export const searchUsers = (search) => {
    return http.get(`/api/v1/user/users/search?q=${search}`);
}

export const searchUserByAddress = (address) => {
    return http.get(`/api/v1/user/users/wallet/${address}`);
}

export const searchUserClaimed = (id) => {
    return http.get(`/api/v1/user/me/claimed?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserRedeemed = (id) => {
    return http.get(`/api/v1/user/me/redeemed?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserCreated = (id) => {
    return http.get(`/api/v1/user/me/created?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserListed = (id) => {
    return http.get(`/api/v1/user/me/listed?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserBought = (id) => {
    return http.get(`/api/v1/user/me/bought?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserSold = (id) => {
    return http.get(`/api/v1/user/me/sold?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserFav = (id) => {
    return http.get(`/api/v1/user/me/favorites?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export const searchUserFavItems = (id) => {
    return http.get(`/api/v1/user/me/favoriteItems?user_id=${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    });
}

export default {
    searchBrands,
    searchCommunities,
    searchOffers,
    searchOffersByAddress,
    searchUsers
};