import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import axiosHandler from '../../../helpers/axiosHandler';
import BrandServices from '../../../services/BrandServices';
import Loading from '../../loader/Loading';
import BrandAbout from './BrandAbout'
import BrandFilter from './BrandFilter'

function BrandDetails() {
    const params = useParams();
    const idparam = params.id
    const location = useLocation().state
    const idstate = location !== null ? location.id : null


    const [brand, setBrand] = useState({})
    const [brandOffers, setBrandOffers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        setBrandOffers(() => [])
        const idparam = params.id
        const func = async () => {
            await BrandServices.getBrandById(idparam)
                .then(async (res) => {
                    setBrand(res.data)
                    await BrandServices.getOffersByBrandName(idparam)
                        .then((res) => {
                            setBrandOffers(res.data)
                            setLoading(false)
                        })
                        .catch((e) => axiosHandler(e.message))
                })
                .catch((e) => axiosHandler(e.message))
        }
        idstate !== null && idstate === idparam && func()
    }, [idparam, idstate])
    return (
        <>
            {
                loading ? <Loading /> : <>
                    <BrandAbout brand={brand} />
                    <BrandFilter brandOffers={brandOffers} />
                </>
            }
        </>
    )
}

export default BrandDetails