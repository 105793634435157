import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import axiosHandler from '../../../helpers/axiosHandler';
import CommunityServices from '../../../services/CommunityServices';
import Loading from '../../loader/Loading';
import BrandAbout from '../Brand/BrandAbout';
import BrandFilter from '../Brand/BrandFilter';

function CommunityDetails() {
    const params = useParams();
    const idparam = params.id
    const location = useLocation().state
    const idstate = location !== null ? location.id : null
    const [comm, setComm] = useState({})
    const [commOffers, setCommOffers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        setCommOffers(() => [])
        const id = params.id

        const func = async () => {
            await CommunityServices.getCommunityById(id)
                .then(async (res) => {
                    setComm(res.data)
                    // console.log(res.data, 'loc')
                    await CommunityServices.getOffersByCommunityName(res.data._id)
                        .then((res) => {
                            // console.log(res.data, id, 'loc')
                            setCommOffers(() => res.data)
                            setLoading(false)
                        })
                        .catch((e) => axiosHandler(e.message))
                })
                .catch((e) => axiosHandler(e.message))
        }
        idstate !== null && idstate === idparam && func()

    }, [idparam, idstate])

    return (<>
        {
            loading ? <Loading /> : <>
                <BrandAbout brand={comm} />
                <BrandFilter brandOffers={commOffers} />
            </>
        }
    </>
    )
}

export default CommunityDetails