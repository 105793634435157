import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar';
import React from 'react'
import { Box, Button, CardMedia, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import LoginModal from './landingPageComponents/modal/LoginModal';
import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import { offerContext } from '../offerContext';
import { useNavigate } from 'react-router';
import { isObject } from 'formik';
import { useAuth } from '@arcana/auth-react';

const Navbar = ({ currentUser, loadWeb3, openDrawer }) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { user, setUser, account, setAccount, open, setOpen, networks, selectedNetwork, setSelectedNetwork } = useContext(offerContext)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { deactivate } = useWeb3React()


  const viewAcc = () => {
    navigate('/userProfile')
  }

  let connectWC = async () => {
    setOpen(true)
  }

  return (
    <div >

      <Grid container spacing={2} sx={{ width: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' }, justifyContent: { md: 'flex-end', sm: 'space-between' }, alignItems: 'center' }}>
        <Grid item md={2}>
          {
            user !== null && user.walletAddress && <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" onClick={viewAcc} color="inherit">
              <Avatar sx={{ backgroundColor: '#7382986c' }}> {user && user.walletAddress && user.walletAddress.substring(user.walletAddress.length - 3)} </Avatar>
            </IconButton>
          }

        </Grid>
        <Grid item sx={{ diaplay: 'flex' }}>
          <FormControl size="small">
            <Select
              value={selectedNetwork}
              size='small'
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p style={{ fontSize: '12px' }}>Select</p>;
                }

                return <Typography sx={{ fontSize: '12px' }}>{!openDrawer ? selected.chain.length <= 7 ? selectedNetwork.chain : selectedNetwork.chain.slice(0, 5) : selectedNetwork.chain}</Typography>;
              }}
              sx={{ padding: '0', display: 'flex', height: '' }}
              onChange={(e) => setSelectedNetwork(e.target.value)}
            >
              {networks.map((net, i) => {
                return <MenuItem key={i} value={net} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardMedia component='img' sx={{ width: '25px', height: '30px', objectFit: 'contain', margin: 0 }} image={net.icon} />
                  <p style={{ fontSize: '12px' }}>{net.chain}</p>
                </MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        {
          <Grid item>{
            user === null || (isObject(user) && Object.keys(user).length === 0) ? <Button onClick={connectWC} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>Connect wallet</Button> : <Button onClick={async () => {
              await auth.logout()
              deactivate();
              setUser(null);
              setAccount('');
              localStorage.setItem("deOffersUser", JSON.stringify(null))
              localStorage.setItem("deOffersToken", JSON.stringify(''))
              localStorage.setItem("deOffersBrand", JSON.stringify(null))
            }} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '8px' } }}>
              Disconnect wallet</Button>
          }

          </Grid>
        }

      </Grid>
      <LoginModal open={open} handleOpen={handleOpen} handleClose={handleClose} setOpen={setOpen} user={user} />
    </div>
  );
}

export default Navbar;