import axios from "axios";
import http from "../http-common";

const getTransactions = (address) => {
    return axios.get(`https://api-goerli.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=latest&page=1&offset=100&sort=desc`);
};


export default {
    getTransactions
};