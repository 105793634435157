import React, { useContext, useEffect, useState } from 'react'
import FilterOffer from '../components/Filter/FilterOffer'
import FilterSidebar from '../components/Filter/FilterSidebar'
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext'
import moment from 'moment'

function FiltersPage({ allAds, allOffers, allCommunities, items }) {
    const { user, listings } = useContext(offerContext)
    const [brandName, setBrandName] = useState([])
    const [platformName, setPlatformName] = useState([])

    const checkStatus = (i) => {
        const now = moment().unix();
        // console.log(now)
        let stats

        if (now > i.endsAt) {
            stats = "Expired"
        }
        else if (i.startsAt > now) {
            stats = "Upcoming";
        }

        else if (i.startsAt <= now <= i.endsAt) {
            stats = "Active";
        }
        return stats
    }
    const filteredStatus = allOffers.filter(i => checkStatus(i) !== "Expired")
    // console.log(items, 'offer')
    useEffect(() => {
        let unique = [...new Set(filteredStatus.map((item) => [item.brand && item.brand.name, false]))];
        let uniqueBrandsObject = Object.fromEntries(unique)
        setBrandName(Object.keys(uniqueBrandsObject).sort().reduce(
            (obj, key) => {
                obj[key] = uniqueBrandsObject[key];
                return obj;
            },
            {}
        ));
    }, [allOffers]);
    useEffect(() => {
        let unique = [...new Set(filteredStatus.map(item => item.targetCommunities.map(com => [com.name, false]))[0])];
        // console.log(unique, 'uni')
        let uniqueBrandsObject = Object.fromEntries(unique)
        setPlatformName(uniqueBrandsObject);
    }, [allCommunities]);


    return (
        <>
            <FilterSidebar brandName={brandName} offers={allOffers} allCommunities={allCommunities} platformName={platformName} items={items}>
                <SideDrawer currentUser={user}>
                    <FilterOffer allAds={allAds} offers={items ? listings : allOffers} allCommunities={allCommunities} items={items} />
                </SideDrawer>
            </FilterSidebar>
        </>
    )
}

export default FiltersPage