import axiosHandler from "../helpers/axiosHandler.jsx";
import successHandler from "../helpers/successHandler.js";
import http from "../http-common.js";
import httpCookie from "../http-cookie.js";
import UserServices from "./UserServices.js";
import Web3 from 'web3';
import httpTransactions from "../http-transactions.js";
import httpCommon from "../http-common.js";
import { getGasFees } from "./helper.js";


const getAllOffers = (chain) => {
    return http.post(`/api/v1/offer/offers/filter`, {
        chain
    });
};

const getOffersByCreator = (id) => {
    return http.get(`/api/v1/offer/creator/${id}`);
};

const getOffersByCommunity = (id) => {
    return http.get(`/api/v1/offer/community/${id}`);
};

const getClaimedDetails = (id) => {
    return http.get(`/api/v1/offer/claimed/${id}`);
};

const getOfferById = (id) => {
    return http.get(`/api/v1/offer/db/${id}`);
}

const updateClaimedDetails = (id) => {
    return httpCookie.post(`/api/v1/offer/claimed/${id}`);
};

const userFavourites = async (setData) => {
    const result = await httpCookie.get(`/api/v1/user/me/favorites`)
    setData(result.data)
}

// const getOfferCount = async (offerFactory) => {
//     const res = await offerFactory.methods.offerCount().call()
//     return res;
// }

const getLiveData = async (address) => {
    const res = await http.get(`/api/v1/offer/${address}`)
    return res.data
}

const buyDecCount = async (id, token) => {
    const res = await http.put(`/api/v1/offer/buy/${id}`, {}, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    })
    return true
}

const updateOffer = async (id, data, token) => {
    const res = await http.put(`/api/v1/offer/db/${id}`, data, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    })
    return res
}

export const getUserNFTsOfContracts = async (addr, contractAddresses, isDev, selectedNetwork) => {
    try {
        console.log(selectedNetwork.alchemyMainUrl, 'yayk')
        let url = `${isDev ? selectedNetwork.alchemyAPIUrl : selectedNetwork.alchemyMainUrl}9_2htus9LWuPu3sZXn_XT0PICbJV2-wa/getNFTs?owner=${(JSON.parse(localStorage.getItem('deOffersUser'))).walletAddress}&withMetadata=false`;
        // let url = `${selectedNetwork.alchemyMainUrl}9_2htus9LWuPu3sZXn_XT0PICbJV2-wa/getNFTs?owner=${addr}&withMetadata=false`;

        contractAddresses.forEach(i => (url += `&contractAddresses\[\]=${i}`));
        // console.log(url);
        return (await fetch(url).then(r => r.json())).ownedNfts;
    } catch (error) {
        console.log('Getting contract Nft Error:- ', error.message);
    }
};

// const createOffer = (offerFactory, offerUri, startsAt, endsAt, count, price, communities, account) => {
//     console.log(account)
//     offerFactory.methods
//         .createOffer(offerUri, startsAt, endsAt, count, price, communities)
//         .send({
//             from: account
//         })
//         .then(() => {
//             successHandler("Offer Created Successfully")
//             return "confirmed"
//         })
// }

// const redeemOffer = async (offerContract, account, web3, contractAddress, offer, obj, setUser) => {
//     try {
//         // console.log(account);
//         // let approval = await offerContract.methods
//         //   .setApprovalForAll(contractAddress, true)
//         //   .send({
//         //     from: account,
//         //   });
//         // console.log('got Approval');
//         const token = localStorage.getItem("deOffersToken")
//         let hash = await offerContract.methods.burnNFT().send({
//             from: account,
//         })
//         // console.log('Burned NFT');
//         const signature = await web3.eth.personal.sign(
//             `Purpose:\nSign to verify wallet ownership in NFTHodlr platform for redeeming the offer.\n\nWallet address:\n${account}\n\nHash:\n${web3.utils.keccak256(
//                 account
//             )}`,
//             account,
//         );
//         let res = await httpTransactions.post(
//             `api/v1/offer/redeem/complete/${signature}`,
//             {
//                 contractAddress,
//                 offer,
//                 ...obj,
//             },
//             {
//                 headers: {
//                     Authorization: localStorage.getItem('deOffersToken')
//                 }
//             }
//         );
//         // console.log(res);
//         await httpCommon.get(`api/v1/user/me`, {
//             headers: {
//                 Authorization: localStorage.getItem('deOffersToken')
//             }
//         })
//             .then((res) => {
//                 setUser(res.data)
//                 localStorage.setItem("deOffersUser", JSON.stringify(res.data))
//                 successHandler("Successful")
//             })
//             .catch((e) => {
//                 console.log(e);
//                 axiosHandler(e.message)
//             })
//         return true;
//     } catch (error) {
//         // console.log('Redeem:- ', JSON.stringify(error));
//         return false;
//     }
// }


const redeemOffer = async (offerContract, account, web3, contractAddress, offer, obj, setUser, setActiveStep, activeStep) => {
    let msg = '';
    const token = localStorage.getItem("deOffersToken")
    try {
        let priorityFee = await getGasFees()
        let hash = await offerContract.methods.burnNFT().send({
            from: account,
            gasPrice: priorityFee,
        }).on("confirmation", function (confirmationNumber, receipt) {
            console.log("Confirmation number:", confirmationNumber);
            console.log("Receipt:", receipt);
        });
        // console.log('Burned NFT');
    } catch (error) {
        msg = error.message;
        //alert(error.message + ' Try Again Later....');
        console.log('Redeem 1:- ', error);
    }

    try {
        // console.log('Calling API');
        const signature = await web3.eth.personal.sign(
            `Purpose:\nSign to verify wallet ownership in NFTHodlr platform for redeeming the offer.\n\nWallet address:\n${account}\n\nHash:\n${web3.utils.keccak256(
                account
            )}`,
            account,
        );
        let res = await httpTransactions.post(
            `api/v1/offer/redeem/complete/${signature}`,
            {
                contractAddress,
                offer,
                ...obj,
            },
            {
                headers: {
                    Authorization: localStorage.getItem('deOffersToken')
                }
            }
        );

        // console.log(res);
        httpCommon.get(`api/v1/user/me`, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        })
            .then((res) => {
                setUser(res.data)
                localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                setActiveStep(activeStep + 1)
                successHandler("Successfully redeemed the offer 🎉")
            })
            .catch((e) => {
                console.log(e);
                setActiveStep(activeStep - 1)
                axiosHandler(e.message)
            })
        return true;
    } catch (error) {
        msg += '\n' + error.message;
        // alert(msg);
        console.log('Redeem 2:- ', msg);
        return false;
    }
};

const executeMetaTx = async (data, targetAddress, offerAddr, offerId, forwarderC, user, selectedNetwork) => {
    try {
        const web3 = window.web3;
        let from = user.walletAddress;
        console.log('---Caller Wallet:- ', from);
        const nonce = await forwarderC.methods.getNonce(from).call();
        console.log('---Got Nonce from Forwarder');
        const tx = {
            from,
            to: offerAddr, // Target contract address (Offer subcontract)
            value: 0,
            nonce,
            data,
        };
        const digest = await forwarderC.methods
            .getDigest(tx.from, tx.to, tx.value, tx.nonce, tx.data)
            .call();
        console.log('---Got digest from Forwarder:- ', digest);
        const signature = await web3.eth.personal.sign(digest, from);
        console.log('---Transaction Signed');
        // console.log(
        //   JSON.stringify({
        //     tx,
        //     signature,
        //     contractAddress: targetAddress,
        //     chain: selectedNetwork.chain,
        //     offerId,
        //   }),
        // );
        const res = await httpCommon.post('/api/v1/user/metatx', {
            tx,
            signature,
            contractAddress: targetAddress,
            chain: selectedNetwork.chain,
            offerId,
        }, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        console.log('---Meta Tx Status :- ', res.data.success);
        if (res.data.success) return res.data.data;
        else {
            console.log('Meta Tx creation error:- ', res.error);
            return res.data.success;
        }
    } catch (error) {
        console.log('Meta Tx creation error:- ', error.message);
        //notifyEVMError(error)
    }
};

const buyOffer = async (offercontract, price, account, token_id, contractAddress, load, setLoad, user, offer, setUser, token, pContract, selectedNetwork, offerAddress, forwarderC) => {
    let priorityFee = await getGasFees()
    console.log(priorityFee, 'prio')
    let sendObj = {
        from: account,
        gasPrice: priorityFee,
    };

    if (price > 0) {
        let approved = await pContract.methods
            .approve(offerAddress, price)
            .send(sendObj);

        if (!approved) throw Error('Token approval failed');
        console.log(offercontract.methods.buyOffer)
        let data = offercontract.methods
            .buyOffer(contractAddress, token_id)
            .encodeABI()
        let res = await executeMetaTx(data, contractAddress, offerAddress, offer._id, forwarderC, user, selectedNetwork)
        if (!res) return console.log('claim failed')
        await buyDecCount(offer._id, token)
            .then(async (res2) => {
                // console.log(res2, 'eli');
                await httpTransactions.get(`api/v1/user/me`, {
                    headers: {
                        Authorization: localStorage.getItem('deOffersToken')
                    }
                })
                    .then((res) => {
                        setUser(res.data)
                        localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                    })
                    .catch((e) => {
                        console.log(e);
                        axiosHandler(e.message)
                    })
                // localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                // setUser(res.data)
                setLoad(false)
                successHandler("Congratulations You've successfully claimed this offer 🎉")
            })
            .catch((err) => {
                console.log(err, 'update me failed');
                axiosHandler(err.message)
                setLoad(false)
            })
    } else {
        console.log(offercontract.methods.buyOffer)
        let data = offercontract.methods
            .buyOffer(contractAddress, token_id)
            .encodeABI()
        let res = await executeMetaTx(data, contractAddress, offerAddress, offer._id, forwarderC, user, selectedNetwork)
        if (!res) return console.log('claim failed')
        await buyDecCount(offer._id, token)
            .then(async (res2) => {
                // console.log(res2, 'eli');
                await httpTransactions.get(`api/v1/user/me`, {
                    headers: {
                        Authorization: localStorage.getItem('deOffersToken')
                    }
                })
                    .then((res) => {
                        setUser(res.data)
                        localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                    })
                    .catch((e) => {
                        console.log(e);
                        axiosHandler(e.message)
                    })
                // localStorage.setItem("deOffersUser", JSON.stringify(res.data))
                // setUser(res.data)
                setLoad(false)
                successHandler("Congratulations You've successfully claimed this offer 🎉")
            })
            .catch((err) => {
                console.log(err, 'update me failed');
                axiosHandler(err.message)
                setLoad(false)
            })
    }


}

const getSummary = async (offercontract) => {
    const res = await offercontract.methods
        .getOfferSummary()
        .call()
    return res;
}

export const getUserClaimableOffers = async (communities, setData, claimed) => {
    try {
        let temp = [];
        // console.log(communities, 'tempp');
        let requests = communities.map(addr => {
            // console.log(addr, 'temp')
            return new Promise((resolve, reject) => {
                getOffersByCommunity(addr)
                    .then(r => resolve(r))
                    .catch(e => reject(e));
            });
        });

        temp = (await Promise.all(requests))
            .flat()
            .filter(
                j =>
                    claimed.find(
                        i => j.contractAddress.toLowerCase() === i.toLowerCase(),
                    ) === undefined,
            );
        // console.log(temp, 'claimtempp');

        return temp;
    } catch (error) {
        console.log(error);
    }
};

export const withdrawETH = async (offerContract, amount, account, setCheck) => {
    try {
        let priorityFee = await getGasFees()
        await offerContract.methods.withdraw().send({
            from: account,
            gasPrice: priorityFee,
        }).on("confirmation", function (confirmationNumber, receipt) {
            console.log("Confirmation number:", confirmationNumber);
            console.log("Receipt:", receipt);
        });
        successHandler(`Your wallet is credited with the collectible balance! 🎉`);
        return true;
    } catch (error) {
        console.log(error);
        // setCheck(false)
        return false;
    }
};

export const getOfferByAddress = async (address) => {
    try {
        let res = await http.get(`/api/v1/offer/${address}`);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getOfferByOfferAddress = async (array, setState) => {
    let offMet = [];
    array.map(async (offer) => {
        await getOfferByAddress(offer)
            .then(async (res) => {
                if (res.data[3] !== "") {
                    let result = await fetch(res.data[3])
                    let metaData = await result.json()
                    offMet.push({ ...metaData, linkUri: res.data[3].split('/')[4], offerAddress: offer });
                }
            })
            .catch((e) => console.log(e))
    })
    setState(offMet)
    // console.log(offMet, 'claimed');
}

export const getCreatedOffers = async (array, user, setState) => {
    const offers = array.filter((offer) => offer.creatorAddress === user.walletAddress)
    setState(offers)
}

export const getClaimedOffers = async (claimed, setData) => {
    let offerUris = []
    let offMet = [];

    let requests = claimed.map(async (address) => {
        return await getOfferByAddress(address)
            .then(async (r) => {
                offerUris.push([address, r.data[3]])
                if (r.data[3] !== "") {
                    var result = await fetch(r.data[3])
                    var metaData = await result.json()
                    offMet.push({ ...metaData, linkUri: r.data[3].split('/')[4], offerAddress: address });

                }
            })
            .catch(e => console.log(e));
    });

    setData(offMet);

};

export const addToFav = async (user, id, setUser, setSave, token, setUserBrand, setUserCreatedOffers, setClaimed, setFav, state, setFavItems) => {
    let arr = user.favorites
    arr.push(id)
    arr = new Set(arr)
    // console.log(arr, 'eli2')
    await UserServices.updateMe(state ? {
        favoriteItems: [...arr]
    } : {
        favorites: [...arr]
    }, token)
        .then(async (res) => {
            // console.log(res.data, 'eli');
            localStorage.setItem("deOffersUser", JSON.stringify(res.data))
            setUser(res.data)
            await UserServices.updateStates(res, setUser, setUserBrand, setUserCreatedOffers, setClaimed, setFav, token, setFavItems)
            setSave(true)
        })
        .catch((err) => {
            console.log(err);
            // axiosHandler(err.message)
        })
}

export const removeFromFav = async (user, id, setUser, setSave, token, setUserBrand, setUserCreatedOffers, setClaimed, setFav, state, setFavItems) => {
    let arr = [...user.favorites]
    arr = arr.filter((add) => add !== id)
    // console.log(arr, 'eli2')
    await UserServices.updateMe(state ? {
        favoriteItems: [...arr]
    } : {
        favorites: [...arr]
    }, token)
        .then(async (res) => {
            // console.log(res.data, 'eli');
            localStorage.setItem("deOffersUser", JSON.stringify(res.data))
            setUser(res.data)
            await UserServices.updateStates(res, setUser, setUserBrand, setUserCreatedOffers, setClaimed, setFav, token, setFavItems)
            setSave(false)
        })
        .catch((err) => {
            console.log(err);
            // axiosHandler(err.message)
        })
}

export const getOfferCount = async offerFactory => {
    try {
        let res = await offerFactory.methods.offerCount().call();
        // console.log(res);
        return res;
    } catch (error) {
        // alert(error.message);
        console.log('Get count:- ', error);
        // console.log(offerFactory)
        return 0;
    }
};

export const uploadJSON = async (data, token) => {
    try {
        let res = await httpCookie.post('/api/v1/ipfs/json', data, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        // console.log(res, data, '00000')

        return res.data.url;
    } catch (error) {
        console.log(error);
    }
};

async function waitForTransactionReceipt(web3, transactionHash) {
    while (true) {
        const receipt = await web3.eth.getTransactionReceipt(transactionHash);
        if (receipt && receipt.blockNumber) {
            return receipt;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second before polling again
    }
}


export const createOffer = async (
    offerFactory,
    offerObject,
    startsAt,
    endsAt,
    count,
    price,
    communities,
    name,
    account,
    web3,
    dbObject,
    token,
    setUpload,
    chain
) => {
    //console.log(offerObject);
    try {
        let dataUri = await uploadJSON(offerObject, token);
        console.log(dataUri, 'debug')
        let priorityFee = await getGasFees()
        console.log(offerFactory, account, priorityFee, 'offfff')
        // console.log('Object ipfs:- ' + dataUri, offerObject.offerName);
        let hash = await offerFactory.methods
            .createOffer(dataUri, startsAt, endsAt, count, price, communities, name, chain)
            .send({
                from: account,
                gasPrice: priorityFee,
            }).on("confirmation", function (confirmationNumber, receipt) {
                console.log("Confirmation number:", confirmationNumber);
                console.log("Receipt:", receipt);
            });
        // console.log('Offer Created:- ', hash);
        let resgen;
        // let web3 = window.web3

        // let receipt = await waitForTransactionReceipt(web3, hash.transactionHash)
        // console.log(receipt)
        // console.log(hash)
        dbObject.offerUri = dataUri;
        dbObject.contractAddress = hash.events.OfferCreated.returnValues['0'].toLowerCase();
        // console.log(dbObject);
        // console.log(dbObject, 'dbObject')
        resgen = await httpCookie.post('/api/v1/offer/', dbObject, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });

        return resgen; //await checkTx(hash.transactionHash, web3);
    } catch (error) {

        console.log('Create:- ', error);
        setUpload(false)
        return false;
    }
};

export const requests = (id, token) => {
    return httpCommon.get(`/api/v1/offer/requests/${id}`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    })
}

export const getCreateFee = async offerFactory => {
    try {
        return await offerFactory.methods.marketFee().call();
    } catch (error) {
        console.log('Getting Fee Error:- ', error.message);
    }
};

export const getNFTOwnedCount = async (
    contract,
    walletAddress,
    tokenId,
    setBalance,
) => {
    try {
        const balance = await contract.methods
            .balanceOf(walletAddress, tokenId)
            .call();
        console.log('Balance Is:- ', balance, typeof balance);
        setBalance(parseInt(balance));
    } catch (error) {
        console.log('NFT Count Error:- ', error.message);
    }
};

export const getPaginatedOffers = (page, size) => {
    return http.get(`/api/v1/offer/offers/paginated?page=${page}&size=${size}`)
}

export const getFilter = (page, size, chain) => {
    return http.post(`/api/v1/offer/offers/filter`, {
        page: page,
        size: size,
        chain
    })
}

export default {
    getAllOffers,
    getOffersByCreator,
    getOffersByCommunity,
    getClaimedDetails,
    updateClaimedDetails,
    createOffer,
    buyOffer,
    getOfferCount,
    getSummary,
    getLiveData,
    withdrawETH,
    getClaimedOffers,
    addToFav,
    removeFromFav,
    getOfferByOfferAddress,
    getCreatedOffers,
    userFavourites,
    getOfferById,
    updateOffer,
    redeemOffer,
    requests
};