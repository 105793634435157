import React from 'react'
import EditFields from './EditFields'
import UserImage from './UserImage'

function CreateProfile() {
    return (
        <>
            <UserImage />
            <EditFields />
        </>
    )
}

export default CreateProfile