import { Box, CardMedia, Button, Tabs, Tab, Grid, Skeleton, Typography, Divider, Tooltip, Paper, Switch, CircularProgress, } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Identicon from 'react-identicons';
import randomColor from 'randomcolor';
import sha256 from 'js-sha256';
import { useNavigate, useParams } from 'react-router';
import { searchUserBought, searchUserByAddress, searchUserClaimed, searchUserCreated, searchUserListed, searchUserRedeemed, searchUserSold } from '../../services/Search';
import { AiFillInstagram } from 'react-icons/ai'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AiFillDribbbleCircle } from 'react-icons/ai'
import OfferCard from '../landingPageComponents/card/OfferCard';
import NoLiveOffers from '../nothingToSee/NoLiveOffers';
import Loading from '../loader/Loading';
import { toggleFollow } from '../../services/UserServices';
import { offerContext } from '../../offerContext';
import FollowFollowingModal from './FollowFollowingModal';

export default function UserDetails() {
    const params = useParams();
    const navigate = useNavigate()
    const address = params.address

    const [thisUser, setThisUser] = useState(null)
    const [claimed, setClaimed] = useState([])
    const [created, setCreated] = useState([])
    const [listed, setListed] = useState([])
    const [bought, setBought] = useState([])
    const [sold, setSold] = useState([])
    const [toggleCreate, setToggleCreate] = useState(false)
    const [toggleTrade, settoggleTrade] = useState(false)
    const [banLoad, setbanLoad] = useState(false)
    const [imgLoad, setimgLoad] = useState(false)
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false)
    const { setUser, user } = useContext(offerContext)
    const [thisUserFollowers, setThisUserFollowers] = useState(null)
    const [thisUserFollowing, setThisUserFollowing] = useState(null)
    const [following, setFollowing] = useState(false);
    const [open, setOpen] = useState(false)
    const [arr, setArr] = useState([])
    const [loader, setLoader] = useState(false)
    const [text, setText] = useState('')

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const func = async () => {
            setLoading(true)
            let res = await searchUserByAddress(address)

            let claimres = await searchUserClaimed(res.data._id)
            let rederes = await searchUserRedeemed(res.data._id)
            let temp = claimres.data.concat(rederes.data)
            temp = Array.from(new Set(temp))
            setClaimed(temp)
            setThisUser(res.data)
            setLoading(false)

            let createres = await searchUserCreated(res.data._id)
            let listres = await searchUserListed(res.data._id)
            let boures = await searchUserBought(res.data._id)
            let solres = await searchUserSold(res.data._id)
            setThisUserFollowers(res.data.followers.length)
            setThisUserFollowing(res.data.following.length)
            setCreated(createres.data)
            setListed(listres.data)
            setBought(boures.data)
            setSold(solres.data)
            console.log(res.data)
        }
        if (address === user?.walletAddress) {
            navigate('/userprofile')
        } else {
            func()
        }
    }, [address])

    useEffect(() => {
        setFollowing(
            user?.following.find(i => i._id === thisUser?._id) === undefined ? false : true,
        );
    }, [user, thisUser]);

    const seed = thisUser && parseInt(sha256(address).substring(0, 16), 16)
    const seed2 = thisUser && parseInt(sha256(address + 1000000000000000).substring(0, 16), 16)

    const color1 = randomColor({ seed });
    const color2 = randomColor({ seed: seed2 });
    const gradientBackground = {
        background: `linear-gradient(45deg, ${color1}, ${color2})`,
        height: '40vh',
        borderRadius: '10px',
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    function abbreviateNumber(number) {
        const SI_SYMBOL = ['', 'k', 'M', 'B', 'T'];
        const tier = Math.log10(Math.abs(number)) / 3 | 0;

        if (tier === 0) return number.toString();

        const suffix = SI_SYMBOL[tier];
        const scale = Math.pow(10, tier * 3);

        const scaledNumber = number / scale;

        // Format the number with a specific number of decimal places
        let formattedNumber;
        if (scaledNumber < 10) {
            formattedNumber = scaledNumber.toFixed(2);
        } else if (scaledNumber < 100) {
            formattedNumber = scaledNumber.toFixed(1);
        } else {
            formattedNumber = scaledNumber.toFixed(0);
        }

        return formattedNumber + suffix;
    }

    const toggleFollowFunc = async (id) => {
        setLoader(true)
        let u = { ...thisUser }; // Create a copy of thisUser object
        let res2 = await toggleFollow(id)
        if (res2) {
            let res = await searchUserByAddress(user.walletAddress)
            setUser({ ...res.data, email: res2.data.email });
            localStorage.setItem("deOffersUser", JSON.stringify({ ...res.data, email: res2.data.email }));
            let res3 = await searchUserByAddress(address)
            setThisUser(res3.data);
            console.log(u)
            setLoader(false)
        }
    }

    // console.log(user)

    return (
        <>
            {loading ? <Loading /> : thisUser && <Grid sx={{ position: 'relative', maxHeight: '320px' }}>
                {
                    thisUser?.profileBanner ? <>
                        <CardMedia onLoad={() => setbanLoad(true)} component="img" image={thisUser?.profileBanner} sx={banLoad ? { height: '40vh' } : { display: 'none' }} />
                        {!banLoad && <Skeleton sx={{ height: '40vh', width: '100%' }} />}
                    </>
                        : <Box sx={gradientBackground}></Box>
                }


                {
                    thisUser?.profileImage ? <CardMedia onLoad={() => setimgLoad(true)} component="img" image={thisUser?.profileImage} sx={{ height: '10vh', width: '10vh', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '4px solid black', position: 'absolute', top: '35vh', left: { md: '5%', sd: '5%', xs: '35vw' } }} /> :
                        <Box sx={{ height: '10vh', width: '10vh', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '4px solid black', position: 'absolute', top: '35vh', left: { md: '5%', sd: '5%', xs: '35vw' } }}>
                            <Identicon string={address} size={40} bg='white' />
                        </Box>
                }
                <Box sx={{ position: 'absolute', top: '41vh', width: '100%' }}>
                    <>
                        <Grid container sx={{ marginTop: { md: '4%', sm: '10%', xs: '15%' }, width: '100%' }}>
                            <Grid item md={2}>
                                <Typography variant='h5' sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', margin: '0', padding: '0' }}>
                                    {thisUser?.username}
                                </Typography>
                                {
                                    user._id !== thisUser._id && user.following.find(i => i._id === thisUser._id) ? loader ? <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <CircularProgress size={20} sx={{ backgroundColor: '#BC09C7', padding: '3px', color: 'white', borderRadius: '50%' }} />
                                    </Box> : <Button size='small' onClick={() => toggleFollowFunc(thisUser._id)} sx={{ textTransform: 'none', padding: '0px 10px', backgroundColor: 'transparent', border: '2px solid #BC09C7', color: '#BC09C7', '&:hover': { color: 'white', bgcolor: '#BC09C7', border: '2px solid #BC09C7' } }}>Following</Button>
                                        : loader ? <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <CircularProgress size={20} sx={{ backgroundColor: '#BC09C7', padding: '3px', color: 'white', borderRadius: '50%' }} />
                                        </Box> : <Button size='small' onClick={() => toggleFollowFunc(thisUser._id)} sx={{ textTransform: 'none', padding: '0px 10px', backgroundColor: 'transparent', border: '2px solid #BC09C7', color: '#BC09C7', '&:hover': { color: 'white', bgcolor: '#BC09C7', border: '2px solid #BC09C7' } }}>Follow + </Button>
                                }

                            </Grid>
                            <Grid md={0.5} item sx={{ padding: '0% 2%' }}>
                                <Divider orientation='vertical' />
                            </Grid>
                            {thisUser && thisUser?.socialHandles && thisUser?.socialHandles.length !== 0 ? <Grid item md={9} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid container columnSpacing={1}>
                                    {
                                        thisUser && thisUser?.socialHandles && thisUser?.socialHandles[0] && thisUser?.socialHandles[0].link && <Grid sx={{ cursor: 'pointer' }} item onClick={() => window.open(thisUser?.socialHandles[0].link, "_blank")}>
                                            <AiFillInstagram size={30} />
                                        </Grid>
                                    }
                                    {thisUser && thisUser?.socialHandles && thisUser?.socialHandles[1] && thisUser?.socialHandles[1].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(thisUser?.socialHandles[1].link, "_blank")} item>
                                        <FacebookIcon sx={{ fontSize: '30px' }} />
                                    </Grid>}
                                    {thisUser && thisUser?.socialHandles && thisUser?.socialHandles[2] && thisUser?.socialHandles[2].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(thisUser?.socialHandles[2].link, "_blank")} item>
                                        <TwitterIcon sx={{ fontSize: '30px' }} />
                                    </Grid>}
                                    {thisUser && thisUser?.socialHandles && thisUser?.socialHandles[3] && thisUser?.socialHandles[3].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(thisUser?.socialHandles[3].link, "_blank")} item>
                                        <PinterestIcon sx={{ fontSize: '30px' }} />
                                    </Grid>}
                                    {thisUser && thisUser?.socialHandles && thisUser?.socialHandles[4] && thisUser?.socialHandles[4].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(thisUser?.socialHandles[4].link, "_blank")} item>
                                        <LinkedInIcon sx={{ fontSize: '30px' }} />
                                    </Grid>}
                                    {thisUser && thisUser?.socialHandles && thisUser?.socialHandles[5] && thisUser?.socialHandles[5].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(thisUser?.socialHandles[5].link, "_blank")} item>
                                        <AiFillDribbbleCircle size={30} />
                                    </Grid>}
                                </Grid>
                            </Grid> : <Grid item md={9} />}
                            <Grid item md={0.4} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'flex-end' }}>
                                <div onClick={() => {
                                    setArr(thisUser.followers)
                                    setText(`${thisUser.username}'s Followers`)
                                    setOpen(true)
                                }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h2>{abbreviateNumber(thisUser.followers.length)}</h2>
                                    <p style={{ fontSize: '12px' }}>Followers</p>
                                </div>
                                <div onClick={() => {
                                    setArr(thisUser.following)
                                    setText(`${thisUser.username} Follows`)
                                    setOpen(true)
                                }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px' }}>
                                    <h2>{abbreviateNumber(thisUser.following.length)}</h2>
                                    <p style={{ fontSize: '12px' }}>Following</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: '2%' }}>
                            <p style={{ fontFamily: 'Poppins', fontSize: '12px', margin: '0', padding: '0' }}>
                                {thisUser?.bio}
                            </p>


                        </Grid>
                    </>
                    {/* <Tabs/> */}
                    <Box sx={{ marginTop: '5%' }}>
                        <Paper sx={{ width: '100%', backgroundColor: 'white', borderRadius: '50px', padding: '5px' }}>
                            <Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className='mediaQuery'  {...a11yProps(0)} label='Claimed' />
                                <Tab className='mediaQuery' {...a11yProps(1)} label='Owned' />
                                <Tab className='mediaQuery' {...a11yProps(2)} label='Trade' />
                            </Tabs>
                        </Paper>
                        <TabPanel value={value} index={0}>
                            <Grid item container spacing={2}>
                                {
                                    claimed.length !== 0 ? claimed.map((offer, index) => {
                                        return <Grid item md={3} xs={4} key={index}>
                                            <OfferCard offer={offer} />
                                        </Grid>
                                    }) : <NoLiveOffers text={"Nothing to show"} />
                                }
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                <p>Created</p>
                                <Switch checked={toggleCreate} onChange={() => setToggleCreate(!toggleCreate)} color="secondary" />
                                <p>Listed</p>
                            </div>
                            <Grid item container spacing={2}>
                                {
                                    toggleCreate ? listed && listed.length === 0 ? <NoLiveOffers text={"Nothing to show"} /> : listed.map((offer, index) => {
                                        return <Grid item md={3} xs={4} key={index}>
                                            <OfferCard offerDets={offer} />
                                        </Grid>
                                    }) : created && created.length === 0 ? <NoLiveOffers text={"Nothing to show"} /> : created.map((offer, index) => {
                                        return <Grid item md={3} xs={4} key={index}>
                                            <OfferCard offer={offer} />
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                <p>Bought</p>
                                <Switch checked={toggleTrade} onChange={() => settoggleTrade(!toggleTrade)} color="secondary" />
                                <p>Sold</p>
                            </div>
                            <Grid item container spacing={2}>
                                {toggleTrade ? sold && sold.length !== 0 && sold !== "" ? sold.map((offer, index) => {
                                    return <Grid item md={3} xs={4} key={index}>
                                        <OfferCard offerDets={offer} showIcon={true} />
                                    </Grid>
                                }) : <NoLiveOffers text={"Nothing to show"} />
                                    : bought && bought.length !== 0 && bought !== "" ? bought.map((offer, index) => {
                                        return <Grid item md={3} xs={4} key={index}>
                                            <OfferCard offerDets={offer} showIcon={true} />
                                        </Grid>
                                    }) : <NoLiveOffers text={"Nothing to show"} />
                                }
                            </Grid>
                        </TabPanel>
                    </Box>
                </Box>
            </Grid>}
            <FollowFollowingModal open={open} setOpen={setOpen} arr={arr} text={text} />
        </>
    )
}
