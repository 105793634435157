import Web3 from "web3";
import OfferFactory from './artifacts/OfferFactory.json';
import Forwarder from './artifacts/Forwarder.json';
import axiosHandler from "./helpers/axiosHandler";
import httpCommon from "./http-common";
import AdServices from "./services/AdServices";
import BrandServices from "./services/BrandServices";
import CommunityServices from "./services/CommunityServices";
import OfferServices from "./services/OfferServices";

export const loadBlockchainData = async (setOfferFactory, setForwarderC, setPcontract, isDev, isArcana, web3) => {
    try {
        const contractAddress = (await httpCommon.get('/api/v1/offer/contractAddress/latest')).data

        console.log(contractAddress, 'cta')
        web3.eth.Contract.givenProvider = web3.eth.Contract.currentProvider
        web3.eth.Contract._ethAccounts.givenProvider = web3.eth.Contract._ethAccounts.currentProvider
        const contract = new web3.eth.Contract(OfferFactory.abi, contractAddress.contractAddress);
        contract.givenProvider = contract.currentProvider
        console.log(web3, 'helloo')
        const contractF = new web3.eth.Contract(Forwarder.abi, contractAddress.contractAddressF);
        // console.log(contractF, 'cat')
        setForwarderC(contractF)
        console.log(contract, 'contttt')

        setOfferFactory(contract);
        const payingTokenAddress = isDev
            ? '0xD087ff96281dcf722AEa82aCA57E8545EA9e6C96'
            : '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619';

        let abi = [
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: '_spender',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'approve',
                outputs: [
                    {
                        internalType: 'bool',
                        name: 'success',
                        type: 'bool',
                    },
                ],
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: true,

                inputs: [{ name: '_owner', type: 'address' }],

                name: 'balanceOf',

                outputs: [{ name: 'balance', type: 'uint256' }],

                type: 'function',
            }
        ];
        const contractP = new web3.eth.Contract(abi, payingTokenAddress);
        setPcontract(contractP)
    } catch (error) {
        console.log(error)
        // axiosHandler(error.message)
    }
}

export const loadAllBrands = async (setAllBrands) => {
    await BrandServices.getBrands()
        .then((res) => {
            // console.log(res.data)
            setAllBrands(res.data)
        })
        .catch((e) => {
            // console.log(e)
        })
}

export const loadAllCommunities = async (setAllCommunities) => {
    await CommunityServices.getCommunities()
        .then(response => {
            // console.log(response.data)
            setAllCommunities(response.data)
        })
        .catch(e => {
            console.log(e);
        });
}

export const loadAllAds = async (setAllAds) => {
    await AdServices.getAds()
        .then(response => {
            setAllAds(response.data)
        })
        .catch(e => {
            console.log(e);
        });
}

export const loadAllOffers = async (setAllOffers, selectedNetwork) => {
    await OfferServices.getAllOffers(selectedNetwork.chain)
        .then(response => {
            // console.log(response.data, 'rd')
            setAllOffers(response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

export default {
    loadBlockchainData,
    loadAllBrands,
    loadAllCommunities,
    loadAllAds,
    loadAllOffers
};