import { CardMedia, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { offerContext } from '../../../offerContext'
import sad from '../../../images/sad.png'

function ModalOneContent({ activeStep, setActiveStep }) {
    const { user, account } = useContext(offerContext)
    useEffect(() => {

    }, [user])
    // console.log(user, account, "modalonek")
    return (
        <>
            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <CardMedia sx={{ width: '70px', height: 'auto' }} component='img' image={sad} />
                <p style={{ marginTop: '1%', color: '#8C8C8C', fontSize: '12px' }}>Don't have a brand?</p>
                <h1 style={{ backgroundImage: 'linear-gradient(275.71deg, #7D93AF -50.16%, #BC09C7 124.58%)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}> Create One Now!</h1>
            </Grid>
        </>
    )
}

export default ModalOneContent