import http from "../http-common.js";
import httpCookie from "../http-cookie.js";
import httpTransactions from "../http-transactions.js";

const getUsers = () => {
  return http.get(`/api/v1/user`);
};

const getUsersPaginated = () => {
  return http.get(`/api/v1/user/paginated`);
};

export const toggleFollow = async (id) => {
  try {
    let res = await httpCookie.put(`/api/v1/user/toggleFollow/${id}`, {
      headers: {
        Authorization: localStorage.getItem('deOffersToken')
      }
    });
    return res;
  } catch (err) {
    console.log(err)
    return false;
  }
};



const getVerifiedCreators = () => {
  return http.get(`/api/v1/user/creators`);
};

const getUserData = (walletAddress) => {
  return http.get(`/api/v1/user/${walletAddress}`);
}

const searchUsers = () => {
  return http.get(`/api/v1/user/search`);
};

const getUserById = (sign) => {
  return http.get(`/api/v1/user/login/${sign}`);
};

const updateMe = (data, token) => {
  // console.log(token);
  return httpTransactions.put(`/api/v1/user/me`, data, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  });
};

const updateStates = async (res, setUser, setUserBrand, setUserCreatedOffers, setClaimed, setFav, token, setFavItems, setBought, setSold) => {
  setUser(res?.user ? res.user : res.data)
  res?.brand && setUserBrand(res?.brand[0])

  // await getCreated(token !== "" && token)
  //   .then((res) => setUserCreatedOffers(() => res.data))
  // await getClaimed(token !== "" && token)
  //   .then((res) => setClaimed(() => res.data))
  // await getFav(token !== "" && token)
  //   .then((res) => setFav(() => res.data))
  // await getFavItems(token !== "" && token)
  //   .then((res) => setFavItems(res.data))
  // await getBought(token !== "" && token, setBought)
  // await getSold(token !== "" && token, setSold)

}

const verifyCreator = (id) => {
  return httpCookie.put(`/api/v1/user/verify/${id}`);
};

const getClaimed = (token) => {
  return httpTransactions.get(`/api/v1/user/me/claimed`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
}

const getCreated = (token) => {
  return httpTransactions.get(`/api/v1/user/me/created`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
}

const getClaimable = (comm, token) => {
  return httpTransactions.post(`/api/v1/user/me/claimable`, comm, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
}

const getFav = (token) => {
  return httpTransactions.get(`/api/v1/user/me/favorites`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
}

const getFavItems = (token) => {
  return httpTransactions.get(`/api/v1/user/me/favoriteItems`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
}

const getBought = async (token, setBought) => {
  let res = await httpTransactions.get(`/api/v1/user/me/bought`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
  setBought(res.data)
}

const getSold = async (token, setSold) => {
  let res = await httpTransactions.get(`/api/v1/user/me/sold`, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  })
  setSold(res.data)
}



export default {
  getUserData,
  getUsers,
  getUserById,
  toggleFollow,
  getUsersPaginated,
  getVerifiedCreators,
  searchUsers,
  updateMe,
  verifyCreator,
  getClaimed,
  getCreated,
  getClaimable,
  getFav,
  updateStates,
  getFavItems,
  getBought,
  getSold
};