import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router'
import axiosHandler from '../../helpers/axiosHandler';
import { offerContext } from '../../offerContext';
import OfferServices from '../../services/OfferServices';

function RedeemFields() {
    const textField = { width: '100%' };
    const { state } = useLocation()
    const id = state && state.id
    const { inAppRed, setInAppRed } = useContext(offerContext)
    const token = localStorage.getItem("deOffersToken")
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()

    const changeInappRedemption = async () => {
        setLoad(true)
        await OfferServices.updateOffer(id, {
            inAppRedemption: true,
        }, token)
            .then((res) => {
                // console.log('res :- ', res)
                setLoad(false)
                navigate(`/offerdetails/${id}`)
                setInAppRed(true)
            })
            .catch((e) => {
                console.log('redeem fields error :-', e);
                axiosHandler('Something went wrong')
                setLoad(false)
            })
    }

    return (
        <>
            <Grid container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid item sm={8} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
        <h3 style={{color:'#BC09C7'}}>Users Redeem Page Preview</h3>
                </Grid>
                <Grid item sm={8} xs={12} sx={{ display: 'flex', marginTop: '3%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Email</p>
                    <TextField required sx={textField} />
                </Grid>
                <Grid item sm={8} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Name</p>
                    <TextField required sx={textField} />
                </Grid>
                <Grid item sm={8} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Phone No.</p>
                    <TextField required sx={textField} />
                </Grid>
                <Grid item sm={8} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <p style={{ fontSize: '12px' }}>Address</p>
                    <TextField required sx={textField} multiline rows={4} />
                </Grid>
                <Grid item sm={8} xs={12} sx={{ display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    {
                        load ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} />
                        </Box> : <Button onClick={changeInappRedemption} sx={{ textTransform: 'none', width: '100%', backgroundColor: '#BC09C7', border: '2px solid #BC09C7', color: 'white', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' } }}>Create</Button>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default RedeemFields