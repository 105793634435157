import { Card, CardContent, CardMedia, Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'

function SimilarOfferCard({ offerMet }) {
    const [loadImg, setLoadImg] = useState(false)
    const navigate = useNavigate()
    return (
        <Card onClick={() => navigate(`/offerdetails/${offerMet._id}`)} sx={{ marginBottom: '4%', cursor: 'pointer' }}>
            <CardContent>
                <Grid container >
                    <Grid item md={12}>
                        <CardMedia component='img' sx={loadImg ? { borderRadius: '5%', width: '100%', height: '15vh !important' } : { display: 'none' }} onLoad={() => setLoadImg(true)} image={offerMet.bannerUri} />
                        {
                            !loadImg && <Skeleton animation="wave" variant="rectangular" width="15vh" height="15vh" />
                        }
                    </Grid>
                    <Grid item>
                        <h4 style={{ margin: '0', padding: '0' }}>{offerMet.offerName}</h4>
                        <p style={{ fontSize: '8px', margin: '0' }} >{offerMet.price} WETH</p>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SimilarOfferCard