import http from "../http-common.js";
import httpCookie from "../http-cookie.js";

const uploadImg = (data, token) => {
  return httpCookie.post(`/api/v1/ipfs/img`, data, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  });
};

const uploadJson = (data, token) => {
  return httpCookie.post(`/api/v1/ipfs/json`, data, {
    headers: {
      Authorization: localStorage.getItem('deOffersToken')
    }
  });
};

export default {
  uploadImg,
  uploadJson

};