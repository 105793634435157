import http from "../http-common";
import httpCookie from "../http-cookie";

const getCommunities = () => {
  return http.get(`/api/v1/community/`);
};

const getOffersByCommunityName = (id) => {
  return http.get(`/api/v1/offer/community/db/${id}`)
}

const newCommunity = (data) => {
  return httpCookie.post(`/api/v1/community/`, data);
};

const deleteCommunities = () => {
  return httpCookie.delete(`/api/v1/community/`);
};

const getCommunityById = (id) => {
  return http.get(`/api/v1/community/${id}`);
};

const updateCommunity = (id, data) => {
  return httpCookie.put(`/api/v1/community/${id}`, data);
};

const deleteCommunity = (id) => {
  return httpCookie.delete(`/api/v1/community/${id}`);
};

const getOfferByAdd = (add) => {
  return http.get(`/api/v1/offer/community/db/${add}`)
}

const getPaginatedCommunities = (page, size, chain) => {
  return http.get(`/api/v1/community/communities/paginated?page=${page}&size=${size}&chain=${chain}`)
}


export default {
  getCommunities,
  newCommunity,
  deleteCommunities,
  getCommunityById,
  updateCommunity,
  deleteCommunity,
  getOffersByCommunityName,
  getOfferByAdd,
  getPaginatedCommunities
};