import axiosHandler from "../helpers/axiosHandler";
import httpCommon from "../http-common";
import UserServices from "./UserServices";
import { getGasFees } from "./helper";


export const getResaleFee = async offerFactory => {
    try {
        return await offerFactory.methods.resaleFee().call();
    } catch (error) {
        console.log('Getting Fee Error:- ', error.message);
    }
};

export const getItemFromDb = async (id) => {
    try {
        return httpCommon.get(`/api/v1/offer/listings/db/${id}`)
    } catch (error) {
        console.log('Item get Error:- ', error.message);
    }
};
export const createListing = async (
    offerFactory,
    account,
    dbObject,
    offerAddress,
    price,
    nftId,
    amount,
    offerContract,
    offerFactoryAddress,
    token,
    setApprove,
    setListingConfirm,
    isEth

) => {
    try {

        // console.log(nftId, offerAddress, amount, price, account)
        let priorityFee = await getGasFees()
        let approved = await offerContract.methods
            .isApprovedForAll(account, offerFactoryAddress)
            .call();
        if (!approved) {
            let approval = await offerContract.methods
                .setApprovalForAll(offerFactoryAddress, true)
                .send({
                    from: account,
                    gasPrice: priorityFee,
                }).on("confirmation", function (confirmationNumber, receipt) {
                    console.log("Confirmation number:", confirmationNumber);
                    console.log("Receipt:", receipt);
                });
            // console.log('Got approval');
        }

        setApprove(true)
        // console.log('Got approval');
        let hash = await offerFactory.methods
            .listNft(nftId, offerAddress, amount, price, isEth)
            .send({
                from: account,
                gasPrice: priorityFee,
            }).on("confirmation", function (confirmationNumber, receipt) {
                console.log("Confirmation number:", confirmationNumber);
                console.log("Receipt:", receipt);
            });
        setListingConfirm(true)
        // console.log('Offer Created:- ', JSON.stringify(hash));
        dbObject.tokenId = hash.events.nftListed?.returnValues['0'];
        // console.log(dbObject);
        let res = await httpCommon.post(`/api/v1/offer/list`, dbObject, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        // console.log(res);
        return res;
    } catch (error) {
        axiosHandler(error)
        console.log('Error in listing:- ' + error);
    }
};

export const updateListing = async (
    offerFactory,
    account,
    itemId,
    tokenId,
    priceWei,
    price,
    token,
    isEth
) => {
    try {
        let priorityFee = await getGasFees()
        let hash = await offerFactory.methods
            .updateListing(tokenId, priceWei, isEth)
            .send({
                from: account,
                gasPrice: priorityFee,
            }).on("confirmation", function (confirmationNumber, receipt) {
                console.log("Confirmation number:", confirmationNumber);
                console.log("Receipt:", receipt);
            });
        let res = await httpCommon.put(`/api/v1/offer/listings/db/${itemId}`, { price, isEth }, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        return res;
    } catch (error) {
        axiosHandler(error)
        console.log('Updation Error:- ', error.message);
    }
};

export const deleteListing = async (offerFactory, account, itemId, tokenId) => {
    try {
        let priorityFee = await getGasFees()
        let hash = await offerFactory.methods.delistNft(tokenId).send({
            from: account,
            gasPrice: priorityFee,
        }).on("confirmation", function (confirmationNumber, receipt) {
            console.log("Confirmation number:", confirmationNumber);
            console.log("Receipt:", receipt);
        });
        let res = await httpCommon.delete(`/api/v1/offer/item/${itemId}`, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        return true;
    } catch (error) {
        axiosHandler(error)
        console.log('Deletion Error:- ', error.message);
        return false;
    }
};

export const buyListing = async (
    offerFactory,
    account,
    itemId,
    tokenId,
    price,
    token,
    setBought,
    payingTokenAddress,
    pContract,
    isEth
) => {
    try {
        console.log(account)
        const contractAddress = (await httpCommon.get('/api/v1/offer/contractAddress/latest')).data.contractAddress
        let priorityFee = await getGasFees()
        let sendObj = {
            from: account,
            value: price,
            gasPrice: priorityFee,
        }
        if (!isEth) {
            delete sendObj.value
            let approved = await pContract.methods
                .approve(contractAddress, price)
                .send(sendObj)
                .on("confirmation", function (confirmationNumber, receipt) {
                    console.log("Confirmation number:", confirmationNumber);
                    console.log("Receipt:", receipt);
                });

            if (!approved) console.log('Token approval failed');
        }
        let hash = await offerFactory.methods.buyNFT(tokenId).send(sendObj);
        let res = await httpCommon.put(`/api/v1/offer/listings/buy/${itemId}`, {}, {
            headers: {
                Authorization: localStorage.getItem('deOffersToken')
            }
        });
        await UserServices.getBought(token, setBought)
        return res;
    } catch (error) {
        axiosHandler("Something went wrong")
        console.log('Buying Error:- ', error.message);
        return false;
    }
};

export const loadAllListings = async (setListings) => {
    const res = await httpCommon.get('/api/v1/offer/listings/db')
    setListings(res.data)
    return res
}

export const getMyListings = async (token) => {
    return await httpCommon.get(`/api/v1/user/me/listed`, {
        headers: {
            Authorization: localStorage.getItem('deOffersToken')
        }
    })
}