import { Divider, Grid, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from 'react';
import { offerContext } from '../../offerContext';
import { AiFillInstagram } from 'react-icons/ai'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AiFillDribbbleCircle } from 'react-icons/ai'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from 'react-router';
import FollowFollowingModal from '../user/FollowFollowingModal';

function Details() {
    const { user } = useContext(offerContext)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [arr, setArr] = useState([])
    const [text, setText] = useState('')

    function abbreviateNumber(number) {
        const SI_SYMBOL = ['', 'k', 'M', 'B', 'T'];
        const tier = Math.log10(Math.abs(number)) / 3 | 0;

        if (tier === 0) return number.toString();

        const suffix = SI_SYMBOL[tier];
        const scale = Math.pow(10, tier * 3);

        const scaledNumber = number / scale;

        // Format the number with a specific number of decimal places
        let formattedNumber;
        if (scaledNumber < 10) {
            formattedNumber = scaledNumber.toFixed(2);
        } else if (scaledNumber < 100) {
            formattedNumber = scaledNumber.toFixed(1);
        } else {
            formattedNumber = scaledNumber.toFixed(0);
        }

        return formattedNumber + suffix;
    }

    return (
        <>
            <Grid container sx={{ marginTop: { md: '4%', sm: '10%', xs: '15%' } }}>
                <Grid item md={2}>
                    <Typography variant='h5' sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', margin: '0', padding: '0' }}>
                        {user.username}
                    </Typography>
                    <Typography variant='p' sx={{ fontFamily: 'Poppins', fontSize: '12px', margin: '0', padding: '0' }}>
                        {user.email}
                    </Typography>
                </Grid>
                <Grid md={0.5} item sx={{ padding: '0% 2%' }}>
                    <Divider orientation='vertical' />
                </Grid>
                {user && user.socialHandles && user.socialHandles.length !== 0 ? <Grid item md={9} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid container columnSpacing={1}>
                        {
                            user && user.socialHandles && user.socialHandles[0] && user.socialHandles[0].link && <Grid sx={{ cursor: 'pointer' }} item onClick={() => window.open(`https://www.instagram.com/${user.socialHandles[0].link}`, "_blank")}>
                                <AiFillInstagram size={30} />
                            </Grid>
                        }
                        {user && user.socialHandles && user.socialHandles[1] && user.socialHandles[1].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(`https://www.facebook.com/${user.socialHandles[1].link}`, "_blank")} item>
                            <FacebookIcon sx={{ fontSize: '30px' }} />
                        </Grid>}
                        {user && user.socialHandles && user.socialHandles[2] && user.socialHandles[2].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(`https://twitter.com/${user.socialHandles[2].link}`, "_blank")} item>
                            <TwitterIcon sx={{ fontSize: '30px' }} />
                        </Grid>}
                        {user && user.socialHandles && user.socialHandles[3] && user.socialHandles[3].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(`https://in.pinterest.com/${user.socialHandles[3].link}`, "_blank")} item>
                            <PinterestIcon sx={{ fontSize: '30px' }} />
                        </Grid>}
                        {user && user.socialHandles && user.socialHandles[4] && user.socialHandles[4].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(`https://www.linkedin.com/in/${user.socialHandles[4].link}`, "_blank")} item>
                            <LinkedInIcon sx={{ fontSize: '30px' }} />
                        </Grid>}
                        {user && user.socialHandles && user.socialHandles[5] && user.socialHandles[5].link && <Grid sx={{ cursor: 'pointer' }} onClick={() => window.open(user.socialHandles[5].link, "_blank")} item>
                            <AiFillDribbbleCircle size={30} />
                        </Grid>}
                    </Grid>
                </Grid> : <Grid item md={9}></Grid>}
                <Grid item container md={0.4} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Grid item md={0.4} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'flex-end' }}>
                        <div onClick={() => {
                            setArr(user.followers)
                            setText(`${user.username}'s Followers`)
                            setOpen(true)
                        }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2>{abbreviateNumber(user?.followers.length)}</h2>
                            <p style={{ fontSize: '12px' }}>Followers</p>
                        </div>
                        <div onClick={() => {
                            setArr(user.following)
                            setText(`${user.username} Follows`)
                            setOpen(true)
                        }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px', marginRight: '2em' }}>
                            <h2>{abbreviateNumber(user?.following.length)}</h2>
                            <p style={{ fontSize: '12px' }}>Following</p>
                        </div>
                    </Grid>
                    <Grid item onClick={() => navigate('/createprofile')}>
                        <Tooltip title='Edit Profile' >
                            <ModeEditIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: '2%' }}>
                <p style={{ fontFamily: 'Poppins', fontSize: '12px', margin: '0', padding: '0' }}>
                    {user.bio}
                </p>

            </Grid>
            <FollowFollowingModal open={open} setOpen={setOpen} arr={arr} text={text} />

        </>
    )
}

export default Details