import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function FixedTags() {
    const fixedOptions = [{ title: 'All communities', year: 2023 }];
    const [value, setValue] = React.useState([...fixedOptions]);

    return (
        <Autocomplete
            multiple
            id="fixed-tags-demo"
            value={value}
            onChange={(event, newValue) => {
                setValue([
                    ...fixedOptions
                ]);
            }}
            disabled
            options={[]}
            getOptionLabel={(option) => option.title}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.title}
                        sx={{ backgroundColor: '#3ED1CF', color: 'white', marginLeft: '1px' }}
                    />
                ))
            }
            style={{ width: '100%' }}
            renderInput={(params) => (
                <TextField {...params} sx={{ width: '100%' }} />
            )}
        />
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
