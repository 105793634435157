import { Fab } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { createContext } from 'react';
import Profile from '../components/Profile/Profile';
import SideDrawer from '../components/Sidebar/SideDrawer'
import { offerContext } from '../offerContext';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

function ProfilePage() {
    const { user, setUser } = useContext(offerContext)
    const navigate = useNavigate()
    return (
        <>
            <SideDrawer currentUser={user}>
                <Profile />
                <Fab size="medium" color="secondary" aria-label="add" onClick={() => navigate('/create')} sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}>
                    <AddIcon />
                </Fab>
            </SideDrawer>
        </>
    )
}

export default ProfilePage