import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { offerContext } from '../../offerContext'
import { ImageBannerContext } from '../../pages/EditProfile'
import 'react-image-upload/dist/index.css'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function UserImage() {
    const { user, token } = useContext(offerContext)
    const [isShown, setIsShown] = useState(true)
    const [isShownP, setIsShownP] = useState(true)
    const { image, banner, setImage, setBanner, profileBanner, profileImage, setProfileBanner, setProfileImage } = useContext(ImageBannerContext)

    const [ban, setBan] = useState(user?.profileBanner)


    // async function getImageFileObject() {
    //     // console.log(img, 'file');
    //     let form2Data = new FormData()
    //     form2Data.append('files', [profileBanner, profileImage]);
    //     // console.log(img.file, token)
    //     const res = await IpfsServices.uploadImg(form2Data, token)
    //     setBanner(res.data.urls[0])
    //     setImage(res.data.urls[1])
    // }
    console.log(profileBanner, profileImage)

    const handleImageChange = (acceptedFiles, setImage, setTemp) => {
        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0])
            const imageUrl = URL.createObjectURL(acceptedFiles[0]);
            setTemp(imageUrl);
        }
    };

    const removeImage = (setImage) => {
        setImage(null);
    };

    const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => handleImageChange(acceptedFiles, setProfileImage, setImage)
    });

    const { getRootProps: getBannerRootProps, getInputProps: getBannerInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => handleImageChange(acceptedFiles, setProfileBanner, setBanner)
    });
    return (
        <Grid sx={{ position: 'relative', height: '300px', width: '92vw', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div {...getBannerRootProps()}>
                {profileBanner ? (
                    <div style={{ position: 'relative', height: "300px", width: "92vw" }} >
                        <img src={banner} alt="Banner" style={{ height: '100%', width: '100%' }} />
                        <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1000', padding: '5px' }}>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '3px', cursor: 'pointer'
                            }} ><CameraAltIcon sx={{ width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /></button>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '3px', cursor: 'pointer'
                            }} onClick={() => removeImage(setProfileBanner)}>
                                <RemoveCircleIcon sx={{ width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ position: 'relative', height: "300px", width: "92vw", borderRadius: '15px', backgroundColor: '#E9E9E9', }} ></div>
                        <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1000', padding: '5px' }}>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '3px', cursor: 'pointer'
                            }} ><CameraAltIcon sx={{ width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /></button>
                        </div>
                        <input {...getBannerInputProps()} accept='image/*' />
                    </>
                )}
            </div>
            <div  {...getImageRootProps()} style={{ display: { md: 'flex', sm: 'flex', xs: 'none', }, backgroundColor: '#E9E9E9', zIndex: 0, position: 'absolute', borderRadius: '15%', top: '80%', left: '5%' }}>
                {profileImage ? (
                    <div style={{ position: 'relative' }}>
                        <img src={image} alt="Profile" style={{ height: '100px', width: '100px', position: 'relative', zIndex: '0', borderRadius: '15px', border: '4px solid black', backgroundColor: '#E9E9E9', backgroundColor: 'transparent' }} />
                        <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1000', padding: '5px' }}>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '2px', cursor: 'pointer'
                            }} ><CameraAltIcon sx={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} /></button>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '2px', cursor: 'pointer'
                            }} onClick={() => removeImage(setProfileImage)}>
                                <RemoveCircleIcon sx={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ height: '100px', width: '100px', position: 'relative', zIndex: '0', borderRadius: '15px', border: '4px solid black', backgroundColor: '#E9E9E9', backgroundColor: 'transparent' }}></div>
                        <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '1000', padding: '5px' }}>
                            <button style={{
                                backdropFilter: 'blur(19.2333px)', color: 'black', borderRadius: '50%', border: 'none', color: '#818A96', padding: '2px', cursor: 'pointer'
                            }} ><CameraAltIcon sx={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} /></button>
                        </div>
                        <input {...getImageInputProps()} accept="image/*" />
                    </>
                )}
            </div>
        </Grid>
    )
}

export default UserImage