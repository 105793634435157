import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import './App.css'
import OfferServices from './services/OfferServices';
import Web3 from 'web3';
import Offer from './artifacts/Offer.json';
import { offerContext } from './offerContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadAllBrands, loadAllCommunities, loadAllOffers, loadBlockchainData } from './LoadData';
import MainRouter from './Router/MainRouter';
import UserServices from './services/UserServices';
import { fetchAccount } from './services/connectors';
import Loading from './components/loader/Loading';
import { loadAllListings } from './services/MarketPlace';
import { HelmetProvider } from 'react-helmet-async';
import { useAuth } from '@arcana/auth-react';

function App() {
    const auth = useAuth()
    const [allAds, setAllAds] = useState([]);
    const [allOffers, setAllOffers] = useState([]);
    const [allCommunities, setAllCommunities] = useState([]);
    const [account, setAccount] = useState(0);
    const [offerFactory, setOfferFactory] = useState(null);
    const [forwarderC, setForwarderC] = useState(null)
    const [user, setUser] = useState(null);
    const [summary, setSummary] = useState([])
    const [offerId, setOfferId] = useState(null);
    const [allBrands, setAllBrands] = useState([]);
    const [userBrand, setUserBrand] = useState(null)
    const [NFTs, setNFTs] = useState([])
    const [token, setToken] = useState('')
    const [userCreatedOffers, setUserCreatedOffers] = useState([])
    const [claimed, setClaimed] = useState([])
    const [fav, setFav] = useState([])
    const [open, setOpen] = useState(false);
    const [eligible, setEligible] = useState([])
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [loadOnAccChange, setLoadOnAccChange] = useState(false)
    const [inAppRed, setInAppRed] = useState(false)
    const [listings, setListings] = useState([])
    const [favItems, setFavItems] = useState([])
    const [bought, setBought] = useState([])
    const [sold, setSold] = useState([])
    const [pContract, setPcontract] = useState(null)
    const [isEth, setIsEth] = useState(true)
    const [isArcana, setISArcana] = useState(false)
    const [networks, setNetworks] = useState([
        {
            icon: 'https://avatars.githubusercontent.com/u/6250754?s=200&v=4',
            name: 'Ethereum Goerli Testnet',
            chain: 'Ethereum',
            explorerLink: 'https://goerli.etherscan.io',
            alchemyAPIUrl: 'https://eth-goerli.alchemyapi.io/v2/',
            alchemyMainUrl: 'https://eth-mainnet.alchemyapi.io/v2/',
            alchemyAPIToken: '1t7-El0FDwdi9miOxMFUKKd69Fuk0eXT',
        },
        {
            icon: 'https://cryptologos.cc/logos/polygon-matic-logo.png',
            name: 'Polygon Testnet',
            chain: 'Polygon',
            explorerLink: 'https://mumbai.polygonscan.com/',
            alchemyAPIUrl: 'https://polygon-mumbai.g.alchemy.com/v2/',
            alchemyMainUrl: 'https://polygon-mainnet.alchemyapi.io/v2/',
            alchemyAPIToken: '1t7-El0FDwdi9miOxMFUKKd69Fuk0eXT',
        },
    ]);
    // window.web3 = new Web3(window.ethereum)
    const [selectedNetwork, setSelectedNetwork] = useState(JSON.parse(localStorage.getItem('selectedDeOffersNetwork')) ? JSON.parse(localStorage.getItem('selectedDeOffersNetwork')) : networks[0]);
    const [isDev, setIsDev] = useState(window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/') ? false : true)
    const [payingTokenAddress, setPayingTokenAddress] = useState(isDev ? '0xD087ff96281dcf722AEa82aCA57E8545EA9e6C96'
        : '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619');
    console.log(isDev)

    const createOfferFromApp = async (offerUri, startsAt, endsAt, count, price, communities) => {
        const res2 = await OfferServices.createOffer(offerFactory, offerUri, startsAt, endsAt, count, price, communities, user.walletAddress);
    }

    const buyOfferFromApp = async (offerId, price, offerAddress, token_id, NFTAddress, load, setLoad, user, offer, setUser) => {
        const web3 = window.web3;
        if (offerAddress) {
            const contractAddress = offerAddress;
            const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
            const res2 = await OfferServices.buyOffer(offercontract, Web3.utils.toWei(String(price)), account, token_id, NFTAddress, load, setLoad, user, offer, setUser, token, pContract, selectedNetwork, offerAddress, forwarderC);
            // console.log(res2)
        }
    }

    const offerSummary = async (offerAddress) => {
        try {
            let provider = window.web3;
            if (user && provider.selectedAddress !== null) {
                const web3 = window.web3;
                // const web3 = window.web3;
                const contractAddress = offerAddress;
                const offercontract = new web3.eth.Contract(Offer.abi, contractAddress);
                const res2 = await OfferServices.getSummary(offercontract);
                // console.log(res2)
                setSummary(res2)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // setSelectedNetwork(JSON.parse(localStorage.getItem('selectedDeOffersNetwork')) ? JSON.parse(localStorage.getItem('selectedDeOffersNetwork')) : networks[0] )
        if (JSON.parse(localStorage.getItem("deOffersUser")) !== undefined
            && (JSON.parse(localStorage.getItem("deOffersUser")))?.email) {
            setUser(JSON.parse(localStorage.getItem("deOffersUser")))
            setToken(localStorage.getItem("deOffersToken"))
            setISArcana(JSON.parse(localStorage.getItem("isArcana")))
            setAccount(JSON.parse(localStorage.getItem("deOffersUser")).walletAddress);
            if (localStorage.getItem("deOffersBrand") === 'undefined') {
                localStorage.setItem('deOffersBrand', null)
            } else {
                setUserBrand(localStorage.getItem("deOffersBrand") !== 'undefined' ? JSON.parse(localStorage.getItem("deOffersBrand")) : null)
            }
        } else {
            setUser(null)
            setUserBrand(null)
            localStorage.clear()
        }
    }, [account])

    // console.log(userCreatedOffers, 'data');
    console.log(user && (!(user.email) || user.email === '' || user.email === null), 'emyail')

    useEffect(() => {
        !isArcana && window.ethereum && window.ethereum.on("accountsChanged", async (accounts) => {
            setLoadOnAccChange(true)

            await fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand)
                .then(async (res) => {
                    if (!(res.user.email) || res.user.email === '' || res.user.email === null) {
                        setOpen(true)
                    } else {
                        localStorage.setItem("deOffersUser", JSON.stringify(res.user))
                        localStorage.setItem("deOffersToken", res.token)
                        localStorage.setItem("deOffersBrand", JSON.stringify(res.brand ? res.brand[0] : null))
                        setAccount(res.account)
                        setToken(res.token)
                        // setUserBrand(res.data ? res.data : null)

                        // console.log(res, 'hey')
                        // await UserServices.updateStates(res, setUser, setUserBrand, setUserCreatedOffers, setClaimed, setFav, token, setFavItems, setBought, setSold)
                        setUser(res.user)
                        setToken(res.token)
                        setUserBrand(res.brand ? res.brand[0] : null)

                        // await fetchNFTs(res.user.walletAddress, setNFTs, setEligible, claimed, res.token, selectedNetwork, isDev);
                    }
                })
                .catch((e) => console.log(e))
            setLoadOnAccChange(false)
        })
    }, [])
    console.log(open, 'heykk')

    useEffect(() => {
        try {
            !isArcana && window.ethereum.on('chainChanged', async (chain) => {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: isDev ? Web3.utils.toHex(80001) : Web3.utils.toHex(137) }],
                });
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        const func = async () => {
            await loadAllOffers(setAllOffers, selectedNetwork);
            await loadAllListings(setListings)
        }


        const func3 = async () => {
            const accounts = await window.web3?.eth.getAccounts();
            setAccount(() => accounts[0]);
        }
        func();
        user !== null && func3()
    }, []);

    useEffect(() => {
        const func = async () => {
            console.log('refreshed', 'contttt')
            // window.web3 = 
            console.log(auth, 'authh')
            auth.provider.rpcConfig = isDev ? 'https://rpc-mumbai.maticvigil.com' : 'https://polygon-rpc.com';
            const web3 = isArcana ? await new Web3(auth.provider) : await new Web3(window.ethereum);
            window.web3 = isArcana ? await new Web3(auth.provider) : await new Web3(window.ethereum);
            console.log(web3, isArcana, 'hello')
            web3.givenProvider = web3.currentProvider
            await loadBlockchainData(setOfferFactory, setForwarderC, setPcontract, isDev, isArcana, web3)
            if (JSON.parse(localStorage.getItem('isArcana'))) {
                window.web3 = new Web3(auth.provider)
            } else {
                window.web3 = new Web3(window.ethereum)
            }
        }
        func()
    }, [isArcana])
    console.log(offerFactory, 'offfff')

    useEffect(() => {
        const func = async () => {
            await loadAllOffers(setAllOffers, selectedNetwork);
            // user !== null && await fetchNFTs(user.walletAddress, setNFTs, setEligible, claimed, token, selectedNetwork, isDev);
            localStorage.setItem('selectedDeOffersNetwork', JSON.stringify(selectedNetwork))
        }
        func()
    }, [selectedNetwork])

    const context = {
        inAppRed, setInAppRed, sold, setSold, bought, setBought, favItems, setFavItems,
        openFilterDrawer, setOpenFilterDrawer, offerId, offerFactory, setOfferFactory, setAllAds,
        setAllCommunities, setAllOffers, eligible, setEligible, open, setOpen, userCreatedOffers,
        setUserCreatedOffers, setClaimed, claimed, setFav, fav, NFTs, setNFTs, userBrand, setUserBrand,
        summary, setSummary, allBrands, setAllBrands, setOfferId, user, setUser, account, setAccount, allOffers,
        allCommunities, token, setToken, allAds, createOfferFromApp, buyOfferFromApp, offerSummary, listings,
        setListings, isDev, networks, setNetworks, selectedNetwork, setSelectedNetwork, isEth, setIsEth,
        payingTokenAddress, pContract, setPcontract, setIsDev, setPayingTokenAddress,
        isArcana, setISArcana
    }

    return (
        <>
            <offerContext.Provider value={context}>
                <HelmetProvider>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Router>
                        {
                            loadOnAccChange ? <Loading /> : <MainRouter />
                        }
                    </Router>
                </HelmetProvider>
            </offerContext.Provider>
        </>

    )
}

export default App;