import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import One from './One';
import Two from './Two';
import Three from './Three';
import { CircularProgress, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useContext } from 'react';
import { offerContext } from '../../offerContext';
import Web3 from 'web3';
import axiosHandler from '../../helpers/axiosHandler';
import Offer from '../../artifacts/Offer.json';
import OfferServices from '../../services/OfferServices';

const steps = ['Fill the details', 'Confirm', 'Successfully redeemed the offer'];

export default function BurnNFTForm() {
    const { user, setUser } = useContext(offerContext)
    const [completed, setCompleted] = useState({});
    const state = useLocation().state
    const offer = state.offer ? state.offer : null
    const [activeStep, setActiveStep] = useState(user && offer && user.redeemed.includes(offer._id) ? 2 : 0);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // console.log(offer);
    const [json, setJson] = useState({
        contractAddress: offer && offer.contractAddress,
        offer: offer && offer,
        redeemEmail: user && user.email,
        redeemPhoneNo: '',
        redeemName: user && user.username,
        redeemAddress: ''
    })
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const Burn = async () => {
        setLoading(true);
        const web3 = window.web3;
        const offerContract = new web3.eth.Contract(Offer.abi, offer.contractAddress);

        await OfferServices.redeemOffer(
            offerContract,
            user.walletAddress,
            web3,
            offer.contractAddress,
            offer._id,
            json,
            setUser,
            setActiveStep,
            activeStep
        )
            
        setLoading(false);
    }

    return (
        <Grid container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid item sm={8} xs={12} sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === 0 ? <One offer={offer} activeStep={activeStep} setActiveStep={setActiveStep} json={json} setJson={setJson} /> : activeStep === 1 ? <Two offer={offer} activeStep={activeStep} setActiveStep={setActiveStep} json={json} setJson={setJson} /> : <Three offer={offer} activeStep={activeStep} setActiveStep={setActiveStep} json={json} setJson={setJson} />}
                    {activeStep === 0 ? <Box sx={{ pt: 2, width: '100%', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            color="inherit"
                            onClick={() => navigate(-1)}
                            sx={{ backgroundColor: 'white', color: '#BC09C7', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: 'white', backgroundColor: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}
                        >
                            Back
                        </Button>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} />

                        <Button onClick={handleNext} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>
                            Next
                        </Button>
                    </Box> : activeStep === 1 ? <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{ backgroundColor: 'white', color: '#BC09C7', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: 'white', backgroundColor: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {
                            loading ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress sx={{ backgroundColor: '#BC09C7', color: 'white', padding: '5px', borderRadius: '50%' }} />
                            </Box> : <Button onClick={Burn} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>
                                Confirm
                            </Button>
                        }
                    </Box> : <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={() => navigate('/offers')} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid #BC09C7', width: '100%', textTransform: 'none', '&:hover': { color: '#BC09C7', border: '2px solid #BC09C7' }, fontSize: { md: '15px', sm: '10px', xs: '10px' } }}>
                            Explore more offers
                        </Button>
                    </Box>
                    }
                </div>
            </Grid>
        </Grid>
    );
}