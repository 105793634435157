import React from 'react'
import SideDrawer from '../components/Sidebar/SideDrawer'
import UserTransactions from '../components/userTransactions/UserTransactions'

function UserTransactionPage({ allAds, allOffers, allCommunities, offers, currentUser, loadWeb3 }) {
    return (
        <>
            <SideDrawer currentUser={currentUser} loadWeb3={loadWeb3}>
                <UserTransactions />
            </SideDrawer>
        </>
    )
}
export default UserTransactionPage