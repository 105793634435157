import Web3 from "web3";
import axios from "axios";
import BrandServices from "./BrandServices";

const isDev = window.location.href.includes('http://app.nfthodlr.xyz/') || window.location.href.includes('https://app.nfthodlr.xyz/') ? false : true


export const fetchAccount = async (user, setUser, account, setAccount, token, setToken, setUserBrand) => {
    const web3 = window.web3
    let use, tokenAcc, userBrand
    console.log(web3)
    try {
        // console.log(user, account, "fetchdata")
        isDev ? await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: Web3.utils.toHex(80001),
                    chainName: 'Mumbai Testnet',
                    nativeCurrency: {
                        name: 'Matic',
                        symbol: 'MATIC',
                        decimals: 18,
                    },
                    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
                    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
                },
            ],
        }) : await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: Web3.utils.toHex(137),
                    chainName: 'Polygon',
                    nativeCurrency: {
                        name: 'Matic',
                        symbol: 'MATIC',
                        decimals: 18,
                    },
                    rpcUrls: ['https://polygon-rpc.com'],
                    blockExplorerUrls: ['https://polygonscan.com/'],
                },
            ],
        });

        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: isDev ? Web3.utils.toHex(80001) : Web3.utils.toHex(137) }],
        });
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        console.log(accounts[0])
        try {
            const signature = await web3.eth.personal.sign(
                `Purpose:\nSign to verify wallet ownership in NFTHodlr platform.\n\nWallet address:\n${accounts[0]}\n\nHash:\n${Web3.utils.keccak256(
                    accounts[0],
                )}`,
                accounts[0],
            );
            if (signature) {
                // console.log(signature)
                // const res2 = await UserServices.getUserById(signature);
                await fetch(isDev ? `https://dev.nfthodlr.xyz/api/v1/user/login?signature=${signature}&address=${accounts[0]}` : `https://app.nfthodlr.xyz/api/v1/user/login?signature=${signature}&address=${accounts[0]}`)
                    .then(r => {
                        return r.json()
                    })
                    .then(async result => {
                        // console.log(signature);
                        // console.log(result)
                        use = result.user
                        tokenAcc = result.token
                        localStorage.setItem("deOffersUser", JSON.stringify(result?.user ? result.user : null))
                        localStorage.setItem("deOffersToken", result?.token ? result.token : null)
                        await BrandServices.getUserBrand(result.user.walletAddress)
                            .then((res) => {
                                userBrand = res.data
                                // console.log(res.data ? res.data[0] : null, 'userBrand')
                                localStorage.setItem("deOffersBrand", JSON.stringify(res.data ? res.data[0] : null))
                                setUserBrand(res.data)
                            }).catch((e) => {
                                console.log(e)
                            })
                        setUser(result.user)
                        setToken(result.token)
                        axios.defaults.headers.post['Authorization'] = result.token;
                        axios.defaults.headers.put['Authorization'] = result.token;
                    })
                    .catch((e) => console.log(e))
                // setUser(res2.data.user)
                // console.log(res2.headers)
            }
            // console.log(signature);
            return { account: accounts[0], user: use, token: tokenAcc, brand: userBrand };
        } catch (error) {
            console.log(error)
        }
        // console.log(user)
    } catch (err) {
        console.log(err.message)
    }
}

export const connectMetaMask = async (user, setUser, account, setAccount, token, setToken, setUserBrand) => {

    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum)
        fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand);
        // window.ethereum.on('accountsChanged', async function () {
        //     await fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand);
        // })
    }
    else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider.enable());
        fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand);
        // window.ethereum.on('accountsChanged', function () {
        //     fetchAccount(user, setUser, account, setAccount, token, setToken, setUserBrand);
        // })
    }
    else {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
}

export const connectArcana = async (user, setUser, account, setAccount, token, setToken, setUserBrand, auth) => {
    try {

        const web3 = new Web3(auth.provider);
        window.web3 = web3
        let use, tokenAcc, userBrand;
        console.log(window.web3.currentProvider, 'done')
        console.log(web3);

        try {
            const accounts = await web3.eth.getAccounts();

            console.log(accounts[0], 'modalonek');
            setAccount(accounts[0]);

            try {
                const signature = await web3.eth.personal.sign(
                    `Purpose:\nSign to verify wallet ownership in NFTHodlr platform.\n\nWallet address:\n${accounts[0]}\n\nHash:\n${web3.utils.keccak256(
                        accounts[0]
                    )}`,
                    accounts[0]
                );

                if (signature) {
                    const response = await fetch(
                        isDev
                            ? `https://dev.nfthodlr.xyz/api/v1/user/login?signature=${signature}&address=${accounts[0]}`
                            : `https://app.nfthodlr.xyz/api/v1/user/login?signature=${signature}&address=${accounts[0]}`
                    );

                    const result = await response.json();
                    console.log(result)
                    use = result.user;
                    tokenAcc = result.token;

                    localStorage.setItem('deOffersUser', JSON.stringify(result?.user ? result.user : null));
                    localStorage.setItem('deOffersToken', result?.token ? result.token : null);
                    localStorage.setItem('isArcana', true);

                    const brandResponse = await BrandServices.getUserBrand(result.user.walletAddress);
                    userBrand = brandResponse.data ? brandResponse.data.length ? brandResponse.data[0] : null : null;
                    localStorage.setItem('deOffersBrand', JSON.stringify(userBrand));
                    setUserBrand(userBrand);

                    setUser(result.user);
                    setToken(result.token);

                    axios.defaults.headers.post['Authorization'] = result.token;
                    axios.defaults.headers.put['Authorization'] = result.token;
                }

                return { account: accounts[0], user: use, token: tokenAcc, brand: userBrand };
            } catch (error) {
                console.log(error);
            }
        } catch (err) {
            console.log(err.message);
        }
    } catch (error) {
        console.log(error);
    }
}