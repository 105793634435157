import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CardMedia, CircularProgress, FormControl, MenuItem, Select, TextField } from '@mui/material';
import price2 from '../../images/price.png';
import { createListing, deleteListing, getItemFromDb, getResaleFee, loadAllListings, updateListing } from '../../services/MarketPlace';
import { offerContext } from '../../offerContext';
import Web3 from 'web3';
import Offer from '../../artifacts/Offer.json'
import httpCommon from '../../http-common';
import { useNavigate, useParams } from 'react-router';
import { compare } from '../../utils/helper';
import delete2 from '../../images/delete.png'
import successHandler from '../../helpers/successHandler';
import axiosHandler from '../../helpers/axiosHandler';
import InfoIcon from '@mui/icons-material/Info';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import matic from '../../images/matic.png'
import weth from '../../images/weth.png'
import { loadBlockchainData } from '../../LoadData';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
};

export default function ResellModal({ open, setOpen, handleClose, handleOpen, nftId, offer, listedOffer, show, setListedOffer }) {

    const [loading, setLoading] = useState(false)
    const { offerFactory, account, user, token, NFTs, setListings, isEth, setIsEth, setOfferFactory, setForwarderC, setPcontract, isDev } = useContext(offerContext)
    const navigate = useNavigate()
    const [price, setPrice] = useState(undefined)
    const params = useParams();
    const [receive, setReceive] = useState(0)
    const [approve, setApprove] = useState(false)
    const [listingConfirm, setListingConfirm] = useState(false)
    const [showLoaders, setShowLoaders] = useState(false)


    const id = params.id
    useEffect(() => {
        const func = async () => {
            await loadBlockchainData(setOfferFactory, setForwarderC, setPcontract, isDev)
            setShowLoaders(false)
            setApprove(false)
            setListingConfirm(false)
        }
        setIsEth(listedOffer?.isEth)
        func();
    }, [])
    console.log(isEth, listedOffer?.isEth)
    // console.log(listedOffer.isEth)
    const list = async () => {
        setLoading(true);
        setShowLoaders(true)
        // let provider = window.ethereum;
        const web3 = window.web3;
        const contractAddress = (await httpCommon.get('/api/v1/offer/contractAddress/latest')).data.contractAddress
        const offerContract = new web3.eth.Contract(Offer.abi, offer.contractAddress);
        // console.log(offerContract, contractAddress)
        let res = await createListing(
            offerFactory,
            user.walletAddress,
            { tokenId: '', nftId, offer: offer._id, amount: 1, price, isEth },
            offer.contractAddress,
            web3.utils.toWei(price),
            nftId,
            1,
            offerContract,
            contractAddress,
            token,
            setApprove,
            setListingConfirm,
            isEth

        );
        if (res) {
            await loadAllListings(setListings)
            successHandler('Offer listed successfully 🎉')
            setOpen(false)
            setLoading(false);
            navigate(`/`)
            setApprove(false)
            setListingConfirm(false)
            setShowLoaders(false)
        } else {
            axiosHandler('Something went wrong')
            setOpen(false)
            setLoading(false);
            setApprove(false)
            setListingConfirm(false)
            setShowLoaders(false)
        }
    };

    const deletelist = async () => {
        setLoading(true)
        let res = await deleteListing(offerFactory, account, id, listedOffer.tokenId)
        if (res) {
            await loadAllListings(setListings)
            successHandler('Listed Offer deleted successfully')
            navigate(`/`)
            setOpen(false)
            setLoading(false);
        } else {
            axiosHandler('Something went wrong')
            setOpen(false)
            setLoading(false);
        }
    }

    const editlist = async () => {
        // let provider = window.ethereum;
        const web3 = window.web3;
        setLoading(true);

        let res = await updateListing(
            offerFactory,
            account,
            id,
            listedOffer.tokenId,
            web3.utils.toWei(price),
            price,
            token,
            isEth === listedOffer.isEth ? listedOffer.isEth : isEth

        );
        if (res) {
            await loadAllListings(setListings)
            await getItemFromDb(id)
                .then((res2) => {
                    // console.log(res2.data, 'yay')
                    setListedOffer(res2.data)
                    successHandler('Price updated successfully 🎉')
                    setOpen(false)
                    setLoading(false);
                })
        } else {
            axiosHandler('Something went wrong')
            setOpen(false)
            setLoading(false);
        }
    }
    // console.log(show)
    useEffect(() => {
        const func = async () => {
            await getResaleFee(offerFactory)
                .then((res) => {
                    // console.log(res)
                    setReceive(res / 10)
                })
        }
        func()
    })

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CardMedia component='img' image={show !== 'delete' ? price2 : delete2} sx={{ width: '30%', height: '30%' }} />
                    {show !== 'delete' ? <div style={{ width: '100%' }}>
                        <p style={{ fontSize: '12px' }}>Price</p>
                        <Box sx={{ display: 'flex' }}>
                            <TextField sx={{ width: ' 100%' }} value={price} name='price' onChange={(e) => setPrice(e.target.value)} placeholder='Change the price' />
                            <Select
                                value={isEth}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <p style={{ fontSize: '12px' }}>Placeholder</p>;
                                    }

                                    return <p style={{ fontSize: '12px' }}>{selected ? 'MATIC' : 'WETH'}</p>;
                                }}
                                sx={{ padding: '0', display: 'flex', height: '' }}
                                onChange={(e) => setIsEth(e.target.value)}
                            >
                                <MenuItem value={true} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <CardMedia sx={{ width: '20px', marginRight: '10px', height: '20px', objectFit: 'contain' }} component='img' image='https://cryptologos.cc/logos/polygon-matic-logo.png' />
                                    <p style={{ fontSize: '12px' }}>MATIC</p>
                                </MenuItem>
                                <MenuItem value={false} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <CardMedia sx={{ width: '25px', marginRight: '10px', height: '25px', objectFit: 'contain' }} component='img' image={weth} />
                                    <p style={{ fontSize: '12px' }}>WETH</p>
                                </MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ width: '100%', bgcolor: 'white', color: '#3782B7', padding: '4%', borderRadius: '5px', marginTop: '5%', boxShadow: 'none', border: '2px dashed #E2E2E2' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                                    <InfoIcon sx={{ marginRight: '2%' }} />
                                    Fees ({receive}%)
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <strong>{price ? `-${(price * receive / 100).toFixed(8)} ${isEth ? 'MATIC' : 'WETH'}` : `0 ${isEth ? 'MATIC' : 'WETH'}`}</strong>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '3%', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                                    <CallReceivedIcon sx={{ marginRight: '2%' }} />
                                    You'll Receive
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <strong>{price ? `${(price - (price * receive / 100)).toFixed(8)} ${isEth ? 'MATIC' : 'WETH'}` : `0 ${isEth ? 'MATIC' : 'WETH'}`}</strong>
                                </div>
                            </div>
                        </Box>
                    </div>
                        :
                        <div style={{ width: '70%' }}>
                            <h3 style={{ textAlign: 'center' }}>Do you want to delist this offer?</h3>
                        </div>
                    }
                    {
                        showLoaders && <div style={{ marginTop: '4%', width: '100%' }}>
                            <div style={{ fontSize: '12px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {approve ? <CheckCircleIcon sx={{ color: '#32C997', fontSize: '12px' }} /> : <CircularProgress size={20} sx={{ color: '#ABBED1', margin: 'none', padding: '5px', fontSize: '13px' }} />}
                                <p>{approve ? 'Approved' : 'Approving'}</p>
                            </div>
                            <div style={{ fontSize: '12px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {listingConfirm ? <CheckCircleIcon sx={{ color: '#32C997', fontSize: '12px' }} /> : <CircularProgress size={20} sx={{ color: '#ABBED1', margin: 'none', padding: '5px', fontSize: '13px' }} />}
                                <p>{listingConfirm ? 'Listed' : 'Listing'}</p>
                            </div>
                        </div>
                    }
                    <div style={{ display: 'flex', marginTop: '5%', width: '100%', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            setLoading(false)
                            handleClose()
                        }} sx={{ backgroundColor: 'white', color: '#BC09C7', border: '2px solid #BC09C7', '&:hover': { backgroundColor: '#BC09C7', color: 'white' }, textTransform: 'none' }}>Back</Button>
                        {
                            loading ? <Box >
                                <CircularProgress sx={{ color: 'white', margin: 'none', padding: '5px', width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#BC09C7' }} />
                            </Box> : <Button onClick={show === 'edit' ? editlist : show === 'delete' ? deletelist : list} sx={{ backgroundColor: '#BC09C7', color: 'white', border: '2px solid BC09C7', '&:hover': { backgroundColor: 'white', color: '#BC09C7' }, textTransform: 'none' }}>{show === 'edit' ? 'Update' : show === 'delete' ? 'Delist' : 'List'}</Button>
                        }
                    </div>
                </Box>
            </Modal>
        </div>
    );
}