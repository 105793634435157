import UserServices from "../services/UserServices";

// Go to www.alchemy.com and create an account to grab your own api key!
const apiKey = "9_2htus9LWuPu3sZXn_XT0PICbJV2-wa";
// "RVakgtAN1cOw89v5ZlSrVJ1jSFQyzRug";

export const fetchNFTs = async (owner, setNFTs, setEligible, claimed, token, selectedNetwork, isDev) => {
    const endpoint = `${isDev? selectedNetwork.alchemyAPIUrl : selectedNetwork.alchemyMainUrl}9_2htus9LWuPu3sZXn_XT0PICbJV2-wa`;
    try {
        await fetch(`${endpoint}/getNFTs?owner=${(JSON.parse(localStorage.getItem('deOffersUser'))).walletAddress}`).then(data => data.json())
            .then(async (res) => {
                // let claim = []
                setNFTs(res.ownedNfts)
                // res.ownedNfts.map(async i => {
                //     await UserServices.getClaimable({ communities: i.contract.address }, token)
                //         .then((res) => {
                //             // console.log(res.data, 'rest');
                //             claim.push(...res.data)

                //         })

                // })
                // claim = [... new Set(claim.map(item => item._id))]
                // setEligible(claim)
                // console.log(claimed, claim, 'restco')
            })

    } catch (e) {
        console.log(e)
        // fetchNFTs(endpoint, owner, contractAddress, setNFTs, retryAttempt+1)
    }

}