import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CardMedia, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import NoLiveOffers from '../nothingToSee/NoLiveOffers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 500, sm: 500, xs: 400 },
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    fontFamily: 'Poppins',
    height: '50vh',
    overflowY: 'scroll',
    "&::-webkit-scrollbar": { display: 'none' }
};

export default function FollowFollowingModal({ open, setOpen, arr, text }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{ marginBottom: '5%' }}>{text}</h3>
                    {
                        arr.length ? arr.map((i, index) => {
                            return <Box key={index}>
                                <Grid item container md={12} onClick={() => {
                                    navigate(`/userdetails/${i?.walletAddress.toLocaleLowerCase()}`)
                                    setOpen(false)
                                }} sx={{ cursor: 'pointer', marginBottom: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={1.5}>
                                        <CardMedia sx={{ borderRadius: '50%', width: '40px', height: '40px' }} component='img' image={i.profileImage} />
                                    </Grid>
                                    <Grid item xs={0.5} />
                                    <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{i.username}</Typography>
                                        <Typography variant='p' sx={{ fontSize: '12px', fontFamily: 'Poppins', margin: '0', padding: '0' }}>{i?.bio?.length > 30 ? i?.bio?.slice(0, 29) + '...' : i?.bio}</Typography>

                                    </Grid>
                                </Grid>
                            </Box>
                        }) : <NoLiveOffers text={'Nothing to show'} />
                    }
                </Box>
            </Modal>
        </div>
    );
}