import React, { useContext } from 'react';
import { useState } from 'react';
import IpfsServices from '../services/IpfsServices';
import SideDrawer from '../components/Sidebar/SideDrawer'
import CreateOffer from '../components/createOffer/CreateOffer';
import { offerContext } from '../offerContext';

const AddToIpfs = ({ offersLength, createOfferFromApp, allCommunities }) => {
    const { user } = useContext(offerContext)
    return (
        <SideDrawer currentUser={user}>
            <div >
                {/* <h2>Upload Image</h2>
                <form onSubmit={onSubmitImage} >
                    <input type='file' onChange={captureFile} />
                    <input type='submit' />
                </form> */}
                {/* <h2>Upload Offer Metadata</h2> */}

                <CreateOffer offersLength={offersLength} createOfferFromApp={createOfferFromApp} communities={allCommunities} />

                {/* <h2>Upload Ad Metadata</h2>
                <div className="form">
                    <div className="form-body">
                        <div className="username">
                            <label className="form__label" for="creatorName">Offer Id </label>
                            <input className="form__input" type="text" value={offerId} onChange={(e) => handleInputChange(e)} id="offerId" placeholder="Offer Id" />
                        </div>


                        <div className="username">
                            <label className="form__label" for="bannerUri">Banner Uri </label>
                            <input type="text" id="banner" className="form__input" value={banner} onChange={(e) => handleInputChange(e)} placeholder="Banner" />
                        </div>
                        <div className="username">
                            <label className="form__label" for="startsAt">Starts At </label>
                            <input type="date" id="startsAt" className="form__input" value={startsAt} onChange={(e) => handleInputChange(e)} placeholder="Starts At" />
                        </div>
                        <div className="username">
                            <label className="form__label" for="endsAt">Ends At </label>
                            <input type="date" id="endsAt" className="form__input" value={endsAt} onChange={(e) => handleInputChange(e)} placeholder="Ends At" />
                        </div>



                    </div>
                    <div className="footer">
                        <button onClick={onSubmitAdJson} type="submit" className="btn">Submit AD Json</button>
                    </div>
                </div> */}

            </div>


        </SideDrawer>
    )
}


export default AddToIpfs;