import React, { useContext } from 'react'
import { offerContext } from '../offerContext'
import SideDrawer from '../components/Sidebar/SideDrawer'
import UserDetails from '../components/user/UserDetails'

export default function UserDetailsPage() {
    const { user } = useContext(offerContext)
  return (
    <SideDrawer currentUser={user}>
        <UserDetails/>
    </SideDrawer>
  )
}
