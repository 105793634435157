import { Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { offerContext } from '../../offerContext'
import { getClaimedOffers } from '../../services/OfferServices'
import OfferCard from '../landingPageComponents/card/OfferCard'

function SavedComponent({ allCommunities }) {

    const { user, fav } = useContext(offerContext)

    return (
        <>
            <Grid container columnSpacing={10} rowSpacing={5}>
                {
                    fav.map((offer, index) => {
                        return <Grid item md={3} key={index}>
                            <OfferCard offer={offer} communities={allCommunities} />
                        </Grid>
                    })
                }
            </Grid>
        </>

    )
}

export default SavedComponent